.countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: #404760;
  color: white;
  /*z-index: 99999999999;*/
  position: relative;
  /* margin-top: 180px; */
}


@media only screen and (max-width: 768px){

	/*.razorpay-container {
		margin-top: 60px !important;
	}*/

}