// HEADING_
$HEADER1: 25px;
$HEADER2: 18px;

// HEADINGS
$HEADING_1: 28px;
$HEADING_2: 25px;
$HEADING_3: 22px;
$HEADING_4: 20px;
$HEADING_5: 18px;
$HEADING_6: 16px;
$HEADING_7 : 50px;
$HEADING_8:20px;
$HEADER_9: 32px;
$HEADING_10 : 38px;
$HEADING_11 : 40px;
// PARAGRAPHS
$PARAGRAPH_X_SMALLL: 10px;
$PARAGRAPH_SMALL: 12px;
$PARAGRAPH_REGULAR: 14px;

// BUTTONNS
$BUTTON_REGULAR: 14px;
$BUTTON_MEDIUM: 16px;
$BUTTON_LARGE: 18px;

// INPUTS
$INPUT_FEILD: 16px;

// FONT FAMILY
$C2C_FONT_FAMILY: Rubik, sans-serif;
