@use "../../../../Util/c2cConstants/colorConstants" as color;
@use "../../../../Util/c2cConstants/fontFileConstants.scss" as font;

.aab-container {
  .aab-button {
    box-shadow: 0px 10px 16px #00ceff33;
    border-radius: 30px;
    background: color.$PRIMARY_COLOR;
    font-size: 20px;
    line-height: 24px;
    font-family: font.$C2C_FONT_FAMILY;
    font-weight: 500;
    color: color.$C2C_WHITE;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    padding: 0px 24px;
    cursor: pointer;
    width: fit-content;
  }

  .aab-button-disabled {
    background: #b2b2b2;
    box-shadow: 0px 10px 16px #b2b2b233;
    cursor: not-allowed;
  }

  .aab-button-title {
    margin-right: 10px;
  }

  .aab-button-image {
    width: 20px;
    height: 19px;
  }

  @media screen and (max-width: 768px) {
    .aab-button-title {
      font-size: 15px;
    }
}
}
