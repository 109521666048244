 .date-wrapper .form-group{
    position: relative;
    box-shadow: 2px 2px 6px pink;
    width: 50px
}
.date-dropdown-ul{
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style-type: none;
    position: absolute;
    max-height: 200px;
    overflow-y: auto;
    z-index: 9;
    background: #fff;
    border: 1px solid #dbdbdb;
    padding: 0;
    top: 3rem;
    border-radius: 5px;
    width: 100%;
}
.date-dropdown-ul li{
    padding: 5px;
    margin: 0px !important;
}
.date-wrapper{
    width: 100%;
}
.date-wrapper .form-group input {
    height: 45px;
    padding: 10px;
    width: 100%;
    background-color: #f5f6f7 !important;
    border-radius: 5px;
    border: 1px solid #dbdbdb;
}
.date-wrapper .form-group{
    box-shadow: none;
    width: 30%;
}
.up-down-icons-wrapper{
    position: absolute;
}
.up-down-icons{
    padding: 10px;
    left: 60%;
    position: absolute;
}
.up-down-icons i{
    color: #17bae0;
}
.active-dropdown{
    border: 1px solid #17bae0 !important;
}
.date_error_message{
    border: 1px solid red;
    border-radius: 5px;
}

@media all and (max-width:280px){
    .pro-content{
        padding: 0px;
    }
}
@media (max-width: 767px) {
    .date-dropdown-ul{
        display: none;
    }
}

@media (min-width: 540px) and (max-width:767px){
    .up-down-icons{
        left: 70%
    }
}

@media (min-width: 767px) and (max-width:1021px){
    .up-down-icons{
        left: 70%
    }
}

@media all and (min-width:1024px) and (max-width:1030px){
    .date-wrapper .form-group input{
        padding: 5px;
        margin-right: 5px;
    }
}