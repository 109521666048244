@use "../../../../Util/c2cConstants/colorConstants" as color;
@use "../../../../Util/c2cConstants/fontFileConstants.scss" as font;

.action-button-container {
  .abc-button {
    box-shadow: 0px 10px 16px #00ceff33;
    border-radius: 30px;
    background: color.$PRIMARY_COLOR;
    font-size: 20px;
    line-height: 24px;
    font-family: font.$C2C_FONT_FAMILY;
    font-weight: 500;
    color: color.$C2C_WHITE;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    cursor: pointer;
    width: fit-content;
  }

  .abc-button-disabled {
    background: #b2b2b2;
    box-shadow: 0px 10px 16px #b2b2b233;
    cursor: not-allowed;
    padding: 14px 24px;
  }

  .abc-button-loader {
    padding: 14px 24px;
  }

  .abc-button-title {
    margin-right: 10px;
  }

  .abc-button-image,.abc-spinner {
    width: 24px !important;
    height: 24px !important;
  }
  

  @media screen and (max-width: 768px) {
    .abc-button-title {
      font-size: 15px;
    }
  }
}
