@use "../../../Util/c2cConstants/colorConstants.scss" as color;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.staff-message-toaster {
  z-index: 10000;
  position: fixed;
  padding: 14px;
  display: flex;
  //   align-items: center;
  justify-content: space-between;
  bottom: 24px;
  left: 90px;
  font-weight: 600;
  right: auto;
  text-transform: none;
  transform: none;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  color: color.$C2C_WHITE;
  width: 454px;

  .smt-message-content {
    width: 440px;
  }

  .smt-title {
    font-weight: 500;
    font-size: 16px;
    line-height: auto;
    margin: 0px;
  }

  .smt-content {
    font-weight: 400;
    font-size: 16px;
    line-height: auto;
    margin: 4px 0px 0px 0px;
  }

  .smt-close-div {
    display: flex;
    align-items: start;
  }
  .smt-close {
    cursor: pointer;
    font-size: 16px;
  }

  @media (max-width: 768px) {
    width: 90%;
    left: 20px;
    top: 20px;
    bottom: auto;
  }
}

.staff-message-toaster-success {
  background-color: color.$C2C_GREEN;
}

.staff-message-toaster-error {
  background-color: color.$C2C_RED;
}
