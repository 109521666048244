.patient-row-individual-row {
  text-align: left;
  background-color: white;
  min-height: 80px;

  .patient-row-patient-id {
    font-size: 10px;
  }
  .primary-cta-button {
    min-width: 100px;
    height: auto;
    background-color: #17bae0;
    color: white;
    border: none;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      background-color: darken($color: #17bae0, $amount: 5%);
    }
  }

  .reverse-color {
    background-color: transparent !important;
    color: #17bae0 !important;
    border: 1px solid #d6dae5 !important;
  }

  .primary-cta-button-disabled {
    min-width: 100px;
    height: auto;
    background-color: transparent;
    color: lightgrey;
    transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      background-color: darken($color: transparent, $amount: 5%);
    }
  }

  .secondary-button {
    min-width: 100px;
    height: auto;
    background-color: white;
    color: #17bae0;
    border: 1px solid lightgray;
    transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

    &:hover {
      background-color: darken($color: #17bae0, $amount: 5%);
      color: white;
    }
  }

  .action-button {
    border: none;
    color: #17bae0;
    background-color: transparent;
    font-size: x-large;
  }

  .action-button-disabled {
    background-color: transparent;
    color: lightgrey;
    border: none;
    font-size: x-large;
  }

  .action-button-card {
    font-size: 18px;
    border: none;
    color: #17bae0;
    background-color: transparent;
  }

  .action-button-card-disabled {
    font-size: 18px;
    border: none;
    color: lightgrey;
    background-color: transparent;
  }

  .action-button-disabled {
    border: none;
    color: lightgrey;
    background-color: transparent;
    font-size: x-large;
  }
}

.patient-row-header {
  background-color: transparent;
  color: #f969a4;
  font-size: smaller;
  font-weight: 600;
}

.inital-patient {
  border: 1px solid #09a4ce;
  border-radius: 3px;
  opacity: 1;
  // height: 32px;
  color: #09a4ce;
  text-align: center;
  font-family: 17px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 150px;
}

.follow-up-patient {
  border: 1px solid #f969a4;
  border-radius: 3px;
  opacity: 1;
  height: 32px;
  color: #f969a4;
  text-align: center;
  font-family: 17px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100px;
}

.button-text {
  font-size: 16px;
}

.action-card {
  color: #2f2f2f;
  text-transform: capitalize;
  font-size: 12px;
}

.border-left {
  border-left: 1px solid #eeeeee;
}

.border-top {
  border-left: 1px solid #eeeeee;
}

.card-prec-heading {
  font-weight: 600;
  letter-spacing: 0.6px;
  color: #f969a4;
  text-transform: uppercase;
  font-size: 12px;
}

.primary-cta-button-card {
  min-width: 100px;
  color: #17bae0;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  border: 1px solid #d6dae5;
  border-radius: 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  height: 35px;
}

.primary-cta-button-disabled {
  color: lightgrey;
  min-width: 100px;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);
  border: 1px solid #d6dae5;
  border-radius: 25px;
  background: transparent 0% 0% no-repeat padding-box;
  height: 35px;
}

.button-text-card {
  font-weight: 14px;
  color: #17bae0;
}

.button-text-card-disabled {
  font-weight: 14px;
  color: lightgrey;
}

.card-prec {
  display: flex;
  align-items: center;
}

.date-time {
  color: #2f2f2f;
  font-size: 12px;
}

.previous-patient-mobile {
  display: none !important;
}

.previous-patient {
  display: block;
}

.patient-content-row {
  font-size: 16px;
  color: #2f2f2f;
}

.fw-medium {
  font-weight: 600;
}
.procedure-name-patient-side {
  width: 100%;
}
@media (min-width: 320px) and (max-width: 768px) {
  .previous-patient-mobile {
    display: block !important;
  }
  .procedure-name-patient-side {
    width: 30%;
  }
  .previous-patient {
    display: none !important;
  }
  .inital-patient {
    min-width: 84px;
    font-size: 12px;
  }

  .follow-up-patient {
    min-width: 84px;
    font-size: 12px;
  }
}

.patient-reminder {
  margin-top: -5px;
}

.secondary-button {
  min-width: 100px;
  height: auto;
  background-color: white;
  color: #17bae0;
  border: 1px solid lightgray;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    background-color: darken($color: #17bae0, $amount: 5%);
    color: white;
  }
}
.primary-cta-button {
  min-width: 100px;
  height: auto;
  background-color: #17bae0;
  color: white;
  border: none;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    background-color: darken($color: #17bae0, $amount: 5%);
  }

  &:disabled {
    background-color: #a3a9b7;

    &:hover {
      background-color: darken($color: lightgrey, $amount: 5%);
    }
  }
}

.textLink {
  margin-left: 5px;
  cursor: pointer;
  color: #17bae0;
  max-width: 100px;
  border-bottom: 2px solid #17bae0;
}
