html {
  scroll-behavior: smooth;
}

.input-col {
  background-color: #f5f6f7;
  width: 100%;
  /*border-bottom: 2px #c6c6c6 solid !important;*/
  border: none;
  padding: 0 15px;
  font-size: 15px;
  color: #1b2027;
  height: 30px;
}

.otp-btn {
  width: 120px;
  height: 45px;
  text-align: center;
  border-radius: 50px;
  line-height: 45px;
  font-size: 16px;
  color: #fff;
  border: none;
  background-color: #17bae0;
  margin: 0px auto;
  display: block;
}
.paste-btn {
  width: 120px;
  height: 45px;
  text-align: center;
  border-radius: 50px;
  line-height: 45px;
  font-size: 16px;
  color: #17bae0;
  border: none;
  background-color: #FFF;
  margin: 0px auto;
  display: block;
}
.paste-btn:hover {
  background: #17bae0;
  color: #FFF;
}
.inline-btn { display: inline-block; margin: 0 12px; }

.otp-btn:disabled {
  background-color: rgb(163, 169, 183);
}

.otp-btn:disabled:hover {
  background-color: rgb(163, 169, 183);
}

.otp-btn:hover {
  /* background-color: #09a4ce; */
  color: #fff !important;
}

.otp-input {
  background-color: #f5f6f7;
  margin: 25px auto 0;
  display: block;
  border: none;
  padding: 0 15px;
  font-size: 15px;
  color: #1b2027;
  height: 30px;
}

.input-col:focus {
  border-color: #56cde8 !important;
}
.otp-input:focus {
  border-color: #56cde8 !important;
}

.bookCardMargin {
  background-color: #404860;
  min-height: 90px;
  padding-top: 20px;
}

@media screen and (max-width: 768px) {
  .knowledgeMargin {
    margin: 20px;
  }
}
.unsupported .swal-icon {
  width: 30%;
}
.do_it_btn {
  color: #17bae0 !important;
  margin-left: 10px;
}

.anchor_tag {
  color: white !important;
}

.titleStyle .swal-title {
  font-size: large;
}