/*body{
    font-family: 'Rubik', sans-serif !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    color: #000;
}*/
img {
  max-width: 100%;
  height: auto;
}
/* ul,
li,
ol {
  padding-left: 0;
  list-style: none;
} */
a:hover {
  text-decoration: none;
}

/*header css*/
.Homeheader {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1) !important;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  background: #fff;
  position: fixed;
  width: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  z-index: 9;
}
ul.contact-info {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
ul.contact-info li {
  margin-left: 20px;
  align-items: center;
  display: flex;
  height: 80px;
  color: #565656;
  font-size: 16px;
}
ul.contact-info li .fa {
  margin-right: 10px;
}
ul.contact-info li .fa-phone-square {
  font-size: 20px;
  color: #56cde8;
}
ul.contact-info li .fa-envelope-o {
  font-size: 20px;
  color: #fa7aae;
}
ul.contact-info li .fa-question {
  font-size: 12px;
  color: #fff;
  background-color: #fa7aae;
  padding: 3px;
  border-radius: 2px;
}
.banner {
  width: 100%;
  background: #fbfbfb;
  margin-top: 80px;
}
.banner h2 {
  font-size: 60px;
  margin-bottom: 50px;
  color: #56cde8;
  text-transform: uppercase;
}
.banner h2 span {
  color: #fa7aae;
}
.doctor-login {
  width: 200px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  background: #56cde8;
  color: #fff;
  font-size: 20px;
  margin-right: 25px;
  border-radius: 50px;
}
.doctor-login:hover {
  border: 2px #56cde8 solid;
  color: #56cde8;
  background: #fff;
}
.patient-login {
  width: 200px;
  height: 50px;
  line-height: 48px;
  text-align: center;
  border: 2px #56cde8 solid;
  color: #56cde8;
  font-size: 20px;
  margin-right: 15px;
  border-radius: 50px;
}
.patient-login:hover {
  background: #56cde8;
  color: #fff;
  border-color: #56cde8;
}
.banner-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 500px;
}
.btn-col {
  display: flex;
}
.video-col {
  width: 100%;
  padding: 80px 0; /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#404760+0,2f9fc1+100 */
  background: #404760; /* Old browsers */
  background: -moz-linear-gradient(
    left,
    #404760 0%,
    #2f9fc1 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    left,
    #404760 0%,
    #2f9fc1 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to right,
    #404760 0%,
    #2f9fc1 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#404760', endColorstr='#2f9fc1',GradientType=1 ); /* IE6-9 */
}
.modal-body iframe {
  width: 100%;
  height: 400px;
}
/* .modal-content {
  margin-top: 15%;
} */
.video-col h3 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 30px;
}
.video-col p {
  font-size: 20px;
  color: #fff;
  text-align: justify;
}
.video-img {
  padding-left: 30px;
  display: block;
}
.video-img2 {
  padding-right: 30px;
  display: block;
}
.video-col2 {
  width: 100%;
  padding: 80px 0;
}
.video-col2 h3 {
  font-size: 40px;
  color: #202020;
  margin-bottom: 30px;
}
.video-col2 p {
  font-size: 20px;
  color: #565656;
  text-align: justify;
}
.app-screen {
  width: 100%;
  padding: 50px 0 0;
  position: relative;
}
.app-screen .background-banner-web {
  width: 100%;
}
.app-screen .background-banner-mob {
  display: none;
}
.app-screen .qrcode-link {
  height: 60px;
}
.app-screen .store-link {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e5f7fc;
  bottom: 0px;
  left: 35%;
}
.app-screen .playstore-link,
.applestore-link {
  height: 40px;
}
.app-screen span {
  font-size: 16px;
  font-weight: 600;
  margin: 0 10px;
}
.app-screen .app-content {
  width: 100%;
  display: flex;
  justify-content: center;
  min-height: 450px;
  flex-direction: column;
}
.app-screen .app-content h3 {
  font-size: 40px;
  margin-bottom: 70px;
}
.btn-col img {
  margin-right: 10px;
}
.pro-content-col .doctor-booking-page {
  margin-top: 4rem;
}
@media only screen and (min-width: 768px) and (max-width: 1199px) {
  .banner h2 {
    font-size: 40px;
  }
  .banner-content {
    margin-top: 15%;
    min-height: initial;
  }
  .video-col h3,
  .video-col2 h3 {
    font-size: 34px;
  }
  .video-col p,
  .video-col2 p {
    font-size: 16px;
  }
  .app-screen .app-content h3 {
    font-size: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    max-width: 100%;
  }
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .banner h2 {
    font-size: 30px;
  }
  .banner-content {
    margin-top: 15%;
    min-height: initial;
  }
  .video-col h3,
  .video-col2 h3 {
    font-size: 24px;
  }
  .video-col p,
  .video-col2 p {
    font-size: 14px;
  }
  .app-screen .app-content h3 {
    font-size: 24px;
    margin-bottom: 40px;
    text-align: center;
  }
  .logo {
    text-align: center;
  }
  .logo img {
    height: 50px;
  }
  ul.contact-info {
    justify-content: center;
  }
  ul.contact-info li {
    height: 30px;
    font-size: 14px;
    padding-bottom: 10px;
  }
  .banner-content {
    margin-top: 5%;
    align-items: center;
    margin-bottom: 40px;
  }
  .banner h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  .video-col h3,
  .video-col2 h3 {
    text-align: center;
  }
  .video-col p,
  .video-col2 p {
    text-align: center;
    margin-bottom: 30px;
  }
  .app-screen .app-content {
    min-height: initial;
    margin-bottom: 40px;
  }
  .app-screen {
    padding: 0;
  }
  .app-screen .background-banner-mob {
    width: 100%;
    display: unset;
  }
  .app-screen .background-banner-web {
    display: none;
  }
  .app-screen .qrcode-link {
    height: 38px;
  }
  .app-screen .playstore-link,
  .applestore-link {
    height: unset;
    width: 70px;
  }
  .app-screen .store-link {
    background: #c2e8f7;
    bottom: 15px;
    left: 8px;
  }
  .app-screen span {
    font-size: 8px;
    margin: 0 4px;
  }
  .video-img {
    padding-left: 0px;
  }
  .video-img2 {
    padding-right: 0px;
    margin-bottom: 40px;
  }
  .btn-col a {
    padding: 0 5px;
  }
  .patient-login {
    margin-right: 0px;
  }
  .video-col2 {
    padding: 40px 0;
  }
  .doctor-login,
  .patient-login {
    font-size: 16px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 480px) {
  .banner-content .btn-col {
    flex-direction: column;
    width: 100%;
  }
  .doctor-login {
    width: 100%;
    margin-bottom: 15px;
  }
  .patient-login {
    width: 100%;
  }
  ul.contact-info li {
    margin-left: 10px;
  }
  .banner h2 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 320px) and (max-width: 400px) {
  ul.contact-info {
    flex-direction: column;
  }
  .banner {
    margin-top: 110px;
  }
  ul.contact-info li {
    margin-left: 0px;
  }
}

.fade.modal {
  opacity: 1;
}

.fade.in {
  opacity: 1 !important;
}

.disable-item{
  pointer-events: none !important;
  opacity: 0.5 !important;
  color: #fff ;
}
.view-prescriprion-section .disabled-item{
  pointer-events: none !important;
  opacity: 0.5 !important;
  color: #fff ;
}
.backgroundButton{
  background-color: #575757 !important;
  opacity: 0.5;
  border: transparent;
}
.disable-icon{
  color: inherit !important;
}