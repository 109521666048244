.doctor-search-input-wrapper {
  display: flex;
  flex: 2;
  flex-direction: column;
  position: relative;
}
.doctor-search-input-wrapper .input-holder {
  display: flex;
  flex: 1;
  align-items: center;
  background-color: #fff;
  position: relative;
  transition: all 0.3s ease-in-out;
  padding: 0px 5px;
  z-index: 9;
}

.doctor-search-input-wrapper.isActive .input-holder {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.doctor-search-input-wrapper.active .input-holder {
  width: 450px;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.5);
  transition: all 0.5s cubic-bezier(0, 0.105, 0.035, 1.57);
}
.doctor-search-input-wrapper .input-holder .search-input {
  width: 100%;
  padding: 10px 10px 10px 30px;
  /* opacity: 0;
    position: absolute;
    top:0px;
    left:0px; */
  background: transparent;
  box-sizing: border-box;
  border: none;
  outline: none;
  /* font-family:"Open Sans", Arial, Verdana; */
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  /* color:#FFF; */
  /* transform: translate(0, 60px); */
  /* transition: all .3s cubic-bezier(0.000, 0.105, 0.035, 1.570); */
  transition-delay: 0.3s;
  color: #000;
}
.doctor-search-input-wrapper.active .input-holder .search-input {
  opacity: 1;
  transform: translate(0, 10px);
}
.doctor-search-input-wrapper .input-holder .search-icon {
  position: absolute;
  cursor: pointer;
  /* width:70px;
    height:70px; */
  /* border:none;
    border-radius:6px;
    padding:0px;
    outline:none;
    z-index: 2;
    float:right;
    transition: all 0.3s ease-in-out;
    display: flex;
    background-color: transparent; */
}
/* .doctor-search-input-wrapper.active .input-holder .search-icon {
    width: 50px;
    height:50px;
    margin: 10px;
    border-radius: 30px;
} */
/* .doctor-search-input-wrapper .input-holder .search-icon span {
    width:22px;
    height:22px;
    vertical-align: middle;
    position:relative;
    transform: rotate(-45deg);
    transition: all .4s cubic-bezier(0.650, -0.600, 0.240, 1.650);
} */
/* .doctor-search-input-wrapper.active .input-holder .search-icon span {
    transform: rotate(-45deg);
}
.doctor-search-input-wrapper .input-holder .search-icon span::before, .doctor-search-input-wrapper .input-holder .search-icon span::after {
    position: absolute; 
    content:'';
}
.doctor-search-input-wrapper .input-holder .search-icon span::before {
    width: 2px;
    height: 8px;
    left: 6px;
    top: 14px;
    border-radius: 2px;
    background: #00C5DB;
}
.doctor-search-input-wrapper .input-holder .search-icon span::after {
    width: 14px;
    height: 14px;
    left: 0px;
    top: 0px;
    border-radius: 16px;
    border: 2px solid #00C5DB;
} */
.close_serch {
  display: none;
}
.close_serch img {
  cursor: pointer;
}
.doctor-search-input-wrapper.isActive .close_serch {
  display: flex;
}
/* .doctor-search-input-wrapper .close_serch {
    position: relative;
    z-index: 1;
    top:24px;
    right:20px;
    transform: rotate(45deg);
    transition: all .3s cubic-bezier(0.285, -0.450, 0.935, 0.110);
    transition-delay: 0.2s;
    width: 16px;
    height: 15px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #a3a9b7;
    border-radius: 50%;
    box-sizing: border-box;
    padding: 0px 1px 1px 3px;
} */
/* .doctor-search-input-wrapper.active .close_serch {
    right:-50px;
    transform: rotate(45deg);
    transition: all .6s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    transition-delay: 0.5s;
} */
/* .doctor-search-input-wrapper .close_serch::before, .doctor-search-input-wrapper .close_serch::after {
    position:absolute;
    content:'';
    background-color: #ffffff;
    border-radius: 2px;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: flex;
    margin: auto;
} */
/* .doctor-search-input-wrapper .close_serch::before {
    width: 1px;
    height: 11px;
    left: 5px;
    top: 0px;
} */
/* .doctor-search-input-wrapper .close_serch::after {
    width: 11px;
    height: 1px;
    left: 0px;
    top: 5px;
} */

.searched_value {
  display: none;
  width: 100%;
  position: absolute;
  left: 0;
  top: 40px;
  right: 0;
  background-color: #ffffff;
  padding: 15px 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.searched_value.searched_value_no_absolute {
  position: relative;
  top: 0;
  padding: 0px;
}
.doctor-search-input-wrapper.isActive .searched_value {
  display: flex;
  flex-direction: column;
}
.searched_value ul {
  width: 100%;
  margin: 0px;
  max-height: 210px; //152px;
  overflow-y: auto;
}
.searched_value ul li {
  width: 100%;
  margin: 0px;
  cursor: pointer;
  // padding: 5px;
  padding: 15px;
  // border-bottom: 1px solid #A3A9B7;
  border-bottom: 1px solid rgba(163, 169, 183, 0.5);
  list-style-type: none;
}
.searched_value ul li:last-child {
  border-bottom: 0px;
}
.searched_item_list {
  display: flex;
}
.searched_item_img {
  max-width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.searched_item_img img {
  width: 100%;
  height: 100%;
}
.searched_item_text {
  padding-left: 10px;
}
.searched_item_text h5 {
  font-size: 16px;
  line-height: initial;
  color: #1b2027;
  margin: 0px;
  font-weight: 500;
}
.searched_item_text p {
  font-size: 12px;
  line-height: initial;
  color: #a3a9b7;
  font-weight: 200;
  padding-top: 4px;
  margin: 0px;
}

.search-input::placeholder {
  color: #a3a9b7;
  opacity: 1;
  font-size: 12px;
}

.search-input:-ms-input-placeholder {
  color: #a3a9b7;
  font-size: 12px;
}

.search-input::-ms-input-placeholder {
  color: #a3a9b7;
  font-size: 12px;
}
.no_data_found {
  display: flex;
  justify-content: center;
  align-items: center;
}

.searched_item_no_img {
  color: #fff;
  text-transform: capitalize;
  height: 40px;
  width: 40px;
  background-color: rgb(86, 205, 232);
  font-size: 16px;
}

.search_icon_outer_width {
  top: 79px;
  left: 1132px;
  width: 50px;
  height: 34px;
  /* UI Properties */
  background: #1f4695 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;

  .search_icon {
    width: 24px;
    height: 24px;
    margin-left: 10px;
    margin-top: 5px;
  }
}
