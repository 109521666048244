.investors b {
  font-size: 25px;
}
.investors p {
  margin: 10px 0px;
}
.investors a {
  color: #17bae0;
}
.investors-doctor-mob {
  display: none;
}
@media (max-width: 767px) {
  .investors-doctor-mob {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .investors-doctor-web {
    display: none;
  }
  .investors {
    margin: 30px 0px;
    text-align: center;
  }
}
