// // css for modal
// .rx_boday{
//     padding: 30px;
// }

// @media (max-width:768px){}

.rx_modal_content {
  font: normal normal normal 18px/28px "Rubik", sans-serif;
  letter-spacing: 0px;
  color: #1b2027;
}
.erxcell_menu_item {
  color: #26bee2;
}
.warning-counter {
  width: 258px;
  height: 14px;
  /* UI Properties */
  text-align: left;
  font: normal normal normal 12px/14px "Rubik", sans-serif;
  letter-spacing: 0px;
  color: #da1346;
  opacity: 1;
}
.menu-pos {
  position: absolute !important;
  left: -50px !important;
}
.rx_icons_btn {
  background: #e8fbff 0% 0% no-repeat padding-box;
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin: 5px;
  color: #17bae0;
}

.rx_icons_btn:disabled {
  background-color: #f2f2f2;
  color: #c1c1c1;
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
}
.disable-pointer-event-span {
  pointer-events: none;
}
.disable-current-icon {
  color: #fff !important;
  background-color: rgb(228, 228, 228) !important;
}

.share-manual-rx-modal-wrapper{
  background: #fff;
  border-radius: 10px;
}

.save-shared-prescription-content-wrapper {
  border-radius: 5px;
  width: 100%;
}

.save-shared-prescription-content-container {
  width: 49%;
  margin: 0px 0px 10px 0px;
}


.save-shared-prescription-container {
  background-color: #ffff;
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;


}

@media screen and (max-width: 768px) {
  .save-shared-prescription-content-wrapper {
      width: 100%;
      margin-left: 0px;
    }

  .save-shared-prescription-container {
    background-color: #ffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .save-shared-prescription-content-container {
    width:100%;  
  }
}

@media only screen and (min-width: 360px) and (max-width: 600px)  {
  .share-manual-rx-modal-wrapper{
    width: 95%;
  }
}