video-duration {
  float: left;
}
/* .videoEnd-pop{
  margin-top: 37%  !important;
} */
.pro-doctor-video {
  width: 100%;
  background-color: #f5f5f5;
  padding: 25px 65px;
  float: left;
  margin-top: 8px;
  height: calc(100vh - 173px);
  overflow: hidden;
  overflow-y: auto;
}
.video-col-left {
  width: 35%;
  float: left;
  /* background-color: #fff; */
  /* border-radius: 5px; */
  padding: 20px;
  max-height: 35rem;
  overflow-y: scroll;
}
.video-col-left h2 {
  font-size: 22px;
  color: #f969a4;
  font-weight: 600;
}
.video-col-left label {
  color: #f969a4;
  text-transform: uppercase;
}
.video-col-left .form-group {
  margin-bottom: 30px;
}
.video-col-left .input-col {
  /*border-bottom: 1px #ccc solid !important;*/
  padding: 0px;
  text-transform: uppercase;
  background-color: #f5f6f7;
}
.video-col-left .input-col::-webkit-input-placeholder {
  /* Edge */
  text-transform: capitalize;
}

.video-col-left .input-col:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  text-transform: capitalize;
}

.video-col-left .input-col::placeholder {
  text-transform: capitalize;
}

.mb-video-duration {
  display: none;
}

.login-btn {
  width: auto;
  height: auto;
  background: #17bae0;
  float: right;
  border-radius: 50px;
  line-height: 38px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  margin: 0 0 0 10px;
  padding: 0 20px;
}
.login-btn:hover {
  color: #fff;
  text-decoration: none;
  background: #09a4ce;
}

.video-col-right {
  float: right;
  width: 65%;
  padding-left: 40px;
}
.video-col-right iframe {
  width: 100%;
}
.video-duration p {
  font-size: 18px;
  font-weight: 600;
  float: left;
  margin-right: 30px;
  margin-top: 20px;
}
.video-duration p span {
  color: #f969a4;
}

.video-col-right iframe {
  height: 500px;
}

.metting-btn {
  width: 150px;
  height: 50px;
  background: #c81342;
  margin-left: 15px;
  float: right;
  border-radius: 50px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  margin-top: 10px;
}
.metting-btn:hover {
  color: #fff;
  text-decoration: none;
  background: #000;
}

/* responsive css */
@media (min-width: 767px) and (max-width: 1199px) {
  .video-duration {
    width: 50%;
    margin-top: 0px;
  }
  .logo img {
    height: 55px;
  }
  .video-duration p {
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 16px;
  }
  .login-btn {
    height: 45px;
    font-size: 16px;
    line-height: 43px;
    margin-top: 5px;
  }
  .metting-btn {
    height: 45px;
    font-size: 16px;
    line-height: 43px;
    margin-top: 5px;
  }
}
@media (max-width: 767px) {
  .video-col-left.prescription_scroll_style {
    overflow: unset;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .update-btn {
    width: 100%;
  }
}
@media (min-width: 320px) and (max-width: 991px) {
  .prescription-history-container {
    padding: 5px !important;
    width: 100%;
  }
  .pro-doctor-video {
    padding: 20px 35px;
    height: calc(100vh - 145px);
  }
  .video-duration {
    width: 100%;
    margin-bottom: 20px;
  }
  .desk-video-duration {
    display: none;
  }
  .mb-video-duration {
    display: block;
  }
  .video-col-right {
    padding-left: 20px;
  }
  .pro-content {
    padding: 0 15px;
    padding-top: 50px;
  }
  .pro-content2 {
    padding: 0 15px;
    padding-top: 50px;
  }
  .pro-content-col {
    padding: 20px 30px;
  }
  .scrtabs-tab-container {
    width: 100%;
    /* float: left; */
    border-radius: 10px;
    background-color: #f5f6f7;
    display: flex;
  }
  .scrtabs-tabs-fixed-container ul.nav-tabs > li h4 {
    font-size: 15px;
  }
  .doctor-profile h3 {
    font-size: 20px;
  }
  .doctor-profile h4 {
    font-size: 18px;
  }
  .doctor-profile ul.doc-info li {
    font-size: 14px;
  }
  .morning-date ul li .time-box {
    width: 100px;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
  }
  header {
    padding: 10px 20px;
  }
  .prescription_preview_modal {
    padding: 0px 0px;
  }
}
@media (min-width: 320px) and (max-width: 767px) {
  .metting-btn {
    width: 130px;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
  }
  .header-bottom-shadow {
    padding: 7px 25px 0 !important;
  }
  .mr-tab {
    margin-right: 30px !important;
  }
  .nav-tab {
    font-size: 16px !important;
  }
  .video-col-right {
    width: 100%;
    float: left;
    padding-left: 0px;
    margin-bottom: 30px;
  }
  .metting-btn {
    margin-top: 5px;
  }
  .video-col-left {
    width: 100%;
    float: left;
  }
  .profile-img {
    margin: 0px auto 30px;
    width: 120px;
    height: 120px;
  }
  .doctor-profile h3 {
    text-align: center;
  }
  .doctor-profile h4 {
    text-align: center;
  }
  .pro-calender {
    padding-left: 0px;
    margin-top: 20px;
  }
  .pro-content-col h2 {
    font-size: 20px;
    text-align: center;
  }
  .video-duration p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .logo img {
    height: 50px;
  }
  .login-btn {
    width: 115px;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    margin-top: 5px;
  }
  .mb-video-duration .metting-btn {
    float: none;
    display: block;
    margin: 15px auto 0;
  }
  .video-duration p {
    float: none;
    margin: 0px;
    text-align: center;
  }
  .pro-doctor-video {
    padding: 20px 25px;
    height: calc(100vh - 125px);
  }
  .pro-content {
    padding: 30px 0;
  }
  .proc-btn {
    margin-bottom: 20px;
  }
  .pro-content2 {
    padding: 0 15px;
    padding-top: 30px;
    width: 100%;
  }
  .payment-col h5 {
    text-align: center;
  }
  .payment-col h6 {
    text-align: center;
  }
  .doc-profile {
    text-align: center;
  }
  .pro-img {
    margin: 0px auto !important;
    float: none;
  }
  .doctor-profile ul.no-record li {
    text-align: center;
  }
  ul.user-list li {
    text-align: center;
  }
  .pro-bottom-col h5 {
    font-size: 14px;
  }
  .next-btn {
    width: 100px;
    height: 40px;
    line-height: 38px;
  }
  .morning-date ul li .time-box {
    width: 90px;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
  }
  .morning-top {
    font-size: 16px;
  }
  .morning-top img {
    height: 30px;
  }
  .medical-record {
    margin-top: 0px !important;
  }
  .prescription-history {
    margin-bottom: 16px;
  }
  .br-bottom {
    border-color: transparent !important;
  }
}
@media (min-width: 320px) and (max-width: 600px) {
  .morning-date ul li .time-box {
    width: 90px;
    height: 40px;
    line-height: 40px;
  }
  .morning-top {
    font-size: 14px;
  }
  .morning-top img {
    height: 30px;
  }
  .scrtabs-tabs-fixed-container ul.nav-tabs > li {
    padding: 0;
  }
  .logo img {
    height: 45px;
  }
  .login-btn {
    width: 100px;
    height: 40px;
    line-height: 36px;
    margin-top: 0px;
  }
  .video-col-right iframe {
    min-height: 750px !important;
  }
  .pro-bottom-col h5 {
    float: none;
    text-align: center;
  }
  .next-btn {
    display: block;
    margin: 0px auto;
    float: none;
  }
  .pro-bottom-col {
    margin-bottom: 20px;
  }
  .pataint-details {
    padding: 40px 30px;
  }
  .patient-content-col h3 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .patient-content-col h4 {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .patient-content-col label {
    font-size: 16px;
    width: 100%;
  }
  .radio-col3 {
    width: 30%;
  }
  .radio-col {
    width: 30%;
  }
  .cancel-btn {
    height: 45px;
    line-height: 45px;
  }
  .save-btn {
    height: 45px;
    line-height: 45px;
  }
  .modal-content {
    padding: 0px 0px;
  }
  .modal-header {
    margin-bottom: 8px;
  }
  .header-bottom-shadow {
    padding: 7px 15px 0 !important;
  }
  .mr-tab {
    margin-right: 20px !important;
  }
  .nav-tab {
    font-size: 16px !important;
  }
  .mob-view-img {
    width: 60px;
  }
}

.patient-data label {
  color: #f969a4;
}

.custom-padding {
  padding-bottom: 20px;
}

.next-avaliablity {
  color: #17bae0;
  font-size: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  font-family: Rubik, sans-serif;
}

.patient-content input {
  border: none;
  /*border-bottom: 1px solid #ababab;*/
  background-color: #f5f6f7;
  border-radius: 0;
}

.patient-content div {
  color: #f969a4;
}

.patient-content ul li {
  margin: 0;
  border-bottom: none;
}

.b-bm {
  border-bottom: 1px solid #eeae0103;
}
.disclaimer {
  width: inherit;
}

/***************** CHANGES TO DOCTOR VIDEO PRESCRIPTION *****************/

.video-col-left .doctor-video-collapse-header,
.patient-data .doctor-video-collapse-header {
  background-color: #17bbe1;
  color: white;
  cursor: pointer;
  font-size: 18px;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.video-col-left .doctor-video-collapse-header i {
  float: right;
}

.height-weight-labels {
  width: 250px;
}

.video-col-left .prescription-save-button {
  width: 150px;
  background-color: #17bbe1;
  border-radius: 6px; /*25px;*/
  color: white;
  font-size: 18px;
  padding: 5px 10px;
}

.video-col-left .prescription-preview-button {
  width: 150px;
  background-color: #f5f5f5;
  border-radius: 25px;
  color: #17bbe1;
  margin-left: 10px;
  border: none;
  font-size: 18px;
  font-weight: bolder;
  padding: 5px 10px;
}

.video-col-left .prescription-save-button-disabled {
  width: 150px;
  background-color: #a3a9b7;
  border-radius: 6px; /*25px;*/
  color: white;
  cursor: not-allowed;
  font-size: 18px;
  padding: 5px 10px;
}

.video-col-left .prescription-upload-container h6 {
  text-transform: uppercase;
  color: #1b2027; /*#f969a4;*/
}

.top-border-none {
  border-top: none !important;
}
.main_modal {
  min-width: 80%;
}
.modal-header {
  border-bottom: 0 none;
}
.close {
  color: #d63031 !important;
  opacity: 1;
}

.modal-content {
  overflow-y: auto;
  overflow-x: hidden;
}
.prescription-history {
  display: flex;
  justify-content: space-between;
  text-align: center;
}
.medical-record {
  margin-top: 20px;
}

.prescription-history-container {
  background: #ffffff 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #d6dae5;
  width: 100%;
}

.bullet {
  height: 5px;
  width: 5px;
  background: #a3a9b7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.bulletUpload {
  height: 5px;
  width: 5px;
  background: #a3a9b7;
  border-radius: 50%;
}

/* .prescription-file-name {
  width: 25%;
} */

.medical-record-container {
  display: flex;
  margin-left: auto;
}

.medical-record-heading {
  font-size: 16px;
  font-weight: medium !important;
  color: #2f2f2f;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
}

.medical-record-date {
  font: 14px;
  color: #a3a9b7;
}

.medical-record-type {
  font-size: 14px;
  color: #a3a9b7;
  display: flex;
}

.medical-record-type-doc {
  font-size: 14px;
  color: #a3a9b7;
  display: flex;
  align-items: center;
}

.medical-record-icon,
.view-icon {
  background: #e8fbff 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  font-size: 15px !important;
  padding: 8px;
  cursor: pointer;
  color: #5ad5f2 !important;
}

.view-disable-icon {
  color: #a3a9b7 !important;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  padding: 8px;
  font-size: 15px !important;
}

.medical-record-new {
  background: #e2ffd9 0% 0% no-repeat padding-box;
  border-radius: 20px;
  color: #2d8811;
  font-size: 19px;
  height: 31px;
  min-width: 63px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.prescription_selector {
  width: 150px;
  height: 40px !important;
  background: #17bae0 0% 0% no-repeat padding-box !important;
  border-radius: 6px !important;
  border: none !important;
  align-self: flex-start !important;
}

.prescription_selector:focus {
  box-shadow: 0 0 black !important;
}

.medical-node {
  background: #d7ecfa 0% 0% no-repeat padding-box;
  padding: 20px;
  font-size: 18px;
  font-weight: normal;
  color: #000000;
  display: flex;
}

.upload-document {
  font-size: 29px;
  color: #f969a4;
  font-weight: medium;
}

.upload-descrption {
  font-size: 16px;
  letter-spacing: 0.29px;
  color: #a3a9b7;
  text-align: left;
  max-width: 800px;
  margin: 20px 0px;
  padding-left: 7px;
}

.upload-descrption-video {
  font-size: 16px;
  letter-spacing: 0.29px;
  color: #a3a9b7;
  text-align: left;
  max-width: 800px;
  margin: 20px 0px;
  padding-left: 0px;
}

.exclamation-icon {
  color: #17bae0;
  font-size: 28px !important;
  margin-right: 20px;
  margin-top: 8px;
}

.trash-icon {
  background: #ffeaea 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  font-size: 15px !important;
  padding: 8px;
  cursor: pointer;
  color: #e02020 !important;
}

.note {
  font-weight: bold;
}

.notification-count {
  font-size: 20px;
  letter-spacing: 0px;
  color: #f969a4;
  font-weight: 600;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  padding: 7px 13px;
  border-radius: 50%;
  align-items: center;
}

.doctor-contanier {
  position: relative;
  height: 100vh;
  width: 100vw;
}

@media (max-width: 498px) {
  .prescription-history-container {
    width: 90vw;
  }

  .prescription-file-name {
    width: 100% !important;
  }
}

.header-bottom-shadow {
  border-top: 1px solid #00000029;
  padding: 10px 60px 0;
  border-bottom: 1px solid #d3d3d3;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;
}

.nav-tab {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #bcbcbc;
  font-weight: 600;
  border-bottom: 2px solid transparent;
  padding: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.nav-tab-active {
  color: #2f2f2f;
  border-color: #f969a4;
}

.icon-font {
  font-size: 24px;
}

.mr-tab {
  margin-right: 60px;
}

.br-bottom {
  border-bottom: 1px solid #00000029;
}

.drawer-heading {
  font-size: 25px;
  color: #2f2f2f;
  font-weight: 600;
}

.patient-row-heading {
  font-size: 12px;
}

@media (min-width: 320px) and (max-width: 768px) {
  .drawer-heading {
    font-size: 20px;
  }

  .medical-record-icon,
  .view-icon {
    width: 30px;
    height: 30px;
    padding: 4px;
    font-size: 12px !important;
  }

  .trash-icon {
    width: 30px;
    height: 30px;
    font-size: 12px !important;
    padding: 4px;
  }

  .medical-record-heading {
    font-size: 14px;
    max-width: 120px;
  }
  .medical-record-heading-icons {
    display: flex;
    padding-right: 30px;
  }

  .medical-record-date,
  .medical-record-type {
    font-size: 12px;
  }
}

.end-call-confirmation .modal-content {
  margin: 0px;
}

.scroll_style {
  overflow-y: scroll;
  overflow-x: hidden;
}

.scroll_style::-webkit-scrollbar {
  width: 16px;
}
.scroll_style::-webkit-scrollbar-track {
  border-radius: 10px;
}
.scroll_style::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
  /* background: #888; */
}
.select__menu-list {
  overflow-y: scroll;
  overflow-x: hidden;
}
.select__menu-list::-webkit-scrollbar {
  width: 8px;
  height: 0px;
}
.select__menu-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.select__menu-list::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
}
.select__menu-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.textarea-purpose::-webkit-scrollbar {
  width: 7px;
}
.textarea-purpose::-webkit-scrollbar-track {
  border-radius: 10px;
}

.textarea-purpose::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
  min-height: 2px;
}

@media (max-width: 1024px) {
  .for_call_patient {
    justify-content: center;
    align-items: center;
  }
  .dashboard_call_patient {
    display: flex;
  }
}

.prescription_modal .modal-content {
  height: calc(100vh - 8vw);
}

.medical-record-icon-disabled {
  color: #a3a9b7 !important;
  background: #f2f2f2 0% 0% no-repeat padding-box;
  border-radius: 5px;
  width: 30px;
  height: 30px;
  font-size: 15px !important;
  padding: 8px;
  cursor: not-allowed;
}

@media (max-width: 475px) {
  .medical-record-icon-disabled {
    font-size: 12px !important;
    padding: 4px;
  }
}
