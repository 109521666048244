@use "../../../Util/c2cConstants/colorConstants" as c;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.side_bar_main_view {
  width: 0;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: c.$C2C_WHITE;
  overflow-x: hidden;
  transition: width 0.5s;
  display: none;

  .sidebar-content {
    padding: 16px;
  }

  .close-icon {
    position: absolute;
    padding-left: 48px;
    top: 25px;
    right: 25px;
    cursor: pointer;
  }

  .close-icon img {
    width: 20px;
    height: 20px;
  }

  .sidebar_containt_view {
    color: c.$C2C_DARK_GRAY;
    font-weight: 600;
    margin-bottom: 10px;
  }

  .additional-content {
    margin-top: auto;
    padding: 20px;
    background-color: c.$C2C_WHITE;
    border-top: 1px solid c.$C2C_WHITE;
  }

  .side_bar_doctor_search {
    width: 320px;
    margin-top: 30px;
    margin-left: 30px;
    border: 1px solid #E1E1E1;
  }

  .side_bar_color {
    color: c.$C2C_DARK_BLUE;
  }

  .custom-sidebar-content {
    overflow-y: auto;
    padding: 30px
  }

  /* Styles for the FAQ link */
  .faq-link {
    color: c.$C2C_WHITE;
  }

  /* Styles for the bottom bar */
  .bottom-bar {
    bottom: 0;
    width: 100%;
    padding: 25px;
  }

  /* Styles for the icons and mail section */
  .bar-icons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .mail-section {
    display: flex;
    margin-bottom: 30px;
  }

  .side_bar_company_color {
    color: c.$C2C_DARK_GRAY;
    margin-left: 8px;
    height: 23px;
  }

  .side_bar_company_download_view {
    color: c.$C2C_DARK_GRAY;
  }

  .doctor-search-input-wrapper .input-holder.search-input {
    border-color: c.$C2C_GRAY_4;
  }
}

.sidebar-visible {
  width: 100%;
  display: block;
  z-index: 9
}


@media screen and (max-width: 768px) {
  .home_page_header_content_main .doctor-search-input-wrapper {
    width: 105%;
    height: 40px;
  }

  .side_bar_main_view .side_bar_doctor_search {
    margin-top: 20px;
    border: none;
  }

  .searched_value ul {
    width: 101%;
    margin-left: 0px;
    box-shadow: 0px 20px 20px #0D1C3B1A;


    li {
      border-bottom: 0px;
      // margin-left: -40px;
    }
  }

  .side_bar_main_view .custom-sidebar-content {
    padding: 30px 30px 0px 30px
  }


  .search_icon_outer_width {
    margin-left: 12px;
  }

  .doctor-search-input-wrapper .input-holder {
    border: 1px solid c.$C2C_GRAY_4;
  }

  .doctor-search-input-wrapper.isActive .searched_value {
    border-top: 1px solid c.$C2C_GRAY_4;
  }

  .doctor-search-input-wrapper .input-holder .search-input {
    padding: 10px;
  }
}