@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.doctor-login-with-otp-container {
  width: 25vw;

  .dlwoc-password-otp-header {
    display: flex;
  }

  .dlwoc-password-otp-header-title {
    font: normal normal normal 16px/19px font.$C2C_FONT_FAMILY;
    color: #b2b2b2;
    margin: 0px 24px 24px 0px;
    margin-bottom: 24px;
    cursor: pointer;
  }

  .dlwoc-password-otp-header-title-selected {
    font-weight: 500;
    color: #1d1d1d;
  }

  .dlwoc-mobile-input {
    margin-bottom: 24px;
  }

  .dlwoc-action-button {
    display: flex;
    justify-content: end;
    margin: 32px 0px;
  }
}

@media (max-width: 768px) {

  .doctor-login-with-otp-container {
    width: 100%;
  }
}