%common-style {
  display: flex;
  flex-direction: row;
}

.page-layout {
  width: 100%;
  background: #fafafb 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
}

.text-maq {
  text-align: center;
  font: normal normal bold 20px/34px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #2f2f2f;
  opacity: 1;
  padding: 30px;
  margin-top: 40px;
  @media screen and (min-width: 992px) {
    font: normal normal 600 25px/29px Rubik, sans-serif;
    text-align: center;
    letter-spacing: 0px;
    color: #2f2f2f;
    opacity: 1;
    padding: 30px;
    margin-top: 40px;
  }
}

.maq-question-style {
  font: normal normal normal 16px/22px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #2f2f2f;
  opacity: 1;

  @media screen and (min-width: 992px) {
    text-align: left;
    font: normal normal normal 18px/25px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #2f2f2f;
    opacity: 1;
  }
}

.maq-answer-style {
  text-align: left;
  font: normal normal normal 16px/22px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #2f2f2f;
  opacity: 1;

  @media screen and (min-width: 992px) {
    text-align: left;
    font: normal normal normal 16px/22px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #868686;
    opacity: 1;
  }
}

.toggle-qa {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;

  @media screen and (min-width: 992px) {
    max-width: 50vw;
    width: 50%;
    margin: auto;
  }
}

.questions-list-hr {
  width: 100%;
}
.questions-list-row {
  width: 100%;
  margin-bottom: 2vh;
}

.questions-list-question-adornment {
  text-align: right;
  cursor: pointer;
}

.align-content {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.text-format {
  width: 264px;
  height: 29px;
  text-align: center;
  font: normal normal 600 24px/30px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #2f2f2f;
  opacity: 1;
}

.expand-btn {
  @extend %common-style;
  justify-content: space-between;
  background-color: black;
  width: 600px;
}

.panel-header {
  border: 0px solid #cccc;
  font-weight: normal;
  border-radius: 5px 5px 0px 0px;
  @extend %common-style;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 8px;
}

.panel-header button {
  border: none;
  background: transparent;
}

.panel-header button:focus {
  outline: 0px auto -webkit-focus-ring-color;
}

.panel-body {
  border: 0px solid #fafafb;
  color: #333333;
  padding: 5px;
  border-top: none;
}

.questions-list-question {
  text-align: left;
  font: normal normal normal 18px/22px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #2f2f2f;
  opacity: 1;
}

.questions-list-answer {
  text-align: left;
  font: normal normal normal 16px/22px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #868686;
  opacity: 1;
  transition: visibility ease-in-out, opacity 0.35s ease-in-out,
    height 0.65s ease-in-out;
}
