.abha-card-view-conatiner {
  display: flex;
  align-items: center;
  justify-content: center;

  .abha-card {
    width: 546px;
    background: #ffffff;
    box-shadow: 3px 3px 30px #00000010;
    border-radius: 10px;
    padding: 30px;
  }

  @media screen and (max-width: 768px) {
    .abha-card {
      width: 345px;
      background: #ffffff;
      box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.062745098);
      border-radius: 10px;
      padding: 20px;
      margin: 20px 15px 0px 15px;
    }
  }
}