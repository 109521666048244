@use "../../../Util/c2cConstants/colorConstants.scss" as color;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.home-page-testimonial-container {
  .home-page-testimonial-container-content {
    padding: 70px 165px;
    background: transparent
      linear-gradient(
        180deg,
        rgba(23, 186, 224, 0) 0%,
        rgba(181, 213, 197, 0.3) 100%
      );
  }

  .home-page-testimonial-title {
    font: normal normal 500 30px/38px font.$C2C_FONT_FAMILY;
    color: color.$C2C_DARK_GRAY;
    text-align: center;
    margin-bottom: 50px;
  }

  .home-page-testimonial-list-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home-page-testimonial-left-right-arrow {
    cursor: pointer;
  }

  .home-page-testimonial-list {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  .home-page-testimonial-card {
    width: 267px;
    height: 399px;
    background: color.$C2C_WHITE;
    border-radius: 2px;
    padding: 15px 20px;
    margin: 30px 40px;
  }

  .home-page-testimonial-card-opacity {
    opacity: 0.5;
    overflow: hidden;
  }
  .home-page-testimonial-card-active {
    width: 267px;
    height: 465px;
    box-shadow: 0px 14px 20px rgba(23, 186, 224, 0.1490196078);
    overflow: hidden;
    position: relative;
    bottom: 0;
    transition: transform 250ms ease-in-out;

  }

  .home-page-testimonial-card-active::-webkit-scrollbar {
    display: none;
  }

  .home-page-testimonial-card-active:hover {
    overflow-y: auto;
    bottom: 10px;
    transform: translateY(-10px);

  }

  .home-page-testimonial-doctor-name {
    font-weight: 500;
    font-size: font.$PARAGRAPH_REGULAR;
    line-height: 20px;
    font-family: font.$C2C_FONT_FAMILY;
    color: color.$C2C_DARK_GRAY;
  }

  .home-page-testimonial-specialization {
    font-size: font.$PARAGRAPH_SMALL;
    line-height: 17px;
    font-family: font.$C2C_FONT_FAMILY;
    color: color.$C2C_DARK_GRAY;
  }

  .home-page-testimonial-profile {
    width: 99px;
    height: 101px;
    margin-bottom: 25px;
  }

  .home-page-testimonial-details {
    font-size: font.$PARAGRAPH_SMALL;
    line-height: 20px;
    font-family: font.$C2C_FONT_FAMILY;
    color: color.$C2C_CYNICAL_BLACK;
    margin-top: 15px;
  }

  .home-page-testimonial-left-right-arrow-container {
    display: flex;
    justify-content: center;

    .home-page-testimonial-left-right-arrow {
      margin: 0px 15px;
    }
  }

  .home-page-testimonial-profile-image {
    width: 99px;
    height: 101px;
    border: 1px solid color.$C2C_BLEACHED_SILK;
  }

  @keyframes fade {
    from {
      opacity: 0.1;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    .home-page-testimonial-container-content {
      padding: 40px 30px;
    }

    .home-page-testimonial-list-mobile-view {
      width: 100%;
      display: flex;
      flex-direction:column;
      align-items: center;
    }

    .home-page-testimonial-card {
      margin: 30px 25px;
    }

    .mobile-view-testimonial-view-container {
      display: flex;
      position: relative;
    }

    .testimonial-side-card-1 {
      position: absolute;
      top: 10%;
      right: 100%;
      height: 380px;
      width: 30px;
      background-color: #fff;
    }

    .testimonial-side-card-2 {
      position: absolute;
      top: 10%;
      left: 100%;
      height: 380px;
      background-color: #fff;
      width: 30px;
    }

    .home-page-testimonial-title {
      font-size: font.$HEADING_4;
      margin-bottom: 10px;
    }
  }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {

  .home-page-testimonial-container .home-page-testimonial-list {
    justify-content: center;
  }

}


@media only screen and (min-width: 820px) and (max-width: 1179px) {
  .home-page-testimonial-container .home-page-testimonial-container-content {
    padding: 70px 30px;
  }

  .home-page-testimonial-container .home-page-testimonial-card-active {
    width: 460px;
  }

}
