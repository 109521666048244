@use "../../../Util/c2cConstants/colorConstants" as color;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.abha-link-with-address-view {
  .alwav-heading {
    text-align: center;
    font-size: 30px;
    font-family: font.$C2C_FONT_FAMILY;
    font-weight: 600;
    color: color.$C2C_CYNICAL_BLACK;
    line-height: 36px;
    margin: 0px 100px;
  }

  .alwav-aadhar-abha-created-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }

  .alwav-aadhar-abha-created-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #4ea944;
    border-radius: 100px;
    width: 275px;
    height: 40px;
    padding: 0px 12px 0px 0px;
    margin-top: 13px;
  }

  .alwav-aadhar-abha-created-text {
    font-weight: 500;
    font-size: font.$PARAGRAPH_SMALL;
    font-family: font.$C2C_FONT_FAMILY;
    line-height: 22px;
    color: #4ea944;
    text-transform: capitalize;
  }

  .alwav-aadhar-abha-created-icon {
    width: 0px;
    height: 20px;
  }

  .alwav-card-tick-gif {
    height: 30px;
    border-radius: 50%;
    margin: 0px 5px 0px 0px;
  }

  .alwav-abha-id-container {
    display: grid;
    justify-content: center;
  }

  .alwav-abha-id-label {
    text-align: center;
    font-weight: 500;
    font-size: font.$PARAGRAPH_REGULAR;
    font-family: font.$C2C_FONT_FAMILY;
    line-height: 22px;
    color: color.$C2C_CYNICAL_BLACK;
    text-transform: capitalize;
    margin-bottom: 16px;
  }

  .alwav-abha-id-text {
    background: #f8f8f8;
    border: 1px solid #efefef;
    border-radius: 5px;
    font-size: font.$HEADING_5;
    font-family: font.$C2C_FONT_FAMILY;
    line-height: 22px;
    color: color.$C2C_CYNICAL_BLACK;
    padding: 13px 56px;
    margin-bottom: 16px;
  }

  .alwav-checkbox-container {
    display: grid;
    justify-content: center;
  }

  .alwav-checkbox-input-container {
    display: flex;
    justify-content: center;
    font-weight: 500;
    font-size: font.$HEADING_6;
    font-family: font.$C2C_FONT_FAMILY;
    line-height: 16px;
    color: color.$C2C_CYNICAL_BLACK;
  }

  .alwav-checkbox-input {
    margin: 0px 10px;
  }

  .alwav-checkbox-input-label {
    margin-left: 10px;
  }

  .alwav-create-link-button-container {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  .alwav-abha-suggestion-container {
    display: flex;
    // justify-content: center;
    margin: 20px;
    overflow: auto;
  }

  .alwav-abha-suggestion-list {
    display: flex;
    font-size: 14px;
    font-family: font.$C2C_FONT_FAMILY;
    color: color.$C2C_CYNICAL_BLACK;
  }

  .alwav-abha-suggestion-label {
    margin-right: 10px;
  }

  .abha-suggestion-list-wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  .alwav-abha-suggestion {
    color: color.$C2C_BLUE;
    cursor: pointer;
  }

  .alwav-abha-input-container {
    font-size: font.$PARAGRAPH_SMALL;
    font-family: font.$C2C_FONT_FAMILY;
    color: color.$C2C_CYNICAL_BLACK;
    line-height: 14px;
    display: grid;
    justify-content: center;
    margin: 17px 0px;
  }

  .alwav-abha-input-content {
    width: 285px;
    height: 48px;
    background: #f8f8f8;
    border: 1px solid #efefef;
    border-radius: 5px;
    display: flex;
  }

  .alwav-abha-input-content-error {
    border: 1px solid #fd1717;
  }

  .alwav-abha-input-suffix {
    width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .alwav-abha-input {
    width: 100%;
    border: none;
    padding-left: 20px;
    border-radius: 5px 0px 0px 5px;
  }

  .alwav-abha-input-error {
    font-family: font.$C2C_FONT_FAMILY;
    font-size: font.$PARAGRAPH_REGULAR;
    line-height: 17px;
    color: #fd1717;
    margin-top: 8px;
  }
}
