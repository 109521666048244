.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.add_new_patient {
  border-style: dashed;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  /* height: calc(100% - 12px); */
  cursor: pointer;
  min-width: 160px;
  max-width: 160px;
  margin-bottom: 7px;
  margin-right: 10px;
  min-height: 130px;
}
.card-group.existing-patient-list {
  display: flex;
  overflow-x: scroll;
  flex-flow: nowrap;
}
.existing-patient-list {
  overflow-x: scroll;
  flex-flow: inherit !important;
}
.existing-patient-list::-webkit-scrollbar {
  display: none;
}
.card-deck.existing-patient-list .card {
  min-width: 160px;
  max-width: 160px;
  margin: 0px 10px;
  box-sizing: border-box;
  cursor: pointer;
  /* border-left: 1px solid lightgrey; */
}
.example::-webkit-scrollbar {
  display: none;
}
/* .col-md-10.patient_list .inner::-webkit-scrollbar {    //commenting to enable scroll in doctor slug
  display: none;
} */
.back_forword {
  position: fixed;
  bottom: 0;
  background: white;
  width: 100%;
  z-index: 1;
}
.docDetails {
  background-color: white;
}
.col-md-4.left-section {
  background-color: white;
  min-width: 250px;
  max-width: 340px;
}
.pro-content-col.appointment_details .row {
  justify-content: space-between;
}
.pro-content-col.appointment_details {
  box-shadow: none;
  padding: 30px;
  background-color: transparent;
  margin-top: 4rem;
}
.back_continue_buttons {
  display: flex;
  /* width: 350px; */
  justify-content: center;
  /* float: right; */
}
.date-and-time {
  /* min-width: 275px;
  max-width: 275px; */
  width: max-content;
  gap: 10px;
  height: 45px;
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
  line-height: 45px;
  font-size: 16px;
  padding: 0px 30px;
  margin: 0 auto;
  background: #f7fdff;
  color: #17bae0;
  align-items: center;
}
.date-and-time img {
  height: 20px;
}
.book-appoint-date-time11 img {
  height: 32px;
  margin-right: 4%;
}
.continue_button,
.back_button {
  margin-right: 30px;
  width: 200px;
  height: 45px;
  text-align: center;
  border-radius: 50px;
  line-height: 45px;
  font-size: 16px;
  padding: 0px 12px;
}
.accept_button {
  background-color: #17bae0;
  border-radius: 50px;
  border: none;
  color: white;
  font-size: 12px;
  padding: 10px 25px;
  margin-right: 1rem;
}
.decline_button {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d6dae5;
  border-radius: 50px;
  padding: 10px 25px;
  color: #17bae0;
  font-size: 12px;
}
.appointment-details {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
  height: 80px;
  margin-bottom: 0;
}
.modal-btn {
  margin-top: 3rem;
}
.modal-btn .back_button {
  justify-content: center;
  margin-top: 2rem;
}
.continue_button {
  background-color: #17bae0;
  color: white;
  cursor: pointer;
}

.cont-btn-disabled {
  background-color: #a3a9b7;
  cursor: not-allowed;
}

.back_button {
  color: #17bae0;
  background-color: white;
  border: 1px solid #d6dae5;
}
.continue_button img {
  height: 15px;
  margin-left: 10px;
}
.card.active {
  border: 1px solid #17bae0;
  position: relative;
}
.checked-image {
  position: absolute;
  top: 5px;
  right: 5px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

button {
  outline: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.react-timekeeper__time-dropdown.css-1qk1skk-TimeDropdown,
.react-timekeeper__time-dropdown.css-1r3299-TimeDropdown {
  display: none !important;
}

.appointments-patient-accounts-list {
  margin: 30px 0px 0px;
  width: fit-content;
  padding: 20px;
  max-height: 300px;
  /* box-shadow: 1px 2px 3px 4px; */
  overflow-y: scroll;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}
.grey_line {
  position: absolute;
  height: 2px;
  width: 100%;
  top: 125px;
  background-color: #8888880d;
}
.patients {
  display: flex;
  height: 80%;
  padding: 0px 20px !important;
}
.select-patients {
  padding: 0px 20px !important;
}

.select-patient {
  line-height: 80px;
  font-size: 20px;
}
.continue_button span {
  color: #17bae0;
  padding: 10px;
  font-size: 16px;
  border-radius: 35px;
  background: #f7fdff;
}
/* .pt-4.pb-1.footer {
  display: none;
} */
@media (max-width: 576px) {
  .appointments-patient-accounts-list {
    margin: 30px auto 0 10px;
    width: 90%;
  }
  .card-group.existing-patient-list > .card + .card {
    border-left: initial;
    margin: 0px 10px;
  }
  .existing-patient-list {
    overflow-x: scroll;
    flex-flow: inherit !important;
  }
}

.phone-number-select {
  width: 40px;
  text-align: center;
  padding: 6px 0px;
  /* border: 1px solid; */
  border: 2px solid #d6dae5;
  border-right: none;
}

@media screen and (max-width: 991px) {
  .col-md-4.left-section {
    min-width: 200px;
    max-width: 200px;
    height: 200px;
  }
}
@media screen and (max-width: 767px) {
  .pro-content-col.appointment_details {
    padding: 15px;
  }
  .pro-calender {
    float: left;
  }
  .select-patient {
    line-height: 25px;
    font-size: 15px;
    float: left;
    margin: 27px 0px;
    text-align: left;
    padding: 0 20px;
  }
  .appointment-details {
    font-size: 15px;
    text-align: left;
    height: auto;
  }
  .date-and-time {
    height: 45px;
    margin: 12px 0 0;
  }
  .add_new_patient {
    justify-content: space-evenly;
    padding: 16px 12px;
    flex-direction: row;
    margin: 0 22px;
    /* width: calc(100% - 44px) !important; */
    border-color: #eeeeee;
    min-height: inherit;
  }
  .grey_line {
    top: 112px;
  }
  .add_new_patient span {
    color: #17bae0;
  }
  .card-deck.existing-patient-list .card {
    min-width: 100%;
    max-width: 100%;
    margin: 10px 0px;
  }
  .col-md-9.patient_list {
    padding: 0;
  }
  .card-body {
    display: flex;
    justify-content: space-between;
  }
  .col-md-12.patients {
    flex-direction: column;
    padding: 0;
  }
  .continue_button,
  .back_button {
    width: 150px;
    margin: 0 !important;
  }
  .back_continue_buttons {
    justify-content: space-evenly;
    width: 325px;
  }
}
@media screen and (max-width: 767px) {
  .date-and-time span {
    width: initial;
  }
}
@media screen and (max-width: 320px) {
  .date-and-time {
    min-width: 245px;
    max-width: 245px;
    font-size: 14px;
  }
  .back_continue_buttons {
    width: 290px;
  }
  .continue_button,
  .back_button {
    width: 130px;
  }
}
/* removing  horizontal scroll */
body {
  overflow-x: hidden;
}
