.share-with-pharmacy-btn-container {
    display: table;
    margin-top: 19px;

    .share-with-pharmacy-btn {
        display: flex;
        align-items: center;
        cursor: pointer;
        padding: 7px 15.5px;
        color: #17BAE0;
        font: normal normal normal 12px/14px Rubik, sans-serif;
        background-color: #17BAE01A;
        border-radius: 4px;
    }

    .share-with-pharmacy-icons {
        font-size: 15px;
        margin-right: 7px;
        color: #17bae0;
    }
}