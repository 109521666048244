@use "../../../Util/c2cConstants/colorConstants" as color;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.abha-profile-container {
    .apc-header {
        width: 100%;
        height: 76px;
        background: color.$C2C_WHITE;
        border-top: 1px solid #D6DAE5;
        border-bottom: 1px solid #D6DAE5;
    }

    .apc-header-title {
        color: color.$C2C_CYNICAL_BLACK;
        padding-left: 35px;
        padding-top: 25px;
        padding-bottom: 29px;
        font: normal normal bold 18px/22px font.$C2C_FONT_FAMILY;
    }

    .apc-top-container {
        display: flex;
        padding-bottom: 21px;
        align-items: center;
    }

    .apc-top--container-text-view {
        padding-left: 24px;
    }

    .apc-top-container-text {
        font: normal normal normal 18px/33px font.$C2C_FONT_FAMILY;
        color: color.$C2C_GRAT_1;
    }

    .apc-top-container-patient-phone {
        font: normal normal normal 25px/33px font.$C2C_FONT_FAMILY;
        color: color.$C2C_CYNICAL_BLACK;
    }

    .abha-horizontal-dotted-line {
        border-top: 1px dashed rgba(29, 29, 29, 0.2);
        background: transparent;
    }

    .aps-padding-conatiner {
        padding: 30px;
    }

    @media screen and (max-width: 768px) {
        .apc-header-title {
            padding: 17px 20px;
        }

        .aps-padding-conatiner {
            padding: 17px 20px;
        }

        .apc-profile-title {
            padding-bottom: 16px;
        }
    }

}