.verify-otp-modal {
  .modal-content {
    padding: 30px 60px !important;
    border-radius: 14px !important;
  }
  h2 {
    font-size: 24px;
    color: black;
    font-weight: 600;
    margin: 0 0 6px;
  }
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .input-group {
    width: 80%;
    margin: 0 auto 20px;
    .input-group-prepend {
      margin-right: 0;
    }
    .input-group-text {
      background: #f2f2f2;
      border: 0;
      border-right: 1px solid #ccc;
    }
    .form-control {
      background: #f2f2f2;
      border: 0;
      font-size: 14px;
    }
  }
  .btn {
    padding: 12px 30px;
    min-width: inherit;
    font-weight: 600;
    font-size: 14px;
  }
}
.verify-number-modal {
  .modal-content {
    padding: 30px 60px !important;
    border-radius: 14px !important;
  }
  h2 {
    font-size: 24px;
    color: black;
    font-weight: 600;
    margin: 0 0 6px;
  }
  p {
    font-size: 16px;
    margin-bottom: 20px;
  }
  .form-group {
    width: 80%;
    margin: 0 auto 20px;
    .label-main {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 12px;
      font-size: 13px;
      p {
        margin: 0;
        color: #9c9c9c;
        font-size: 13px;
      }
      a {
        color: #19bae0;
        font-weight: 600;
      }
    }
    .label-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 12px;
      font-size: 13px;
      p {
        margin: 0;
        font-size: 13px;
      }
      a {
        color: #19bae0;
        font-weight: 600;
      }
    }
    .form-control {
      background: #f2f2f2;
      border: 0;
      font-size: 14px;
      height: 42px;
    }
  }
  .btn {
    padding: 12px 30px;
    min-width: inherit;
    font-weight: 600;
    font-size: 14px;
  }
}
@media(max-width: 767px){
  .video-section {
    .room {
      position: relative;
    }
  }
}
.patient-video-section {
  background-color: #f5f5f5;
  padding: 42px 40px;
  h2 {
    font-size: 36px;
    color: #f969a4;
    margin: 0 0 12px;
  }
  p {
    color: #717171;
    margin-bottom: 20px;
  }
  .file {
    position: relative;
    overflow: hidden;
    input {
      position: absolute;
      font-size: 50px;
      opacity: 0;
      right: 0;
      top: 0;
    }
  }
  .btn-01 {
    border: 0;
    background-color: #19bae0;
    text-transform: uppercase;
    font-size: 13px;
    padding: 6px 20px;
    font-weight: 600;
    letter-spacing: 1px;
    box-shadow: none !important;
    color: white;
  }
  video {
    width: 100%;
  }
  .video-txt {
    background: #d7d8da;
    margin: -6px 0 0;
    padding: 15px 18px;
  }
  .local-participant {
    position: absolute;
    width: 160px;
    overflow: hidden;
    right: 30px;
    top: 15px;
    z-index: 100;
    @media(max-width: 767px){
      width: 50%;
      top: auto;
      bottom: 72px;
      right: 0;
    }
  }
  .remote-participants {
    position: relative;
    min-height: 400px;
    background: #000;
    @media(max-width: 767px) {
      min-height: 200px;
      padding-bottom: calc(50% - 30px);
    }
  }
  .remote-participants .participant + .participant {
    position: absolute;
    width: 160px;
    overflow: hidden;
    right: 15px;
    top: 150px;
    z-index: 100;
    @media(max-width: 767px){
      width: 50%;
      top: auto;
      bottom: 0;
      left: 0;
      right: auto;
    }
  }
  .call-action-btn {
    position: absolute;
    bottom: 55px;
    left: 0;
    right: 0;
    text-align: center;
    @media(max-width: 767px){
      position: static;
      background: #e9ecef;
      padding: 10px 0;
    }
    button {
      width: 52px;
      height: 52px;
      border-radius: 52px;
      line-height: 52px;
      border: 0;
      background: white;
      color: black;
      padding: 0;
      margin: 0 12px;
      &.endCall {
        background: red;
        color: white;
      }
    }
  }
}
