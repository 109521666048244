@use "../../../../Util//c2cConstants/colorConstants.scss" as color;
@use "../../../../Util//c2cConstants/fontFileConstants.scss" as font;

.abha-patient-profile-details-container {
    width: 542px;
    background: #FFFFFF 0% 0% no-repeat padding-box;


    .patient-profile-heading-btn-wrapper {
        display: flex;
        align-items: center;
        padding: 20px 20px 20px 32px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.2)
    }

    .abha-patient-profile-details-close-icon {
        display: flex;
        justify-content: end;
    }

    .abha-patient-profile-headinng {
        font: normal normal 500 24px/28px font.$C2C_FONT_FAMILY;
        color: #171717;
        flex: 1;
    }

    .abha-patient-details-btn-wrapper {
        padding: 16px 32px 32px;
    }

    .patient-personal-details-wrapper {
        margin-bottom: 32px;
    }

    .patient-personal-detail {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
    }

    .patient-detail-title-wrapper {
        display: flex;
        color: #F969A4;
        align-items: center;
        justify-content: space-between;
        width: 28%;
        margin-right: 37px;
    }

    .patient-detail-title {
        font: normal normal 500 16px/19px font.$C2C_FONT_FAMILY;
        padding-right: 15px;
    }

    .patient-detail-value {
        font: normal normal normal 16px/19px font.$C2C_FONT_FAMILY;
        color: #171717;
        overflow-wrap: anywhere;
        width: 66%;
    }

    .download-your-abha-btn {
        background: #17BAE0 0% 0% no-repeat padding-box;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font: normal normal 500 16px/19px font.$C2C_FONT_FAMILY;
        color: #FFFFFF;
        width: 250px;
        height: 50px;
        cursor: pointer;
    }

    @media screen and (max-width: 768px) {
        width: 100%;

        .patient-profile-heading-btn-wrapper {
            padding: 18px 18px 18px 24px;
        }

        .abha-patient-profile-otp-container {
            padding: 24px 19px 24px 24px;
        }

        .abha-patient-details-btn-wrapper {
            padding: 24px 19px 24px 24px;
        }

        .patient-personal-details-wrapper {
            margin-bottom: 24px;
        }

        .patient-detail-title-wrapper {
            width: 39%;
            margin-right: 24px;
        }

        .patient-detail-title {
            font: normal normal 500 14px/17px font.$C2C_FONT_FAMILY;
            padding-right: 10px;
        }

        .patient-detail-value {
            width: 53%;
            font: normal normal normal 14px/17px font.$C2C_FONT_FAMILY;
        }
    }
}