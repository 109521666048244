.input {
  display: none;
}
.multiFileSelectorDiv{
  display: flex;
  width: fit-content;
  flex-direction: column;
}
.previewedImagesRoot {
  display: grid;
  grid-template-columns: repeat(auto-fit, 150px);
  justify-content: center;
  margin-top: 2px;
  background: #dae0e2;
  padding: 1rem;
}

.previewedNamesRoot {
  display: grid;
  gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 2fr));
  padding: 1rem;
  justify-content: center;
  margin-top: 2px;
  background: #dae0e2;
}

.previewedImageRoot {
  position: relative;
}

.previewedImage {
  width: 120px;
  margin: 0.5rem 0;
}

.previewedNameRoot {
  min-height: 40px;
  width: fit-content;
}

.previewedNameRoot > * {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  padding: 0 8px 0 32px !important;
  word-break: break-all;
}

.crossedIcon {
  position: absolute;
  left: 6px;
  top: 12px;
  cursor: pointer;
}

.crossedIconName {
  position: absolute;
  left: 10px;
  cursor: pointer;
}

@media (min-width: 600px) {
  .previewedImagesRoot {
    justify-content: flex-start;
  }

  .previewedNamesRoot {
    justify-content: flex-start;
  }
}
