@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;
@use "../../../Util/c2cConstants/colorConstants" as c;

.home-page-about-main-container {
  .home_page_header_benefits_containt {
    top: 1527px;
    margin-right: 30px;
    width: 255px;
    height: 280px;
    background: c.$C2C_WHITE;
    border: 2px solid #f8f8f8;
    border-radius: 2px;
    opacity: 1;
  }

  .benefits-title-heading {
    font-size: font.$BUTTON_MEDIUM;
    font-weight: 500;
    padding: 20px 10px 10px 20px;
  }

  .benefits-pera-text {
    width: 210px;
    height: 65px;
    padding-left: 20px;
    margin-right: 30px;
    font-weight: 400;
    font-size: font.$PARAGRAPH_SMALL;
    font-family: font.$C2C_FONT_FAMILY;
  }

  .home_page_header_benefits_title_main {
    display: flex;
    justify-content: space-between;
    padding: 86px 160px 0px 165px;
  }

  .home_page_header_benefits_arrow_view_container {
    width: 24px;
  }

  .home_page_header_benefits_arrow_view {
    height: 10px;
    width: 24px;

    &:hover {
      animation: slide1 1s ease-out infinite;
    }
  }

  .home_page_header_benefits_left_arrow_view {
    height: 10px;
    width: 24px;
    margin-left: 10px;

    &:hover {
      animation: slide1 1s ease-in-out infinite;
    }
  }

  @keyframes slide1 {

    0%,
    100% {
      width: 15px;
    }

    50% {
      width: 24px;
    }
  }

  .home_page_header_benefits_containt_view::-webkit-scrollbar {
    width: 0;
    display: none;
  }

  .home_page_header_benefits_containt_view {
    overflow: hidden;
    height: 300px;
    margin-top: 20px;
  }

  .home_page_header_benefits_description {
    width: 210px;
    height: 65px;
  }

  .home_page_about_containt_benefits_view {
    display: flex;
  }

  .home_page_header_benefits_containt:hover {
    background: linear-gradient(to bottom,
        c.$C2C_PALE_IVORY 0%,
        c.$C2C_PALE_IVORY 35%,
        c.$C2C_SAGA_GREEN 35%,
        c.$C2C_SAGA_GREEN 100%);
  }

  .lightGrayArrow {
    filter: brightness(70%) grayscale(100%);
  }

  .home_page_header_benefits_title {
    font-weight: 500;
    padding: 10px;
    font-size: font.$HEADING_2;
    margin-left: -10px;
  }

  .left-right-arrow {
    left: calc(50% - 12px);
    display: flex;
    align-items: center;
  }

  .home_page_header_benefits_containt_view {
    overflow: auto;
    overflow-y: hidden;
    padding: 0px 165px;
  }

  .home_page_header_benefits_containt div div {
    font-size: font.$BUTTON_MEDIUM;
    font-weight: 400;
    padding: 10px;
  }

  .home_page_header_benefits_containt img {
    padding: 20px;
  }

  .benefits-header-container {
    display: flex;
    justify-content: center;
  }

  .arrow-images {
    margin-left: 40px;
  }

  .arrow-images img {
    cursor: pointer;
    margin-right: 10px;
  }

  .heading {
    font-size: 25px;
    font-weight: 500;
    margin-bottom: 20px;
    font-family: font.$C2C_FONT_FAMILY;
  }

  .paragraph {
    margin-bottom: 20px;
    font-size: font.$PARAGRAPH_REGULAR;
    font-family: font.$C2C_FONT_FAMILY;
    width: 540px;
  }

  .about-mobile-content {
    margin-bottom: 10px;
  }

  .mobile-heading {
    font-size: 22px;
    font-weight: 500;
    font-family: font.$C2C_FONT_FAMILY;
  }

  .mobile-paragraph {
    margin-bottom: 10px;
  }

  .mobile-img {
    max-width: 100%;
  }

  .mobile-img-web {
    width: 526px;
    height: 392px;
  }

  .horizontal-line {
    width: 100%;
    height: 1px;
    background-color: c.$C2C_GRAY_4;
  }

  .home_page_about_containt_arrow_disable {
    pointer-events: none;
    opacity: 0.3;
  }

  .home_page_about_containt_view {
    display: flex;
    justify-content: space-between;
    padding: 0px 165px;
    border-bottom: 0.5px solid c.$C2C_GRAY_4;
    align-items: center;
  }
}

@media screen and (min-width: 768px) {
  .header-text {
    font-size: font.$BUTTON_MEDIUM;
    font-weight: bold;
    font-family: font.$C2C_FONT_FAMILY;
  }
}

@media screen and (max-width: 768px) {
  .home-page-about-main-container .home_page_about_containt_view {
    display: flex;
    justify-content: space-between;
    padding: 0px;
    margin-bottom: 40px;
  }

  .home-page-about-main-container .home_page_header_benefits_containt_view {
    padding: 0px 28px;
  }

  .arrow-images {
    margin-left: 30px;
    padding-top: 5px;
  }

  .header-text {
    font-size: font.$HEADING_8;
    font-weight: 500;
    font-family: font.$C2C_FONT_FAMILY;
  }

  .home-page-about-main-container .about-mobile-content {
    margin-bottom: 10px;
    padding: 30px 10px 10px 25px;
  }

  .home-page-about-main-container .mobile-paragraph {
    margin-top: 10px;
    padding: 0px 20px 0px 25px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .home-page-about-main-container {
    margin-top: 40px;
  }

  .home-page-feature-container .explore-our-features-btns-wrapper {
    width: 100vw;
    align-items: center;
  }

  .home-page-feature-container .explore-our-features-btn {
    width: 90%;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1024px) {
  .home-page-about-main-container .home_page_about_containt_view {
    padding: 30px 30px 0px 30px;
  }

  .home-page-about-main-container .mobile-img-web {
    width: 256px;
    height: 247px;
  }

  .home-page-about-main-container .home_page_header_benefits_containt_view {
    padding: 0px 30px;
  }

  .home-page-about-main-container .left-right-arrow {
    margin-top: 20px;
  }
}