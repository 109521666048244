.bottom-show-custom-modal {
  .modal-backdrop {
    background: rgba(0, 0, 0, 0.5) !important;
  }

  .modal-dialog {
    margin: 0 0.5rem 0 0.5rem !important;
    display: flex;
  }

  .modal-content {
    border-radius: 0 !important;
    margin: auto 0 0 0 !important;
    padding: 0 !important;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

.custom-modal-design {
  padding-right: 0px;
  &.fade {
    pointer-events: initial;
  }
  .br-0 {
    border-radius: 0 !important;
  }

  .modal-bottom {
    padding: 0px !important; //0 0 18px !important;
  }
}

.custom-modal-message-design {
  .modal-bottom {
    padding: 0px !important; //0 0 18px !important;
    border-radius: 10px !important;
  }
}
.custom-modal-message-design .modal-dialog-scrollable {
  width: 40%;
  height: 100%;
}
.mobFollowUp {
  display: none;
}
@media screen and (min-width: 320px) and (max-width: 768px) {
  .custom-modal-message-design .modal-dialog-scrollable {
    width: fit-content;
  }
  .custom-modal-design {
    .modal-bottom {
      padding: 0 19px 14px !important;
      margin-top: 40% !important;
    }
    .centered_aline {
      padding-bottom: 0px !important;
      // .modal-body {
      //   .top_scroll {
      //     padding: 0px 20px !important;
      //   }
      // }
    }
  }
  .container-eligible .star-rating-button,
  .container-eligible .star-rating-button {
    margin: 10px !important;
  }
  .modal-dialog.modal-lg.modal-dialog-scrollable .container-eligible {
    padding: 20px !important;
  }
  .modal-dialog.modal-lg.modal-dialog-scrollable .container-eligible.p60 {
    padding: 20px 60px !important;
  }
  .mobFollowUp {
    display: block;
  }
  .custom-modal-message-design {
    .modal-bottom {
      padding: 0px !important; //0 19px 14px !important;
      // margin-top: 50% !important;
    }
  }
  .book-appoint-header.book-appoint-header1-mob {
    display: flex !important;
    margin: 20px 15px;
    align-items: center;
    justify-content: space-between;
  }
  .book-appoint-header.book-appoint-header1 {
    display: none !important;
  }
  .book-appoint-header1 {
    display: none;
  }
}

@media screen and (max-width: 320px) {
  .custom-modal-design {
    .modal-bottom {
      margin-top: 30% !important;
    }
  }

  .custom-modal-message-design {
    .modal-bottom {
      margin-top: 40% !important;
    }
  }
}

.book-appoint-header.book-appoint-header1-mob {
  display: none;
}
.book-appoint-header.book-appoint-header1 {
  display: flex;
}

.dummy {
  .modal-content {
    width: 60%;
    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }
}
.modal_scroll {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 400px;
}

.modal_scroll::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
  background: #888;
  min-height: 120px;
}
