@use "../../../../Util//c2cConstants/colorConstants.scss" as color;
@use "../../../../Util//c2cConstants/fontFileConstants.scss" as font;

.abha-patient-profile-otp-container {
  padding: 16px 32px 32px;

    .download-abha-otp-wrapper {
        margin-bottom: 24px;
    }

    .otp-linked-mobile-number-pera {
        font: normal normal normal 14px/22px font.$C2C_FONT_FAMILY;
        color: #171717;
        width: 75%;
        margin: 0px 0px 15px 0px;
    }

    .aadhaar-number-heading {
        font: normal normal 500 14px/22px font.$C2C_FONT_FAMILY;
        color: #171717;
    }

    .verify-otp-btn {
        background: #17BAE0 0% 0% no-repeat padding-box;
        border-radius: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font: normal normal 500 16px/19px font.$C2C_FONT_FAMILY;
        color: #FFFFFF;
        width: 153px;
        height: 50px;
        cursor: pointer;
    }

    .verify-otp-btn-disabled {
      background: #B2B2B2;
      cursor: not-allowed;
    }

    .abha-otp-input-wrapper {
      display: flex;
      align-items: center;
      margin-top: 16px
    }

    .otp-group {
        display: flex;
        width: 100%;
        max-width: 300px;      
    }

    .abha-otp-input::placeholder {
      font: normal normal normal 20px/2px font.$C2C_FONT_FAMILY;
      letter-spacing: 2.1px;
      color: #BEBEBE;
    }

      .abha-otp-input-error {
        width: 41px;
        height: 41px;
        border: 1px solid #FD1717;
        border-radius: 5px;
        text-align: center;
        font-size: 14px;
        margin-right: 10px;
        background-color: #F8F8F8;
      }

      .otp-timer-error-msg-resend-btn-wrapper {
        width: 295px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .resend-otp-timer {
        font: normal normal normal 16px/19px font.$C2C_FONT_FAMILY;
        display: flex;
        align-items: center;
        color: #7B7B7B;
      }

      .resend-otp-button {
        font: normal normal 500 16px/19px font.$C2C_FONT_FAMILY;
        color: #B2B2B2;
        cursor: pointer;
      }

      .resend-otp-button-disabled {
        font: normal normal normal 16px/19px font.$C2C_FONT_FAMILY;
        color: #B2B2B2;
        cursor: not-allowed;
      }

      .otp-error {
        font: normal normal normal 14px/17px font.$C2C_FONT_FAMILY;
        color: #FD1717;
        margin: 10px 0px 0px 0px;
      }

      .resend-otp-running-timer {
        font: normal normal normal 16px/19px font.$C2C_FONT_FAMILY;
        color: #249F24;
        margin: 0px 0px 0px 5px;
      }

    /****************** abha otp input component ********************/

    .abha-otp-input-container {
      justify-content: flex-start;
    }

    .aoi-otp-input {
      font: normal normal normal 14px/2px font.$C2C_FONT_FAMILY;
      color: #6A6A6A;
    }

    .aoi-otp-button {
      justify-content: flex-start;
      width: 290px;
    }

    .aoi-otp-input:first-child {
      margin-left: 0;
    }

    .aoi-resend-time-with-button {
      margin-right: 0;
      margin-bottom: 0;
      margin-left: 0;
      padding: 0;
    }

    .aoi-resend-button {
      text-decoration: none;
    }

    .aoi-otp-input-error-content {
      margin: 8px 0px 0px 0px;
      padding: 0;
    }

    /************************************************************************/


    @media screen and (max-width: 768px) {
      .otp-linked-mobile-number-pera {
        width: 100%;
      }
    }



}
