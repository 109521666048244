span.medicine-name {
  text-transform: uppercase;
  font-weight: bold;
  padding-bottom: 10px;
  display: inline-block;
  padding-top: 10px;
}
.mob_tables {
  display: none;
}
.for_help_web img {
  height: 20px;
}
.emergency_number_mob {
  display: none;
}
.emergency_number {
  display: flex;
}
.follow_up_date {
  margin-left: -50px;
}
.doctor_number {
  display: flex;
}
.no_history {
  margin-right: 30px;
}
.dignsis {
  text-transform: uppercase;
  font-weight: bold;
  padding-top: 15px;
}
.grid-container-sangharsha {
  width: 100%;
  min-width: 100%;
  padding: 0px 0px 0px 30px;
}
.mob_prescription {
  display: none;
}
.medi-describe {
  width: 100%;
  height: 400px;
  display: inline-block;
  margin-top: 100px;
}
.for_help_web {
  display: flex;
}
.go_back {
  display: none;
}
.for_help_mob {
  display: none;
}
.float-right.signature_section {
  position: absolute;
  bottom: 0;
  right: 0;
}
.mob_patient_data {
  display: none;
}
.left_patient .address {
  margin-right: 40px;
}
.left_patient .name {
  margin-right: 30px;
}
.right_patient {
  width: 50%;
  display: flex;
  justify-content: space-between;
}
.left_patient b,
.right_patient b {
  margin-right: 15px;
}
.left_patient_data {
  width: 60%;
}
.left_patient_data,
.right_patient_data {
  width: 50%;
}
.patient_data {
  width: 100%;
  display: flex;
}
.left_patient {
  display: flex;
}
.signature {
  width: 95%;
  /* text-align: right; */
  padding-right: 50px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}
.responsive-padding {
  padding: 20px;
}
.responsive-padding td.patient-details {
  display: flex;
  /* padding: 20px; */
}
.patient-details span {
  font-weight: bold;
}

.patient-details-guide {
  display: flex;
  justify-content: flex-end;
  text-align: left;
  letter-spacing: 0px;
  color: #2f2f2f;
  opacity: 1;
  font-size: 16px;
}

.patient-details-guide span {
  cursor: pointer;
}

.patient-details-guide i {
  color: #17bae0;
}
.responsive-padding .b {
  font-family: Rubik,  sans-serif;
}
.headerStyle {
  /* width: 92.5%; */
  padding: 10px 50px;
  background-color: #dae0e2;
  display: block;
}
.bodyStyle {
  width: 100%;
  margin: 0px auto;
  font-family: "Roboto", sans-serif;
}
.tBody {
  padding-top: 5px;
  display: inline-block;
  display: flex;
  position: relative;
  padding-bottom: 100px;
}
.tBody ul {
  list-style-type: square !important;
  display: flex;
  flex-direction: column;
}
.tables {
  margin: 3rem 0px;
}
.table {
  border: 1px solid #e8e8e8;
  margin-top: 10px;
}
.rows {
  display: flex;
}
.dignsis_data p,
.no_history {
  font-family: Rubik,  sans-serif;
  font-size: 14px;
  margin-bottom: 5px;
  color: #404760;
}
.user_info {
  color: #404760;
  font-size: 14px;
  font-family: Rubik,  sans-serif;
}
.tBody ul li,
.medicine_table_data ul li {
  display: list-item;
  margin-bottom: 5px;
  padding: 0;
  margin-left: 20px;
  list-style-type: disc;
  color: #404760;
  font-family: Rubik,  sans-serif;
  font-size: 14px;
}
.float-right.signature_section img {
  height: 30px;
}
.doc_sign img {
  height: 40px;
}
.medicine_data {
  border-top: 2px solid #333;
  /* border-bottom: 2px solid #333; */
}
.table-striped > tbody > tr:nth-child(2n + 1) > td,
.table-striped > tbody > tr:nth-child(2n + 1) > th {
  background-color: #ffffff;
  color: #404760;
  font-weight: 400;
}
.table-striped > tbody > tr:nth-child(2n) > td,
.table-striped > tbody > tr:nth-child(2n) > th,
.table-striped > tbody > tr:nth-child(2n) > td,
.table-striped > tbody > tr:nth-child(2n) > th {
  background-color: #f5f5f5;
  color: #404760;
  font-weight: 400;
}

.table-striped > thead > tr {
  background-color: #f5f5f5;
}
.medicine_descrip {
  width: 100%;
  float: left;
  padding: 15px;
}
.dr_details_data {
  width: 85%;
  float: left;
}
.patient_details_div {
  width: 92.5%;
  padding: 30px 50px;
}
.patient_name_address {
  width: 60%;
  float: left;
}
.patient_details {
  width: 40%;
  float: left;
}
.medicine_table_data {
  width: 100%;
  /* border-right: 2px solid #333; */
  padding: 0px 15px;
  float: left;
  height: 615px;
}

.dr_deatils {
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 5px;
  display: flex;
}
.dr_name {
  font-size: 18px;
  margin: 0px;
  font-weight: 600;
}
.download_pdf_btn {
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.footer_right p {
  align-items: baseline;
  display: flex;
  margin-left: 40px;
}
.tables .thead tr th {
  font-size: 14px;
  font-weight: 300;
  color: #404760;
  /* font-weight: 600; */
  font-family: Rubik,  sans-serif;
}
.table_bold {
  font-weight: 600;
}
.row.table_data {
  font-weight: 300 !important;
  color: #404760 !important;
  font-family: Rubik,  sans-serif;
}
.tBody .tables th {
  font-size: 14px;
  /* font-weight: 300;
  color: #404760; */
  font-family: Rubik,  sans-serif;
}
.medicine_descrip img {
  height: 35px;
}
.footer_right {
  width: 30%;
}
.doc_sign {
  float: right !important;
  bottom: 10px;
  position: absolute;
  right: 0;
}
.footer_left {
  width: 70%;
  color: #707070;
  font-size: 12px;
  display: flex;
}
.horizontal_line {
  display: none;
}
.vertical_line {
  display: flex;
  height: 80%;
  width: 2px;
  background-color: #000000;
  position: absolute;
  right: 60%;
}
/* .doc_sign img{
  height: 45px;
} */
.myFooter {
  justify-content: space-between;
  width: 100%;
  margin: 0;
  align-items: baseline;
  padding: 10px 20px;
}
.no_history {
  margin-right: 30px;
}
.watermark {
  /* position: absolute; */
  /* z-index: 0; */
  /* top: 52%; */
  /* left: 4%; */
  font-size: 13rem;
  transform: rotate(-45deg);
  color: #dae0e2;
}
.watermark-mob {
  display: none;
}
.view-prescriprion-section {
  margin: 1.7rem !important;
}

.scrollbar_thikness_main
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight::-webkit-scrollbar-track,
.scrollbar_thikness::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.scrollbar_thikness_main
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight::-webkit-scrollbar,
.scrollbar_thikness::-webkit-scrollbar {
  width: 12px;
  background-color: #f5f5f5;
}

.scrollbar_thikness_main
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorRight::-webkit-scrollbar-thumb,
.scrollbar_thikness::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #555;
}

@media screen and (max-width: 768px) {
  .view-prescriprion-section {
    margin: 1.7rem !important;
  }
  .watermark-mob {
    position: absolute;
    display: block;
    font-size: 5rem;
    transform: rotate(-62deg);
    color: #dae0e2;
    margin-left: -60px;
    top: 210px !important;
  }
  .watermark {
    display: none;
  }
  .medicine_table_data {
    border-right: none !important;
    padding: 0;
    margin: 0;
  }
  .dignsis_data {
    margin-top: 30px;
  }
  .patient_name_address {
    width: 100% !important;
  }
  .myFooter .footer_left {
    flex-direction: column;
  }
  .rows {
    flex-direction: column;
  }
  .tBody {
    padding: 0;
    display: flex;
    flex-direction: column;
    position: unset;
    /* height: 750px;
    max-height: 750px;
    min-height: 100%; */
  }
  .patient_details {
    width: 100% !important;
  }
  .responsive-padding {
    position: relative;
    margin: 0px;
    padding: 0;
    /* margin-left: 15px !important;
    margin-right: 15px !important; */
  }
  .medicine_descrip span,
  .medicine_descrip p {
    width: 100%;
    float: left;
  }
  .dr_deatils {
    display: flex;
    font-size: 12px;
    font-family: Rubik,  sans-serif;
  }
  .tables.drugs_table {
    width: 100%;
    display: none;
  }
  .medicine_descrip img {
    height: 50px;
    float: right;
  }
  .medi-describe {
    height: auto;
  }
  .medicine_descrip {
    display: flex;
    flex-direction: column;
    margin-top: 20px !important;
  }
  .mob_watermark_section {
    position: relative;
  }
  /* .dignsis_data{
    margin-left: 25px;
  } */
  .medicine_table_data {
    height: auto !important;
  }
  .medicine_descrip ul {
    margin-right: -1px;
  }
  .patient_name_address {
    padding-bottom: 20px;
  }
  .myFooter {
    flex-direction: column;
    /* margin-bottom: 5rem; */
    margin-left: 0px !important;
  }
  .footer_left,
  .footer_right {
    width: 100%;
    /* padding: 0px 24px; */
  }
  .left_patient_data,
  .right_patient_data {
    width: 100%;
  }
  .mob_patient_left,
  .mob_patient_right {
    font-size: 13px;
    font-family: Rubik,  sans-serif;
  }
  .patient_data {
    flex-direction: column;
  }
  .container {
    padding: 0 !important;
    margin: 0;
    /* background-color: #E0E0E0; */
  }
  .left_patient b,
  .right_patient b {
    margin-right: 30px;
  }
  .left_patient .address {
    margin-right: 20px;
  }
  .left_patient .name {
    margin-right: -125px !important;
  }
  .right_patient {
    width: 100%;
  }
  .mob_patient_data {
    display: none;
  }
  .mob_patient_data div {
    width: 50%;
  }
  .patient_data {
    display: none;
  }

  .mob_tables {
    display: flex;
    flex-direction: column;
  }
  .grid-row-sangharsha {
    margin-top: 2rem;
  }

  .mob_table tr {
    display: flex;
    font-size: 14px;
    justify-content: space-between;
  }
  .mob_table {
    display: flex;
    margin-bottom: 20px;
    flex-direction: column;
    border: 1px solid #e8e8e8;
  }
  .tables .table.table-striped.rx-table-outer {
    display: none;
  }
  .tables.examination_table {
    display: flex;
    display: none;
  }
  .signature {
    text-align: end;
    padding-right: 0px;
  }
  .medicine_descrip .float-right {
    margin: 35px 0px 20px;
  }
  .for_help_web {
    display: none;
  }
  .for_help_mob {
    display: contents;
  }
  .go_back {
    display: flex;
    position: absolute;
    left: 35px;
    top: 75px;
  }
  .grid-container-sangharsha b {
    font-size: 14px;
    /* padding: 0px 15px; */
  }
  .col-6.col-md-4,
  .col-6.col-md-8,
  .col-4,
  .col-2,
  .medicine_table_data,
  .medicine_descrip {
    font-size: 14px;
    margin: 0 !important;
    padding: 0 !important;
  }
  .col-4 {
    text-align: center;
  }
  .responsive-padding {
    width: 100%;
    margin: 0 auto;
  }
  .headerStyle {
    padding: 10px 12px;
  }
  .dr_details_data {
    width: 100%;
  }
  .footer_right p {
    margin: 0;
  }
  .mob_prescription {
    display: flex;
    padding: 10px 20px;
    font-size: 16px;
    font-family: Rubik, sans-serif;
  }
  .follow_up_date {
    margin: 0px;
  }
  .emergency_number_mob {
    display: flex;
  }
  .emergency_number {
    display: none;
  }
  .mob_table td {
    width: 50%;
    font-family: Rubik,  sans-serif;
    color: #1b2027;
    font-size: 14px;
    text-align: left;
  }
  .display_data {
    color: #404760;
  }
  .mob_tables .table-striped > tbody > tr:nth-child(2n + 1) > td,
  .mob_tables .table-striped > tbody > tr:nth-child(2n + 1) > th {
    background-color: #f5f5f5;
  }
  .mob_tables .table-striped > tbody > tr:nth-child(2n) > td,
  .mob_tables .table-striped > tbody > tr:nth-child(2n) > th,
  .mob_tables .table-striped > tbody > tr:nth-child(2n) > td,
  .mob_tables .table-striped > tbody > tr:nth-child(2n) > th {
    background-color: #ffffff;
  }
  .table {
    border: none;
  }
  .user_info {
    font-size: 13.25px;
    padding: 0 !important;
  }
  .vertical_line {
    display: none;
  }
  .horizontal_line {
    display: flex;
    width: 88vw;
    height: 2px;
    background-color: #000000;
    position: absolute;
    margin: 4px -18px !important;
  }
  .doc_sign {
    position: unset;
    margin: 15px 0px;
  }
  .doc_sign img {
    float: right;
  }
  .table th,
  .table td {
    word-break: break-all;
  }
  .watermark {
    top: 47%;
    left: 4%;
    font-size: 5rem;
  }
}
