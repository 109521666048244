.document-container {
  position: absolute;
  width: 38%;
  height: 100%;
  opacity: 1;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  z-index: 999;
  display: flex;
  flex-direction: column;
  bottom: 0;
}

.document-container-full {
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  width: 100vw !important;
  height: 100%;
}

.document-header {
  font-size: 16px;
  letter-spacing: 0px;
  color: #ffffff;
  background: #000000 0% 0% no-repeat padding-box;
  height: 60px;
  align-items: center;
  padding: 0px 0px 0px 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-wrap: wrap;
}

.drawer_component .MuiDrawer-paper {
  position: fixed;
}

.pdf_popup {
  width: 100vw;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}

.document-content {
  border-radius: 8px;
  border: 2px solid #f5f5f5;
  height: 100%;
  overflow: hidden;
  padding: 5px;
  overflow-y: scroll;
}

.document-header-left {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* margin-left: 40px; */
}

.document-header-right {
  margin-right: 40px;
  column-gap: 20px;
  justify-content: space-between;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
}

.full-view {
  /* margin-left: auto;
    cursor: pointer; */
  display: none;
}

.close-icon-notification {
  cursor: pointer;
  font-size: 19px;
}

.print-icon {
  width: 20px;
}

#react-doc-viewer #header-bar {
  display: none;
}

#react-doc-viewer #image-renderer {
  background-image: none !important;
}

@media screen and (max-width: 498px) {
  .full-view {
    display: none;
  }

  .document-header-right {
    margin-right: 20px;
    column-gap: 20px;
    justify-content: space-between;
  }
  .document-header-left {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.document-content .dcSQVE {
  overflow: hidden;
}

#pdf-download {
  display: none;
}
