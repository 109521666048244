.c2c-custom-popup-container {
  .c2c-custom-popup-header {
    display: flex;
    padding: 20px 20px 20px 30px;
    border-bottom: 1px solid #d6dae5;
    align-items: center;
  }

  .c2c-custom-popup-header-title {
    font: normal normal 500 18px/22px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #171717;
    flex: 1;
  }

  .c2c-custom-popup-header-close {
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
  }

  .c2c-custom-popup-header-close img {
    width: 24px;
    height: 24px;
  }

  .c2c-custom-popup-cont {
    padding: 20px 30px 30px 30px;
  }

  .c2c-custom-popup-footer {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .c2c-custom-popup-footer-left-btn {
    margin-right: 20px;
  }
}

@media (max-width: 768px) {
  .c2c-custom-popup-container {
    .c2c-custom-popup-header {
      padding: 16px 20px;
    }

    .c2c-custom-popup-cont {
      padding: 15px 20px 30px 20px;
    }
  }
}

.edit-vaccine-modal {
  .MuiPaper-root {
    overflow: visible;
  }
}
