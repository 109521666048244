.comment-search-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
  padding-left: 10px;
  border: solid 1px;
  border-radius: 6px;
  border-color: #d6dae5;
}

.comment-search-wrapper {
  flex: 2;
  flex-direction: column;
  // max-width: 500px;
}

.comment-entered-field-container {
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px;
  border: solid 1px;
  border-radius: 6px;
  border-color: #d6dae5;

  p {
    margin: 0px;
    padding: 10px;
    align-items: center;
  }
}

.comment-entered-field-top-container {
  align-items: center;
  display: flex;
}

.input-holder {
  display: flex;
  flex: 1;
  align-items: center;
  background: white;
  position: relative;
  transition: none; //all 0.3s ease-in-out;
  // padding: 0px 10px;
  z-index: 9;
}

.content-text-search-input {
  width: 100%;
  background: white;
  box-sizing: border-box;
  border: none;
  outline: none;
  font: normal 12px Rubik, sans-serif;
  font-weight: 400;
  line-height: 20px;
  transition-delay: 0.3s;
  color: #000;
  border-radius: 5px;
  padding: 10px;
}

.search-icon {
  position: absolute;
  cursor: pointer;
  padding: 0px 10px;
}

.close_search {
  display: block;
  width: 24px; //added new
  font-size: 18px;
  opacity: 0.5;
  margin-right: 10px;
}
.close_search img {
  cursor: pointer;
}
