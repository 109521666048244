@use "../../../../Util/c2cConstants/colorConstants" as color;
@use "../../../../Util/c2cConstants/fontFileConstants.scss" as font;

.c2c-learnmore-popup {
  background-color: color.$C2C_WHITE;
  .main-popup-view-main {
    width: 498px;
    height: 100px;
    /* UI Properties */
    background: color.$C2C_LIGHT_PALE_BLUE 0% 0% no-repeat padding-box;
    border-radius: 5px;
    justify-content: space-between;
    display: flex;
    margin-left: 10px;
    margin-top: 15px;
  }

  .learn-more-popup-containt-details {
    display: flex;
    justify-content: space-between;
  }

  .learn-more-popup-containt {
    font-weight: 500;
    font-size: font.$BUTTON_LARGE;
    margin-left: 5px;
  }

  .learn-more-popup-subcontaint {
    font-weight: 500;
    font-size: font.$BUTTON_MEDIUM;
    margin-top: 20px;
    margin-left: 10px;
    text-align: start;
  }

  .learn-more-popup-subcontaint-text {
    font-size: font.$BUTTON_REGULAR;
    margin-left: 10px;
    text-align: start;
  }

  .learn-more-popup-img {
    width: 100px;
    height: 48px;
  }

  .main-img-padding {
    padding-top: 20px;
    padding-left: 20px;
  }

  .main-containt-padding {
    padding-top: 20px;
    padding-right: 20px;
    margin-left: 20px;
  }

  .main-containt-padding-sidedisplay {
    font-weight: 500;
    font-size: font.$BUTTON_REGULAR;
    text-align: left;
  }

  .main-containt-padding-sidedisplay-text {
    font-size: font.$PARAGRAPH_SMALL;
    text-align: left;
  }

  .learn-more-popup-crossicon {
    width: 24px;
    height: 24px;
  }
}

@media screen and (max-width: 768px) {
  .c2c-learnmore-popup .main-popup-view-main {
    background: rgba(23, 186, 224, 0.0509803922) 0% 0% no-repeat padding-box;
    border-radius: 5px;
    justify-content: space-between;
    display: flex;
    margin-left: -10px;
    margin-top: 15px;
    width: auto;
    height: auto;
    padding: 5px;
  }

  .c2c-learnmore-popup .main-img-padding {
    // /* padding-top: 20px; */
    padding-left: 0px;
    padding-top: 0px;
  }

  .c2c-learnmore-popup .main-containt-padding {
    padding-top: 0px;
  }

  .c2c-learnmore-popup .learn-more-popup-subcontaint {
    margin-top: 0px;
  }

  .c2c-learnmore-popup .learn-more-popup-img {
    height: 112px;
    width: 151px;
  }

  .c2cmiddle-popup-container .c2cmiddle-popup-header-close {
    display: none;
  }

  .c2c-learnmore-popup .learn-more-popup-containt {
    font-size: 17px;
  }

  .c2c-learnmore-popup .main-containt-padding-sidedisplay-text {
    margin-top: 12px;
  }

  .c2c-learnmore-popup .main-containt-padding-sidedisplay {
    font-weight: 500;
    font-size: 14px;
  }
}
