@use "../../../Util/c2cConstants/colorConstants" as c;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.home_page_top_container_view {
  .home_page_top_container_display {
    display: flex;
    border-bottom: 0.5px solid c.$C2C_GRAY_4;
    padding: 60px 165px 0px 165px;
  }

  .home_page_top_container_display_view {
    display: flex;
    align-items: center;
  }

  #movingImg {
    margin-left: -50px;
    margin-bottom: 30px;
    animation: moveUpDown 1s ease-in-out infinite alternate;
  }

  @keyframes moveUpDown {
    0% {
      margin-top: 0;
    }

    100% {
      margin-top: -10px;
    }
  }

  body.sidebar-open #movingImg {
    display: none;
  }

  .home_page_top_container_rating_view {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    padding-left: 50px;
  }

  @media screen and (min-width: 768px) {
    .home_page_top_AI_text_view {
      top: 216px;
      left: 172px;
      width: 250px;
      height: 73px;
      background: c.$C2C_WHITE;
      border-radius: 2px;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .home_page_top_main_view {
      display: flex;
      justify-content: space-between;
      padding: 20px 165px;
      margin-top: 20px;
    }

    .home_page_top_destopView {
      width: 568px;
      height: 474px;
      opacity: 1;
    }

    .home_page_top_AI_font_color {
      text-align: left;
      font: normal normal 500 50px/61px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: c.$C2C_WINE_BLUE;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      padding: 0px 10px;
    }

    .home_page_top_container_doctor_patient_view {
      width: 50px;
    }

    .home_page_top_text_view {
      font-size: font.$HEADING_7;
      letter-spacing: 0px;
      color: c.$C2C_WHITE;
      opacity: 1;
      font-weight: 500;
    }

    .home_page_top_view_doctor_loginView {
      width: 145px;
      height: 40px;
      /* UI Properties */
      border-radius: 2px;
      opacity: 1;
      cursor: pointer;
    }

    .home_page_top_display_view {
      display: flex;
      align-items: center;
      margin-top: -10px;
    }

    .home_page_top_container_login_view {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;
    }

    .home_top_view_display_Request_link {
      text-decoration: none;
      color: c.$C2C_DARK_GRAY;
      font-weight: bold;
      margin-left: 10px;
      margin-top: 10px;
      cursor: pointer;
    }
  }

  .home_page_login_view {
    height: 17px;
    color: c.$C2C_WHITE;
    font: normal normal normal 14px/20px font.$C2C_FONT_FAMILY;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 10px;
    margin-left: 3px;
    padding-left: 5px;
  }

  .home_page_login_button:hover {
    transform: translateY(-5px);
  }

  .home_page_login_button {
    background-color: c.$C2C_DARK_GRAY;
    width: 159px;
    height: 40px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    transition: transform 200ms ease-in-out;
    border-radius: 3px;
  }

  .doctor-info .doctor-info-container {
    position: relative;
  }

  .doctor-info .doctor-info-title,
  .doctor-info .doctor-info-hover-content {
    font-size: font.$HEADER_9;
    /* Set the desired font size */
    font-weight: bold;
    cursor: pointer;
    color: c.$C2C_WINE_BLUE;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .doctor-info .doctor-info-hover-content {
    position: absolute;
    top: 0;
    left: 0;
    color: c.$C2C_PALE_YELLOW;
    /* Change to your desired color */
    transform: translateX(100%);
    /* Initially position it outside the visible area */
    white-space: nowrap;
    opacity: 0;
    transition: transform 0.3s ease, opacity 0.3s ease;
  }

  .doctor-info .doctor-info-container:hover .doctor-info-title {
    transform: translateX(-40%);
    /* Slide the first text to the left */
    opacity: 0;
    /* Hide the first text */
  }

  .doctor-info .doctor-info-container:hover .doctor-info-hover-content {
    transform: translateX(0);
    opacity: 1;
  }

  .containerStyle {
    width: 178px;
    height: 30px;
    /* Half of the intended height */
    overflow: hidden;
    position: relative;
  }

  .hoverEffect {
    transition: transform 0.3s ease;
    position: relative;
    padding-top: 10px;
    width: 178px;
    height: 190px;
  }

  .containerStyle:hover .hoverEffect {
    transform: translateY(-5%);
  }

  .containt_color {
    color: c.$C2C_DARK_GRAY;
  }

  /* Apply styles to the text */
  .textContainer_color {
    color: c.$C2C_DARK_GRAY;
    margin-top: 15px;
  }

  .button_color {
    color: c.$C2C_WHITE;
  }

  .home_top_view_display_link {
    text-decoration: none;
    color: c.$C2C_WHITE;
  }

  .home_page_img_height {
    width: 140px;
    margin-left: 10px;
  }

  .home_top_view_display_Request_link_img {
    margin-left: 10px;
  }

  .home_page_top_animation_view {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 14px;
    right: 25px;
  }

  .home_page_header_view_pratice_alignment {
    margin-left: 10px;
  }
}

@media screen and (max-width: 768px) {
  .home_page_top_main_view {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .home_page_img_height {
    margin-bottom: 40px;
  }

  .side_bar_main_view #movingImg {
    display: none;
  }

  .home_page_top_text_view {
    font-size: font.$HEADING_1;
    font-weight: 600;
    letter-spacing: 0px;
    color: c.$C2C_WHITE;
    opacity: 1;
    font-family: font.$C2C_FONT_FAMILY;
  }

  .home_page_top_AI_font_color {
    font-size: font.$HEADING_1;
    text-align: left;
    letter-spacing: 0px;
    color: c.$C2C_WINE_BLUE;
    opacity: 1;
    font-family: font.$C2C_FONT_FAMILY;
    font-weight: 500;
  }

  .home_page_top_AI_text_view {
    top: 216px;
    left: 172px;
    width: 173px;
    height: 56px;
    background: c.$C2C_WHITE;
    border-radius: 2px;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .home_page_top_destopView {
    width: 100%;
    margin-top: 20px;
  }

  .home_page_top_container_doctor_patient_view {
    width: 39px;
    height: 39px;
  }

  .home_page_top_view_doctor_loginView {
    top: 552px;
    left: 172px;
    width: 145px;
    height: 40px;
    background: var(--c2c_dark_mode_blue) 0% 0% no-repeat padding-box;
    background: c.$C2C_DARK_GRAY 0% 0% no-repeat padding-box;
    border-radius: 2px;
    opacity: 1;
    cursor: pointer;
  }

  .home_page_top_display_view {
    display: flex;
    align-items: center;
  }

  .home_page_top_container_login_view {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }

  .home_page_top_containt_rating_mobile_view {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px;
  }

  .home_page_top_containt_rating_mobile_text {
    margin-left: 50px;
    color: c.$C2C_DARK_GRAY;
    width: 50%;
  }

  .home_page_top_containt_rating_mobile_rating {
    margin-left: 0px;
    color: c.$C2C_DARK_GRAY;
  }

  .textContainer {
    white-space: pre-line;
  }

  .home_top_view_display_Request_link {
    text-decoration: none;
    color: c.$C2C_DARK_GRAY;
    font-weight: bold;
    margin-left: 0px;
    margin-top: 20px;
    cursor: pointer;
  }

  .home_page_Top_container_rating_display_view {
    padding: 50px 30px 30px 30px;
    border-bottom: 1px solid c.$C2C_GRAY_4;
  }

  .home_page_top_container_view .hoverEffect {
    transition: transform 0.3s ease;
    position: relative;
    padding-top: 10px;
    width: 178px;
    height: 170px;
  }

  .home_page_Top_container_rating_display_image_view {
    width: 168px;
    margin-top: 10px;
    padding: 10px 10px 10px 0px;
  }

  .home_page_top_container_view .home_page_login_view {
    margin-top: 0px;
  }

  .home_top_view_display_Request_link_img {
    margin-left: 10px;
  }

  .home_page_top_animation_view {
    top: 8px;
    right: 10px;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1024px) {
  .home_page_top_container_view .home_page_top_main_view {
    display: flex;
    justify-content: space-between;
    padding: 60px 80px 25px 80px;
    margin-top: 20px;
  }

  .home-page-view-container .home_page_colour_containt {
    height: 745px;
    background: linear-gradient(to left,
        #1f4695 0%,
        #1f4695 35%,
        #19c8f0 35%,
        #19c8f0 100%);
  }

  .home_page_Top_container_rating_display_view {
    padding: 50px 30px 30px 60px;
  }

  .home_page_img_height {
    margin-bottom: 65px;
  }

  .home_page_top_container_view .textContainer_color {
    color: #101010;
    margin-top: 15px;
    font: normal normal 500 20px/23px Rubik, sans-serif;
  }

  .home_page_top_container_view .home_page_login_view {
    margin-top: 15px;
  }

  .home_page_top_container_view .home_page_login_button {
    height: 40px;
  }

  .home_page_top_container_view .home_page_top_container_login_view {
    justify-content: flex-start;
  }

  .home_page_top_container_doctor_patient_view {
    height: 50px;
  }

  .home_page_top_container_view .home_page_top_display_view {
    margin-top: -11px;
  }

  .home_page_top_container_view .home_top_view_display_Request_link {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 820px) and (max-width: 1179px) {
  .home_page_top_container_view .home_page_top_main_view {
    padding: 60px 20px 25px 30px;
  }

  .home_page_header_content_main .home_page_header_containt_doctor_search {
    width: 40%;
    margin-left: 60px;
  }

  .home_page_header_content_main .doctor-search-input-wrapper {
    width: 288px;
    height: 40px;
  }

  .home_page_top_container_view .home_page_top_destopView {
    width: 329px;
    height: 250px;
  }

  .home_page_top_container_view .home_page_top_container_display {
    padding: 30px 30px 0px 30px;
  }

  .home_page_top_container_view .home_page_top_container_rating_view {
    padding-left: 18px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 819px) {
  .home_page_top_container_view .home_page_top_main_view {
    padding: 0px 30px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 330px) {
  .home_page_top_container_view .home_page_top_animation_view {
    top: 3px;
    right: 5px;
    width: 14px;
    height: 14px;
  }
}

@media only screen and (min-width: 331px) and (max-width: 380px) {
  .home_page_top_container_view .home_page_top_animation_view {
    top: 14px;
    right: 20px;
  }
}