@use "../../Util/c2cConstants/colorConstants.scss" as color;
@use "../../Util/c2cConstants/fontFileConstants.scss" as font;

.stay-signin-forgot-password {
    height: 15%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 10px 0px 0px 0px;

    .stay-signin-checkbox-text {
        font: normal normal normal 14px/17px font.$C2C_FONT_FAMILY;
        color: #000000;
        display: flex;
        align-items: center;
      }
    
      .stay-sign-in-checkbox {
        background: #d6dae5 0% 0% no-repeat padding-box;
        width: 22px;
        height: 22px;
        border-radius: 5px;
        margin-right: 5px;
        justify-content: center;
        display: flex;
        align-items: center;
        transition: ease-in-out 0.1s;
      }
      .stay-sign-in-checkbox-selected {
        background: #17bae0;
      }
      .forgot-password {
        font: normal normal 500 14px/17px font.$C2C_FONT_FAMILY;
        color: #43ceff;
        cursor: pointer;
      }
  }
 