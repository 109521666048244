.thecolor {
  color: #2f2f2f;
}

.thecolor:hover {
  color: #2f2f2f;
}

.menu-button-font {
  font: normal normal normal 16px/19px Rubik, sans-serif;
}

.menu-custom-button {
  color: #ffffff;
}

.menu-custom-button-Header {
  color: #565656;
}

.wrapper-login {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
  column-gap: 8px;
}

.right-menu {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: max-content;
  min-width: fit-content;
}

.arrowdown-mob {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

.menu-custom-button {
  .right-menu {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
}
