@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.sign-in-form-layout {
  .sifl-c2c-logo-container {
    margin-bottom: 30px;
  }

  .sifl-c2c-logo {
    width: 220px;
    height: 63px;
    cursor: pointer;
  }

  .sifl-heading {
    font: normal normal normal 25px/29px font.$C2C_FONT_FAMILY;
    color: #919191;
    margin-bottom: 8px;
  }

  .sifl-subheading {
    font: normal normal 600 39px/46px font.$C2C_FONT_FAMILY;
    color: #1a1a1a;
    margin-bottom: 30px;
  }

}

@media (max-width: 768px) {

  .sign-in-form-layout {

    .sifl-c2c-logo {
      width: 160px;
    }

    .sifl-c2c-logo-container {
      margin-bottom: 20px;
    }

    .sifl-heading {
      font: normal normal normal 14px/17px font.$C2C_FONT_FAMILY;
    }

    .sifl-subheading {
      font: normal normal 500 22px/35px font.$C2C_FONT_FAMILY;
      margin-bottom: 20px;
    }
  }
}