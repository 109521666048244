@use "../../../Util/c2cConstants/colorConstants" as color;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.abha-existing-card-view {
  height: 100vh;
  background: transparent linear-gradient(180deg, #17bae000 0%, color.$C2C_SAGA_GREEN 100%) 0% 0% no-repeat padding-box;
  display: flex;
  align-items: center;
  justify-content: center;

  .axcv-card-container {
    width: 856px;
    background: color.$C2C_WHITE;
    box-shadow: 3px 3px 30px #00000010;
    border-radius: 10px;
    padding: 20px 30px 30px 30px;
  }

  .axcv-heading {
    text-align: center;
    font: normal normal 500 30px/36px font.$C2C_FONT_FAMILY;
    color: #171717;
    display: grid;
    margin: 0px 186px;
  }

  .axcv-abha-card {
    width: 326px;
    border: 2px solid color.$C2C_BLUE;
    border-radius: 20px;
    padding: 19px 24px;
    position: relative;
    margin-top: 30px;
  }

  .axcv-abha-card-container {
    display: flex;
    justify-content: center;
    font: normal normal normal 9px/27px font.$C2C_FONT_FAMILY;
    letter-spacing: 0px;
    color: #101010;
  }

  .axcv-abha-card-content {
    display: flex;
    margin-top: 10px;
  }

  .axcv-abha-card-left-content {
    width: 70%;
    overflow-wrap: anywhere;
  }

  .axcv-abha-card-right-content {
    width: 30%;

    img {
      width: 98px;
      height: 98px;
    }
  }

  .axcv-abha-blue-tick {
    position: absolute;
    top: -15px;
    left: -15px;
  }

  .axcv-card-tick-gif {
    display: flex;
    justify-content: center;

    img {
      width: 100px;
      height: 100px;
    }
  }

  .axcv-button-container {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  .axcv-button-container-img {
    display: flex;
    justify-content: space-between;
    height: 17px;
    margin-top: 10px;
  }

  .axcv-button-container-img2 {
    margin-top: -7px;
    height: 30px;
  }
}