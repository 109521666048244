@use "../../../../Util/c2cConstants/colorConstants" as c;

.primay-button-container {
  text-align: center;
  font: normal normal 500 16px/19px Rubik, sans-serif;
  color: #ffffff;
  min-width: 150px;
  padding: 15px;
  background: c.$PRIMARY_COLOR;
  border-radius: 25px;
  cursor: pointer;
}

.primay-button-container-disabled {
  background-color: #a3a9b7;
  cursor: not-allowed;
  color: #fff;
}

@media screen and (max-width: 768px) {
  .primay-button-container {
    min-width: 100px;
    padding: 10px;
  }
}
