$success: white;

%common-style {
  display: flex;
  flex-direction: row;
}
.header-categories {
  width: 220px;
  height: 29px;
  text-align: center;
  font: normal normal 600 18px/34px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #2f2f2f;
  opacity: 1;
  padding: 0px;
}

.categories {
  font: normal normal 600 20px/34px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #2f2f2f;
  opacity: 1;
  padding: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 40px;
  width: 100%;

  @media screen and (min-width: 992px) {
    text-align: center;
    font: normal normal bold 25px/29px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #2f2f2f;
    opacity: 1;
    padding: 30px;
    margin-top: 50px;
  }
}

.header-align {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
  width: 100%;

  @media screen and (min-width: 992px) {
    max-width: 50vw;
    width: 50%;
    margin: auto;
  }
}

.categories-layout {
  width: "100%";
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.query-category {
  width: 920px;
  height: 387px;
  opacity: 1;
}

.category-button {
  border: 1px solid pink;
  background-color: pink;
}

.scroll-to-y {
  @extend %common-style;
  width: 100%;
  background-color: pink;
  justify-content: center;
}

.align-scroll-section {
  @extend %common-style;
  background-color: blue;
  width: 920px;
}

.arrow {
  background-color: red;
  width: 10px;
}

.scroll-content {
  width: 900px;
  background-color: green;
}

@import "~bootstrap/scss/bootstrap";
