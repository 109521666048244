@use "../../Util/c2cConstants/colorConstants" as c;

.home-page-view-container {
  height: 100vh;
  .home_page_colour_containt {
    height: 700px;
    background: linear-gradient(
      to right,
      c.$C2C_LIGHT_BLUE 0%,
      c.$C2C_LIGHT_BLUE 65%,
      c.$C2C_WINE_BLUE 50%,
      c.$C2C_WINE_BLUE 100%
    );
  }

  @media screen and (max-width: 768px) {
    .home_page_colour_containt {
      height: 800px;
      background: linear-gradient(
        to top,
        c.$C2C_WINE_BLUE 0%,
        c.$C2C_WINE_BLUE 35%,
        c.$C2C_LIGHT_BLUE 35%,
        c.$C2C_LIGHT_BLUE 100%
      );
    }
  }
}

.a {
  color: #565656;
  &:hover {
    color: #565656;
    text-decoration:none;
  }
}