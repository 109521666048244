#drop-down-search-selector {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    // border: 0.5px solid #56CDE8;
    border: 0.5px solid #D6DAE5;
    border-radius: 5px;
    position: relative;

    input {
        background: #FFFFFF;
        width: 100%;
        // color: #A3A9B7;
        color:#495057;
        font-size: 14px;
        border-radius: 5px;
        font-weight: normal;
    }

    .menu-item-active {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .menu-list-container {
        padding-bottom: 20px;
        position: absolute;
        left: 0;
        right: 0;
        z-index: 10003;
        background: #ffffff;
        border: 0.5px solid #ced4da; //#56CDE8;
        border-radius: 5px;
        border-top: none;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        height: auto;
        margin: -1px;
        // max-height: 145px;
        max-height: 186px;
        overflow: hidden;
        overflow-y: auto
    }

    .menu-list-option {
        min-height: 40px;
        text-align: left;
        font-size: 16px;
        color: #1B2027;
        display: flex;
        align-items: center;
        padding: 0 20px;
        line-height: 1;

        .delete-icon {
            display: none;
            color: #17BAE0;
        }
    }
    .ht{
        height: 40px;
    }

    .cursor-pointer {
        cursor: pointer;
    }

    .menu-list-option:hover {
        background-color: rgba(86, 205, 232, 0.5);

        .delete-icon {
            // display: unset;
            display: flex;
            align-items: center;
        }
    }

    .spinner_css{
        width: 20px;
        height: 20px;
        color: rgb(23, 186, 224) !important;
        border: 3px solid currentColor;
        display: inline-block;
        vertical-align: text-bottom;
        border-right-color: transparent;
        border-radius: 50%;
        animation: .75s linear infinite spinner-border;
    }
}

.input_width{
    input{
        width: 94% !important;
    }
}


.input_div{
    display: flex;
    color: "#6f747a";
    input{
        border-radius: 5px;
        border: 0.5px solid #D6DAE5;
        background-color: white;
    }

    .frequency_spinner{
        position: absolute;
        top: 14px;   //7px;
        right:28px;
        width: 20px !important;
        height: 20px !important;
        color: rgb(23, 186, 224) !important;
        border: 3px solid currentColor;
        display: inline-block;
        vertical-align: text-bottom;
        border-right-color: transparent;
        border-radius: 50%;
        animation: .75s linear infinite spinner-border;
    }
}
 
.patient-content{
    margin: 20px 0px;
} 
