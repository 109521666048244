.onboarded-modal {
  & .modal-content {
    width: 185%;
    margin-left: 16%;
    padding: 0px !important;
    border-radius: 3px;

    @media screen and (max-width: 576px) {
      width: 100%;
      margin-left: -8% !important;
    }
  }
}

.registered-modal {
  & .modal-content {
    width: 150%;
    margin-left: 30%;
    padding: 0px !important;
    border-radius: 3px;

    @media screen and (max-width: 576px) {
      font-size: smaller;
      width: 119%;
      margin-left: -17% !important;
    }
    @media screen and (min-width: 576px) and (max-width: 768px) {
      margin-left: -21% !important;
    }
  }
}
.registered-modal1 {
  & .modal-content {
    width: 64%;
    margin-left: 10%;
    padding: 0px !important;
    border-radius: 3px;

    @media screen and (max-width: 576px) {
      font-size: smaller;
      width: 100%;
      margin-left: 0% !important;
    }
    @media screen and (min-width: 576px) and (max-width: 768px) {
      margin-left: -21% !important;
    }
  }
}
