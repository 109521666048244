@use "../../../Util/c2cConstants/colorConstants" as color;
@use "../../../Util/c2cConstants/fontFileConstants" as font;

.vital-date-picker-input {
  .vdpi-container {
    position: relative;
  }

  .vdpi-input-container {
    display: flex;
    border: 1px solid color.$C2C_GRAY_4;
    border-radius: 3px;
    background: color.$C2C_WHITE;
    height: 40px;
    align-items: center;
    justify-content: space-between;
    background-color: #f4f4f4;
    font-size: 13px;
    line-height: 15px;
    font-family: font.$C2C_FONT_FAMILY;
    color: #2f2f2f;
  }

  .vdpi-input {
    width: 80%;
    padding: 0px 10px;

    input {
      width: 100%;
      outline: none;
      border: none;
      background-color: transparent;
    }
  }

  .vdpi-calendar-icon {
    cursor: pointer;
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: color.$C2C_WHITE;
    height: 100%;
  }

  .vdpi-calendar {
    position: absolute;
    z-index: 9;
    margin-top: 5px;
  }
}
