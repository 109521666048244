.vital-component {
  width: 100%;
  display: flex;
  align-items: center;
  border: 0.5px solid #d6dae5;
  border-radius: 5px;
  height: 100%;

  .vital-component-input {
    padding: 10px;
    font: normal normal normal 13px/15px "Rubik", sans-serif;
    color: #2f2f2f;
    border-right: 0.5px solid #d6dae5;
    width: 80%;
  }

  .vital-component-input input {
    border: none;
    outline: none;
    width: 100%;
    line-height: 22px;
  }

  .vital-component-input input::placeholder {
    color: #a3a9b7;
  }

  .vital-component-unit {
    display: flex;
    align-items: center;
    background: #f5f6f7 0% 0% no-repeat padding-box;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 8px;
    width: 25%;
    border-radius: 0 5px 5px 0;
    height: 100%;
  }

  .vital-component-selected-unit {
    font: normal normal normal 13px/15px "Rubik", sans-serif;
    color: #2f2f2f;
  }

  .vital-component-arrow {
    display: grid;
  }

  .vital-component-arrow i {
    color: #17bae0;
    font-size: 12px;
    cursor: pointer;
  }
}
