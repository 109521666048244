.Popover-body {
    padding: 5px;
    font-size: 14px;
    border-radius: 2px !important;
    box-shadow: 0px 2px 6px 0 rgba(0, 0, 0, 0.35) !important;
  }

.booking-calender-wrapper {
  margin: 0px 0px 10px 0px;
  padding: 0;
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
}