.modal-container {
  position: absolute;
  bottom: 0px;
  z-index: 99;
}

.modal-content {
  display: flex;
  margin: auto;
  border-radius: 10px 10px 10px 10px !important;
  background-color: #ffff;
  padding: 0 0 19px 0 !important;
  /* width: 72%; */
  border: none;
  /* margin-top: 6rem; */
}

.button1 {
  color: #FFFFFF !important;
  background: #17BAE0 0% 0% no-repeat padding-box !important;
  border-radius: 25px !important;
  border: none;
}

.button2 {
  color: #17BAE0 !important;
  background: #FFFFFF 0% 0% no-repeat padding-box !important;
  border: 1px solid #D6DAE5 !important;
  border-radius: 25px !important;
}


@media (min-width: 320px) and (max-width: 768px) {
  .modal-content {
    width: 100%;
  }
}

.time-extension-button {
  background-color: #17bae0;
  position: relative;
  border-radius: 25px;
  width: 108px;
  height: 37px;
  font: normal normal normal 16px/19px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
}

.session-end-text {
  font-weight: bold;
  padding-top: 17px;
  margin-bottom: 0px;
}
/* p:nth-child(even) {
  font-size: 13px;
  margin-bottom: 0px;
} */
.button-spacing {
  padding-top: 25px;
  padding-right: 0px;
  padding-left: 0px;
  text-align: center;
  /* margin: 0px 10px 0px 0px; */
}
.time-extension-button {
  margin: 0px 10px 0px 0px;
}

.notification-icon {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 35px;
  margin-top: 21px;
}

.success {
  color: #2d8811;
  background: #e2ffd9 0% 0% no-repeat padding-box;
}
.failure {
  color: #ff0000;
  background: #ffdbdb 0% 0% no-repeat padding-box;
}

.message-type {
  font-size: 15px;
  border-radius: 16px;
  padding: 4px 12px;
}
