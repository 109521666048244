// add content css ==>
.add-content-header {
  margin-top: 20px;
  padding-left: 20px;
  padding-bottom: 15px;
  padding-right: 20px;
}

.add-content-container {
  margin: 0px 10px 10px 10px;
  border: 1px solid #d6dae5;
  border-radius: 10px;
  width: 40vw;
  height: calc(100vh - 180px);
  overflow-y: auto;
}

.add-content-header {
  margin-top: 20px;
  padding-left: 20px;
  padding-bottom: 15px;
  padding-right: 20px;
}

.add-content-footer-container {
  position: relative;
}

.add-content-Footer {
  text-align: center;
  padding: 10px 0px;
  position: absolute;
  top: -100px;
  right: 60px;
}

.add-content-error {
  display: inline-flex;
  width: fit-content;
  height: 40px;
  color: #da1346;
  background-color: #ffebf3;
  border-radius: 6px;
  font: normal 16px Rubik, sans-serif;
  padding: 10px;
  // animation: cssAnimation 2s forwards;
}

// @keyframes cssAnimation {
//   0% {
//     opacity: 1;
//   }
//   90% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// }
.add-content-error-container {
  text-align: center;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
}

.max-size-error {
  text-align: center;
  width: 100%;
  height: 40px;
  color: #da1346;
  background-color: #ffebf3;
  border-radius: 6px;
  font: normal 16px Rubik, sans-serif;
  margin-top: 20px;
  padding: 10px;
}

// <==
// SuggestedKeywords css ==>
.active-keyword {
  padding: 8px 16px;
  border-radius: 30px;
  height: 36px;
  color: #17bae0;
  font: 14px/19px Rubik, sans-serif;
  font-weight: 300;
  transition: "all 200ms ease";
  cursor: pointer;
  border: solid 1px;
  border-color: #17bae0;
}

.inactive-keyword {
  // background-color: #f7fdff;
  // padding: 8px 16px;
  // border-radius: 30px;
  // height: 36px;
  // color: #17bae0;
  // font: 14px/19px Rubik;
  // font-weight: 300;
  // cursor: pointer;
  // border: solid 1px;
  // border-color: #17bae0;
  background-color: #c5c6c726;
  padding: 8px 16px;
  border-radius: 30px;
  height: 36px;
  color: #a3a9b7;
  font: 14px/19px Rubik, sans-serif;
  font-weight: 300;
  cursor: pointer;
  border: solid 1px;
  border-color: #a3a9b7;
  pointer-events: none;
}

.selected-keyword {
  background-color: #17bae0;
  padding: 8px 16px;
  border-radius: 30px;
  height: 36px;
  color: #ffffff;
  font: 14px/19px Rubik, sans-serif;
  font-weight: 300;
  transition: "all 200ms ease";
  cursor: pointer;
  border: solid 1px;
  border-color: #17bae0;
}

.active-keyword-extra {
  display: inline-block;
  margin-bottom: 10px !important;
}

.suggested-keywords-header {
  display: flex;
  padding: 10px 20px 5px 16px;
  h1 {
    font: normal 14px Rubik, sans-serif;
  }
}

.suggested-keywords-list {
  padding: 10px 10px 5px 0px;
  overflow: hidden;
}

.suggested-keywords-list-active {
  height: 100px;
}

.suggested-keywords-list-inactive {
  height: auto;
}

.active-keyword-extra {
  display: inline-block;
  margin-bottom: 10px !important;
}

.show-more-btn {
  text-align: center;
  color: #17bae0;
  font: normal 14px Rubik, sans-serif;
  background-color: white;
  padding: 10px;
  align-items: center;
}

.add-patients-btn {
  display: flex;
  align-items: center;
  color: #17bae0;
  font: normal 14px Rubik, sans-serif;
}

// <==
// share content ==>
.share-base-content-container {
  width: 100%;
  min-height: 340px;
  padding-left: 0px;
  padding-right: 0px;
  background-color: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  margin-bottom: 100px;
  h1 {
    font: normal normal bold 18px/22px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #2f2f2f;
  }
}

.share-content-inner-container {
  max-height: 250px;
  min-height: 190px;
  overflow-y: auto;
}

.share-content-header {
  margin-top: 30px;
  margin-bottom: 20px;
}

.share-content-input-field-container {
  flex: 1;
  min-height: 100px;
  margin: 20px 10px;
}

.share-content-input-field {
  flex: 1;
  margin-left: 10px;
  margin-top: 10px;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.share-content-upload-media-img-container {
  margin: 0px 16px;
  position: relative;
}

.share-content-upload-media-cancel {
  position: absolute;
  float: right;
  z-index: 1;
  top: 15px;
  right: 10px;
}

.share-content-upload-media-img {
  height: 594px;
  width: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.share-content-container {
  margin: 0px 10px 10px 10px;
  border: 1px solid #d6dae5;
  border-radius: 10px;
  width: 40vw;
}

.share-content-top-container {
  height: calc(100vh - 270px);
  overflow-y: auto;
}

.share-content-img-div {
  display: inline-flex;
  align-items: center;
  border-radius: 20px;
  padding-left: 10px;
  img {
    width: 20px;
    height: 20px;
  }
}

.share-content-footer-text {
  //   font: normal normal medium 12px/20px Rubik;
  font-size: 12px;
  font-family: Rubik, sans-serif;
  padding-left: 10px;
}

.share-content-active-div {
  background-color: #17bae0;
  color: white;
  display: inline-flex;
  align-items: center;
  padding: 10px;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 20px;
}

.share-footer-container {
  margin: 10px;
  padding: 0px 10px;
  border-color: #d6dae5;
}

.share-content-green-div {
  background-color: #10a74a;
  color: white;
  display: inline-flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 20px;
}

.share-text-red {
  color: red;
  font: normal 12px Rubik, sans-serif;
}

.share-text-green {
  color: #10a74a;
  font: normal 12px Rubik, sans-serif;
}

// <==
// doc viewer ==>
.share-document-content {
  display: flex;
  width: 100%;
  background-color: #1d1d1d;
  button {
    padding: 0px;
    padding-right: 5px;
    padding-left: 5px;
    min-width: initial;
  }
  img {
    width: 20px;
  }
  p {
    color: #ffffff;
    font: normal normal normal 12px/19px Rubik, sans-serif;
  }
}

.share-document-content-right {
  padding: 10px;
  flex: 1;
}

.share-document-content-title {
  color: #ffffff;
  word-break: break-all;
  font: normal normal normal 12px/19px Rubik, sans-serif;
}

.share-document-content-description {
  color: #ffffff;
  font: normal normal normal 12px/19px Rubik, sans-serif;
  line-break: anywhere;
  a {
    color: white;
  }
}

#react-doc-viewer {
  height: 800px;
  #header-bar {
    display: none;
  }
  #image-renderer {
    background-image: none !important;
  }
}

//<==
.profile_circular_div {
  width: 24px;
  height: 24px;
  background-color: #f969a4;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  display: flex;
  span {
    flex: 1;
    color: white;
    font: normal 9px Rubik, sans-serif;
  }
}

.profile_circular_div_big {
  width: 45px;
  height: 45px;
  background-color: #f969a4;
  border-radius: 50%;
  text-align: center;
  align-items: center;
  display: flex;
  span {
    flex: 1;
    color: white;
    font: normal 14px Rubik, sans-serif;
  }
}

.inactive-div-gray {
  background-color: #a3a9b7;
  color: white;
  display: inline-flex;
  padding: 10px;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 20px;
  width: fit-content;
  font: normal 16 Rubik, sans-serif;
  pointer-events: none;
}

.active-div-blue {
  background-color: #17bae0;
  color: white;
  display: inline-flex;
  padding: 10px;
  padding-right: 30px;
  padding-left: 30px;
  border-radius: 20px;
  width: fit-content;
  font: normal 16 Rubik, sans-serif;
  pointer-events: initial;
}

@media (max-width: 768px) {
  .add-content-container {
    // margin: 0px 10px 10px 10px;
    border: 1px solid #d6dae5;
    border-radius: 10px;
    width: 95vw;
    height: calc(100vh - 180px);
    overflow-y: auto;
  }
  .share-content-container {
    margin: 0px 10px 10px 10px;
    border: 1px solid #d6dae5;
    border-radius: 10px;
    width: 95vw;
  }
}

.skip-btn {
  background: #e6e6e6 0% 0% no-repeat padding-box;
  border-radius: 23px;
  padding: 5px 6px;
  font: normal normal 400 14px/17px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #1b2027;
  cursor: pointer;
}
