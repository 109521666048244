@use "../../Util/c2cConstants/colorConstants" as color;
@use "../../Util/c2cConstants/fontFileConstants" as font;

$font-family: "Rubik", sans-serif;
$color-primary: #17bae0;
$color-main-heading: #1b2027;
$color-field-heading: #000000;
$color-field-text: #000000;
$color-secondary: #f969a4;
$color-green: #10a74a;
$color-icon-text: #a3a9b7;
$color-patient-list-heading: #a3a9b7;
$border-color: #e6e6e6;

.modal-backdrop,
.modal {
  z-index: 9999;
}

.steps-container {
  .visited {
    text-align: left;
    font: normal normal 600 16px/19px "Rubik", sans-serif;
    color: #1b2027;
    text-transform: capitalize;
    opacity: 1;
    margin-top: 1rem;

    .circle-step-wrapper {
      border: 1px solid #f969a4;
      background: #f969a4 0% 0% no-repeat padding-box;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .not-visited {
    text-align: left;
    font: normal normal 600 16px/19px "Rubik", sans-serif;
    letter-spacing: 1px;
    color: #a3a9b7;
    text-transform: capitalize;
    opacity: 1;
    margin-top: 1rem;

    .circle-step-wrapper {
      color: #a3a9b7;
      border: 1px solid #a3a9b7;
    }
  }

  .circle-step-wrapper {
    text-align: center;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    border-radius: 50%;
    margin-right: 1.5rem;
  }
}

.appointment-steps-wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 75vh;
}

.appointment-steps-wrapper::-webkit-scrollbar {
  display: none;
}

.appointment-steps-wrapper-sec {
  overflow-x: hidden;
  overflow-y: auto;
  height: 75vh;
}

.appointment-steps-wrapper-sec::-webkit-scrollbar {
  width: 12px;
}

.appointment-steps-wrapper-sec::-webkit-scrollbar-track {
  background-color: #fff;
}

.appointment-steps-wrapper-sec::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
  -webkit-box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
}

.gender-form-check-input {
  margin: 0px 10px 0px 0px;
}

.age-info-icon {
  margin: 2px 0px 0px 0px;
}
.pss-info-icon {
  margin: 2px 0px 4px 0px;
}

.patient-country-code-wrapper {
  font-size: 14px;
  padding: 0px 10px;
  border-right: 1px solid #d6dae5;
  display: flex;
  align-items: center;
  height: 100%;
}

.calendar-wrapper {
  position: absolute;
  z-index: 100;

  @media screen and (max-width: 501px) {
    width: 96%;
    min-width: none;
  }
}

.patient-search-container {
  .search-title {
    text-align: center;
    font: normal normal normal 20px/24px "Rubik", sans-serif;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
}

.hr-with-text {
  display: flex;
  align-items: center;
  text-align: center;
  font: normal normal 500 14px/27px "Rubik", sans-serif;
  letter-spacing: 0.5px;
  color: #a3a9b7;
  opacity: 1;
}

.gender-input {
  width: 33% !important;
}

.hr-with-text::before,
.hr-with-text::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid #a3a9b7;
}

.hr-with-text:not(:empty)::before {
  margin-right: 0.25em;
}

.hr-with-text:not(:empty)::after {
  margin-left: 0.25em;
}

.fixed-button {
  position: fixed;
  width: 50%;
  bottom: 32px;
  z-index: 10;
  background: white;
  height: 50px;
}

.main-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 50vh;
}

.secondary-button {
  min-width: 100px;
  height: auto;
  background-color: white;
  color: #17bae0;
  border: 1px solid lightgray;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    background-color: darken($color: #17bae0, $amount: 5%);
    color: white;
  }
}

.primary-cta-button {
  min-width: 100px;
  height: auto;
  background-color: #17bae0;
  color: white;
  border: none;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    background-color: darken($color: #17bae0, $amount: 5%);
  }
}

.patient-details-form-container {
  .patient-form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 0.5rem;

    label {
      color: $color-field-heading;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
    }

    label.required::after {
      content: " *";
      color: #f969a4;
    }

    .upload-docs {
      width: 100%;

      .choose-file-btn {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 10px;
      }
    }

    input {
      background: #f5f6f7 0% 0% no-repeat padding-box;
      border: 1px solid #dbdbdb;
      border-radius: 5px;
      opacity: 1;
      width: 100%;
      padding: 0.5rem 0.5rem;

      &.city_input_val {
        text-transform: capitalize;
      }

      .options-list {
        color: red;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .button-container-row {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-top: 1rem;
  }

  .error {
    color: $color-secondary;
    margin: 0;
    font: normal normal 500 12px Rubik, sans-serif;
  }

  .error-uhid {
    color: #ff1919;
    font: normal normal 500 10px/12px Rubik, sans-serif;
    margin: 0;
    text-align: left;
  }

  .search-error {
    border: 1px solid #ff1919;
  }

  .input-disabled {
    opacity: 1;
    color: rgb(163, 169, 183);
  }

  .pd-abha-address-input-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    .pd-abha-input-container {
      display: flex;
      background: #f5f6f7 0% 0% no-repeat padding-box;
      border-radius: 5px;
      border: 1px solid #dbdbdb;
      width: 40%;
      height: 48px;
      font: normal normal normal 14px/17px Rubik, sans-serif;
      .pd-abha-input {
        border: none;
        background-color: transparent;
      }

      .pd-abha-input-suffix {
        display: flex;
        align-items: center;
        padding: 10px;
        border-left: 1px solid #cfd4e2;
      }
    }

    .pd-abha-text {
      font: normal normal 500 14px/28px Rubik, sans-serif;
      width: 1px;
      height: 35px;
      background-color: #ccc;
      margin: 0 10px;
    }

    .pd-abha-scan-qr-code-container {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      height: 48px;
      padding: 8px 12px;
      background: #ffffff;
      font: normal normal normal 12px/15px Rubik, sans-serif;
      color: #17bae0;
      cursor: pointer;
      white-space: nowrap;
      img {
        width: 19px;
        height: 19px;
        margin-right: 4px;
        margin-bottom: 4px;
      }
    }

    @media screen and (max-width: 768px) {
      display: block;
      margin-bottom: 15px;

      .pd-abha-input-container {
        width: 100%;
      }

      .pd-abha-text {
        display: none;
      }

      .pd-abha-scan-qr-code-container {
        margin-left: 0;
        flex-direction: row;
        justify-content: flex-start;
        padding: 3px;
        font: normal normal medium 12px/15px Rubik, sans-serif;
      }
    }
  }

  .pd-abha-scan-back-icon {
    display: flex;
    align-items: flex-start;

    img {
      transform: rotate(180deg);
      cursor: pointer;
    }
  }

  .pd-abha-scan-back-icon-confirmation-screen {
    max-width: 83.33333333%;
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
    margin: auto;
  }

  .pd-abha-scan-container {
    background: #efefef;
    border-radius: 5px;
    width: 100%;
    height: 334px;
    margin-top: 41px;
  }

  .pd-abha-scan-container-scan-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    z-index: 1;
    position: absolute;
    top: 6%;
    right: 0;
    bottom: 0;
    left: 0;

    img {
      width: 141px;
      height: 141px;
    }
  }

  .pd-abha-scan-invalid-qr-code-container {
    display: flex;
    justify-content: center;
    margin-top: 24%;
  }

  .pd-abha-scan-invalid-qr-code-error-icon {
    width: 77px;
    height: 77px;
  }

  .pd-abha-scan-invalid-qr-code-text {
    text-align: center;
    font: normal normal 500 20px/24px Rubik, sans-serif;
    color: #484848;
    margin: 17px 0;
  }

  .pd-abha-qr-data-confirmation {
    overflow-y: auto;
    max-height: calc(100vh - 288px);
  }

  .pd-abha-qr-data-confirmation::-webkit-scrollbar {
    width: 12px;
  }

  .pd-abha-qr-data-confirmation::-webkit-scrollbar-track {
    background-color: #fff;
  }

  .pd-abha-qr-data-confirmation::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
    -webkit-box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
  }

  .pd-abha-qr-data-confirmation-title {
    font: normal normal 500 25px/29px Rubik, sans-serif;
    color: #1b2027;
    margin-bottom: 13px;
  }

  .pd-abha-qr-data-confirmation-subtitle {
    font: normal normal normal 14px/20px Rubik, sans-serif;
    color: #1b2027;
    margin-bottom: 16px;
  }

  .pd-abha-qr-data-confirmation-content-header {
    border-top: 1px solid #e1e1e1;
    border-bottom: 1px solid #e1e1e1;
    display: flex;
    align-items: center;
    font: normal normal 500 16px/20px Rubik, sans-serif;
    color: #1b2027;
  }

  .pd-abha-qr-data-confirmation-content-header-text {
    padding: 10px 0;
    width: 50%;
  }

  .pd-abha-qr-data-confirmation-content-body-container {
    display: flex;
    border-bottom: 1px solid #e1e1e1;
  }

  .pd-abha-qr-data-patient-details-title {
    font: normal normal normal 14px/17px Rubik, sans-serif;
    color: #a3a9b7;
    margin-bottom: 5px;
  }

  .pd-abha-qr-data-patient-details-value {
    font: normal normal normal 14px/17px Rubik, sans-serif;
    color: #1b2027;
    margin-bottom: 12px;
    overflow-wrap: anywhere;
  }

  .pd-abha-qr-data-confirmation-content-body {
    width: 50%;
    padding: 15px 20px;
    text-align: left;
  }

  .pd-abha-qr-data-confirmation-footer-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 25px;
  }

  .pd-abha-qr-data-confirmation-footer-left-btn {
    margin-right: 12px;
  }

  @media screen and (max-width: 768px) {
    .pd-abha-qr-data-confirmation {
      max-height: calc(100vh - 300px);
    }

    .pd-abha-scan-back-icon-confirmation-screen {
      padding-left: 10px;
    }
  }
}

.referral_name_small_text {
  color: #a3a9b7;
  font-size: 11px !important;
}

.referral_name_div[disabled] {
  pointer-events: none;
  opacity: 0.7;
  background: #6c67670f;
}

.book-appointment-procedure-container {
  width: 100%;

  .book-appointment-procedure-wrapper {
    display: flex;
    height: 90vh;
  }
}

.book-appointments-container-wrapper {
  background-color: #fff;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  @media screen and (max-width: 768px) {
    overflow: auto;
  }
}

.book-appointments-container-wrapper::-webkit-scrollbar {
  width: 12px;
}

.book-appointments-container-wrapper::-webkit-scrollbar-track {
  background-color: #fff;
}

.book-appointments-container-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
  -webkit-box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
}

.book-appointments-container-wrapper-sec {
  background-color: #fff;
  width: 65%;
  height: 100%;
  overflow-y: auto;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  @media screen and (max-width: 768px) {
    width: 100%;
    border-radius: 10px;
  }
}

.book-appointments-container-wrapper-sec::-webkit-scrollbar {
  width: 12px;
}

.book-appointments-container-wrapper-sec::-webkit-scrollbar-track {
  background-color: #fff;
}

.book-appointments-container-wrapper-sec::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
  -webkit-box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
}

.book-appointment-header-titie {
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    justify-content: space-between;

    h1 {
      min-width: 60vw;
      text-align: center;
    }
  }
}

.book-appointment-header-titie-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.physical-consultation-back-btn {
  transform: rotate(180deg);
  cursor: pointer;
  margin-left: 30px;
  @media (max-width: 767px) {
    margin-left: 0px;
  }
}

.resource-modal-contanier {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 262px;
  background-color: #fff;
  border-radius: 10px;
  margin-left: 3px;
}

.reviewshare {
  width: 95%;

  h1,
  h2 {
    font-family: $font-family;
    font-size: 20px;
    line-height: 24px;
    color: $color-main-heading;
    font-weight: bold;
  }

  h1,
  h3 {
    text-transform: uppercase;
  }

  h3 {
    font-size: 14px;
    line-height: 16px;
    color: $color-field-heading;
  }

  .overflow-guard {
    overflow-wrap: anywhere;
  }

  .details {
    font-size: 16px;
    padding-top: 0.5rem;
    color: $color-field-heading;
  }

  .discount {
    color: $color-green;
  }

  .fnt-transcaps {
    text-transform: capitalize;
  }

  .icon-wrapper {
    .icon-credit-card,
    .icon-cash,
    .icon-sms {
      background-size: cover;
      display: inline-flex;
      height: 2rem;
      width: 2rem;
    }

    .icon-card {
      align-items: center;
      width: 135px;
      height: 110px;
      border: 1px solid #e1e1e1;
      position: relative;
      padding: 0px;
      box-sizing: border-box;

      &.left {
        margin-right: 10px;
      }

      &.right {
        margin-left: 10px;
      }

      .okicon_img {
        display: none;
        position: absolute;
        top: 5px;
        right: 5px;
        width: 15px;
        height: 15px;
      }

      &.active-payment-mode {
        .okicon_img {
          display: flex;
        }
      }
    }

    .icon-credit-card {
      background-image: url(../../assets/credit-card.svg);
    }

    .icon-cash {
      background-image: url(../../assets/cash-payment.svg);
    }

    .icon-sms {
      background-image: url(../../assets/smsicon.svg);
    }

    .icon-label {
      font-family: $font-family;
      font-size: 14px;
      color: $color-icon-text;
      display: inline-flex;
    }
  }

  .stretched-link {
    text-decoration: none;
    color: $color-field-heading;
    font-weight: bold;
  }
}

.patient-attendee-card {
  height: 6rem;
  width: 6rem;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.physical-consult {
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-body {
    overflow-x: hidden;
  }

  .modal-content {
    min-height: 30rem;
    z-index: 1000 !important;
  }

  .button-fixed {
    position: sticky;
    position: -webkit-sticky;
    bottom: 0;
    z-index: 9999;
  }

  h1,
  h2 {
    font-family: $font-family;
    font-size: 20px;
    line-height: 24px;
    color: $color-main-heading;
  }

  h1,
  h3 {
    text-transform: uppercase;
  }

  h3 {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: $color-field-heading;

    &.total_fee {
      font-size: 18px;
    }

    &.total_fee_amount {
      font-size: 18px;

      .icon {
        font-weight: initial;
      }
    }
  }

  h2 {
    &.consultation_shared_book_heading {
      font-size: 25px;

      @media (max-width: 992px) {
        font-size: 20px;
      }
    }
  }

  .review-and-share-payment-methods {
    display: flex;
    margin-bottom: 20px;
  }

  .review-and-share-partial-amount-container {
    margin-left: 17px;
    margin-top: 8px;
  }

  .review-and-share-partial-amount-input {
    display: flex;
    width: 100%;
    margin-top: 15px;

    .form-input-with-header-input {
      border-radius: 2px;
      background-color: #f8f8f8;
      border: none;
      padding: 7.5px 10px;

      input {
        font: normal normal normal 14px/17px Rubik, sans-serif;
        color: #212121;
      }
    }

    .form-input-with-header-input input::placeholder {
      color: #b2b2b2;
    }
  }

  .review-and-share-partial-amount-apply-btn {
    border-radius: 0px 2px 2px 0px;
    background-color: #17bae0;
    color: #fff;
    padding: 11px;
    font: normal normal normal 14px/17px Rubik, sans-serif;
    cursor: pointer;
  }

  .review-and-share-partial-amount-error {
    font: normal normal normal 12px/14px Rubik, sans-serif;
    color: #f82323;
    margin-top: 5px;
    overflow-wrap: anywhere;

    .form-input-with-header-input {
      border: 1px solid #f82323;
    }
  }

  .online-payment-container {
    .online-payment-section-container {
      background: #ffffff;
      border: 1px solid #e1e1e1;
      border-radius: 5px;
      padding: 15px;
      margin-bottom: 10px;
    }

    .online-payment-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font: normal normal normal 15px/18px Rubik, sans-serif;
      color: #141414;
    }

    .online-payment-discount-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font: normal normal normal 15px/18px Rubik, sans-serif;
      color: #00b569;
      margin-top: 15px;
    }

    .online-payment-total-fee-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font: normal normal 500 15px/18px Rubik, sans-serif;
      color: #141414;
    }

    .online-payment-gst-text {
      font: normal normal 400 12px/14.22px font.$C2C_FONT_FAMILY;
      display: flex;
      align-items: center;
    }
  }

  .btn-close {
    font-size: 14px;
    line-height: 16px;
    color: $color-field-heading;
    background-color: transparent;
  }

  .btn-close-resource {
    font-size: 12px;
    line-height: 16px;
    color: $color-field-heading;
    background-color: transparent;
    padding: 10px;
  }

  .step-column {
    background-color: #f5f6f7;

    @media (min-width: 992px) {
      min-height: 30rem;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
  }

  @media (max-width: 768px) {
    .toggle-switch-icon {
      margin-right: 8px;
    }
  }

  .consultation-type {
    max-width: 16rem;
  }

  .patient-list {
    overflow-x: auto;
    overflow-y: auto;

    .card {
      min-width: 13rem; // 9rem;

      .card-body {
        .heading {
          color: $color-patient-list-heading;
          font-size: 12px;
        }
      }
    }

    .active-card {
      border: 2px solid $color-primary;
    }

    .patients_name {
      font-size: 14px;
    }

    @media (max-width: 992px) {
      max-height: 14rem;
    }
  }

  .booking-confirmation {
    p {
      line-height: 1.5rem;

      &.consultation_shared_book_para {
        letter-spacing: 0px;
        font-size: 20px;
        color: #000000;
        font-family: "Rubik", sans-serif !important;
        line-height: inherit;
        opacity: 1;

        @media (max-width: 992px) {
          font-size: 16px;
        }
      }
    }
  }

  .btn-tertiary {
    background-color: #ffffff;
    color: $color-primary;
    border-color: $color-primary;
  }

  @media screen and (max-width: 767px) {
    span {
      text-align: center;
    }
  }
}

@media screen and (max-width: 600px) {
  .card_size.add_new_patient_card {
    height: 70px;
  }

  .add_new_patient_card .card-body {
    flex-direction: row !important;
  }

  .physical-consult .patient-list .card {
    margin-left: 0% !important;
    height: 70%;
  }
}

//css for phone input
.phone_width {
  width: -webkit-fill-available;
}

//css for date picker
.react-date-picker {
  width: 90%;
}

.react-date-picker__inputGroup__input {
  height: auto;
}

.rs-picker-menu {
  z-index: 999999;
}

.rs-picker-toggle-wrapper {
  width: inherit;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
  color: black;
  text-align: left;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  background-color: #f5f6f7 !important;
}

.rs-picker-menu .rs-picker-toolbar {
  display: none;
}

.rs-picker-toggle.active {
  border-color: #dbdbdb;
}

.rs-picker-toggle.active,
.rs-btn.active {
  border-color: #dbdbdb !important; //1675e0
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content,
.rs-calendar-month-dropdown-cell-active
  .rs-calendar-month-dropdown-cell-content {
  color: #ffffff;
  border-color: #f969af;
  background-color: #f969af;
  border-radius: 0px;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content {
  border: 1px solid #f969af;
  border-radius: 0px;
}

.rs-calendar-month-dropdown-year-active,
.rs-calendar-show-month-dropdown .rs-calendar-header-title-date {
  color: #f969af;
}

// css for state select
.select_style {
  text-transform: capitalize;

  .css-4ljt47-MenuList {
    max-height: 140px;
  }

  .css-cheahe-control {
    box-shadow: none;
    border-color: #dbdbdb;
  }
}

.select_style_state {
  cursor: not-allowed;

  div {
    pointer-events: none;
    cursor: not-allowed;
  }
}

.city_input_val {
  text-transform: capitalize;
}

.city_input_val::-webkit-input-placeholder {
  /* WebKit browsers */
  text-transform: none;
}

.city_input_val:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  text-transform: none;
}

.city_input_val::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  text-transform: none;
}

.city_input_val:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  text-transform: none;
}

.city_input_val::placeholder {
  /* Recent browsers */
  text-transform: none;
}

.css-9gakcf-option {
  background-color: #17bae0 !important;
}

//css for checkbox
input[type="radio"]:after {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  top: -3px;
  left: -2px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 6px solid #d1d3d1;
}

input[type="radio"]:checked:after {
  border-color: #17bae0;
  background-color: #fff;
}

// css for date picker

.input_div {
  display: flex;
  width: inherit;
  background-color: #f5f6f7;
  border-radius: 5px;
  border: 1px solid #dbdbdb;

  input {
    border: none !important;
  }
}

.rdrMonth {
  width: inherit;
}

.cal_modal {
  position: absolute;
  top: 66px;
  left: 15px;
  background-color: white;
  z-index: 99999;
}

.cal_img {
  width: 24px;
  height: 24px;
  margin: auto 3px;
}

.clear_icon {
  font-size: 21px;
  margin: auto 3px;
  opacity: 0.3;
}

.active-payment-mode {
  border: 2px solid $color-primary !important;
}

.pending-appointment {
  .MuiPaper-root {
    z-index: 100 !important;
  }

  section {
    @media (min-width: 992px) {
      min-width: 42rem;
    }
  }

  .header {
    background-color: transparent;
    color: #f969a4;
    text-transform: uppercase;
    font-size: smaller;
    font-weight: 600;
    font-family: "Rubik", sans-serif !important;
    letter-spacing: 1px;
  }

  .appointment-row {
    border: 1px solid $border-color;
    background-color: #ffffff;
    margin: 10px 0px;

    .consultation,
    .consultation-mobile {
      .initial {
        color: $color-primary;
        border-color: $color-primary;
      }

      .followup {
        color: $color-secondary;
        border-color: $color-secondary;
      }
    }

    .consultation {
      span {
        border: 1px solid #ccc;
        padding: 0.6rem 1.6rem;
        text-transform: uppercase;
      }
    }

    .consultation-mobile {
      span {
        border: 1px solid #ccc;
        padding: 0.3rem 1.2rem;
        text-transform: uppercase;
      }
    }
  }
}

.delete-appointment {
  .modal-body {
    max-width: 40rem !important;
  }
}

.button_style {
  background-color: #17bae0;
  border: none;
}

.button_style:hover,
.button_style:disabled,
.button_style:focus {
  background-color: #17bae0;
  border: none;
}

// css for 3rd step mobile number input field
.button-display {
  background-color: #17bae0;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 16px;
}

.phno_style {
  background-color: #f5f6f7;
  width: 155%;

  .prefix {
    background-color: #fff;
    border-right: none;
  }

  .inpt {
    background-color: #fff;
  }
}

.pc_scroll {
  overflow-x: hidden;
}

.pc_scroll::-webkit-scrollbar {
  width: 12px;
}

.pc_scroll::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #fafafa;
}

.pc_scroll::-webkit-scrollbar-thumb {
  min-height: 40px;
  border-radius: 10px;
  background-color: #c1c1c1;
  box-shadow: none;
}

.css-1wa3eu0-placeholder {
  color: #a3a9b7 !important;
}

.line {
  width: 92px;
  height: 0px;
  border-top: 1px solid #a3a9b7;
}

.or_style {
  margin-top: 10px;
  padding: 0px 15px;
  color: #a3a9b7;
}

.search_width {
  .css-2b097c-container {
    width: 100%; //80%;
  }
}

.custom_select {
  width: 230px !important;
  border: 1px solid lightgrey !important;
  border-radius: 5px !important;
}

//css for search bar

.pc-search-wrapper {
  display: flex;
  flex: 2;
  flex-direction: column;
  position: relative;
}

.pc-search-wrapper .input-holder {
  display: flex;
  flex: 1;
  align-items: center;
  background-color: #f5f6f7;
  border-radius: 6px;
  position: relative;
  transition: none; //all 0.3s ease-in-out;
  z-index: 9;
  border: 1px solid #d6dae5;
}

.pc-search-wrapper.isActive .input-holder {
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border: 1px solid rgb(86, 205, 232);
  border-bottom: none;
}

.pc-search-wrapper.active .input-holder {
  width: 450px;
  border-radius: 50px;
  transition: all 0.5s cubic-bezier(0, 0.105, 0.035, 1.57);
}

.pc-search-wrapper .search-error {
  border: 1px solid red !important;
}

.pc-search-wrapper .input-holder .search-input {
  width: 100%;
  padding: 10px 10px 10px 10px;
  background: #f5f6f7 !important;
  box-sizing: border-box;
  border: none;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition-delay: 0.3s;
  color: #000;
  border-radius: 5px;
}

.pc-search-wrapper.active .input-holder .search-input {
  opacity: 1;
  transform: translate(0, 10px);
}

.pc-search-wrapper .input-holder .search-icon {
  position: absolute;
  cursor: pointer;
  padding: 0px 10px;
}

.close_serch {
  display: block;
  width: 24px; //added new
  font-size: 18px;
  opacity: 0.5;
}

.mobile_close_serch {
  display: flex !important;
}

.close_serch img {
  cursor: pointer;
}

.pc-search-wrapper.isActive .close_serch {
  display: flex;
  background-color: #f5f6f7;
  align-items: center;
  padding-right: 5px;
  padding-bottom: 4px;

  width: 24px; //added new
  font-size: 18px;
  opacity: 0.5;
}

.searched_value {
  display: none;
  width: 100%;
  position: absolute;
  left: 0;
  top: 40px;
  right: 0;
  background-color: #f5f6f7;
  padding: 15px 0px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 999;
}

.pc-search-wrapper.isActive .searched_value {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(86, 205, 232);
  border-top: none;
}

.pc-search-wrapper.has-error .searched_value {
  border: 1px solid red;
  border-top: none;
}

.searched_value ul {
  width: 100%;
  margin: 0px;
  max-height: 210px; //152px;
  overflow-y: auto;
}

.searched_value ul li {
  width: 100%;
  margin: 0px;
  cursor: pointer;
  padding: 15px;
  border-bottom: 1px solid rgba(163, 169, 183, 0.5);
  border-radius: 0px;
  overflow-wrap: anywhere;
}

.searched_value ul li:last-child {
  border-bottom: 0px;
}

.searched_item {
  display: flex;
  justify-content: space-between;
}

.searched_item_img {
  max-width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.searched_item_img img {
  width: 100%;
  height: 100%;
}

.searched_item_text h5 {
  text-align: left;
  font-size: 16px;
  line-height: initial;
  color: #1b2027;
  font-weight: bold;
  margin: 0px;
}

.searched_item_text p {
  font-size: 12px;
  line-height: initial;
  color: #a3a9b7;
  font-weight: 200;
  padding-top: 4px;
  margin: 0px;
}

.search-input::placeholder {
  color: #a3a9b7;
  opacity: 1;
  font-size: 12px;
}

.search-input:-ms-input-placeholder {
  color: #a3a9b7;
  font-size: 12px;
}

.search-input::-ms-input-placeholder {
  color: #a3a9b7;
  font-size: 12px;
}

.no_data_found {
  display: flex;
  justify-content: center;
  align-items: center;
}

//header_style
.header_style {
  background-color: white;
  position: sticky;
  padding: 0px 10px;
}

//select-patient-scroll
.select-patient-scroll::-webkit-scrollbar {
  height: 12px;
}

.select-patient-scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.select-patient-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
  -webkit-box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
}

//confirmation screen

.padding_inclinic {
  padding: 0px 60px;
}

.padding_online {
  padding: 0px 25px;
}

.whatsapp_checkbox {
  background: #dde4ea;
  margin-right: 12px;
  margin-bottom: 0px;
  width: 15px;
  height: 15px;
  border-radius: 2px;
}

.whatsapp_div {
  margin-bottom: 5px;
}

.whatsapp_ntfcn {
  font: normal normal medium 12px/14px Rubik, sans-serif;
  font-weight: 500;
  margin-right: 3px;
}

.whatsapp_number_span {
  font: normal normal normal 12px/14px Rubik, sans-serif !important;
  letter-spacing: 0px;
  color: #17bae0 !important;
}

.whatsapp_edit_div {
  text-align: left;
  font: normal normal normal 12px/14px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #a3a9b7;
  width: 100%;
}

.whatsapp_width {
  width: 100%;
  margin-left: 15px;
  margin-top: 5px;
  position: relative;
}

.img_div {
  display: flex;
  position: absolute;
  right: 1px;
  top: 9px;
}

.img_div span {
  width: 1.2px !important;
  height: 30px;
  background-color: #c9c6c6e4;
  display: block;
  margin-top: -4px;
}

.whatsapp_edit_input {
  background: #f5f6f7 !important;
  box-sizing: border-box;
  outline: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  transition-delay: 0.3s;
  color: #000;

  width: 100% !important;
  border: 1px solid #d6d6d6 !important;
  border-left: 0px !important;
  border-radius: 0px 5px 5px 0px !important;
}

.abha-status-container {
  background: color.$C2C_WHITE;
  border: 1px solid color.$C2C_BLUE;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 106px;
  width: 106px;
  height: 32px;

  .abha-status-icon {
    width: 16px;
    margin-right: 5px;
    margin-bottom: 2px;
  }

  .abha-status-text {
    font: normal normal 500 10px/20px font.$C2C_FONT_FAMILY;
    color: color.$C2C_BLUE;
  }
}

@media (max-width: 450px) {
  .physical-consult h1 {
    font-size: 16px;
  }

  .close-img {
    width: 25px; //45px;
  }

  .padding_online {
    padding: 0px;
  }

  .padding_inclinic {
    padding: 0px;
  }

  .padding_online {
    padding: 0px;
  }
}

@media (max-width: 500px) {
  .physical-consult .pc_modal_body {
    height: 98vh;
    width: 95%;
    margin: 0.2rem;
  }
}

.word-length {
  word-break: break-all;
}

//mobile input css

.inputStyle {
  border-left: none !important;
  border-radius: 0px 0px !important;
}

.phstyle {
  border-radius: 5px 0px 0px 5px;
  background-color: #f5f6f7 !important;
}

//css for dropdown scroll

.searched_value_scroll {
  padding: 0;
  list-style: none;
}

.searched_value_scroll::-webkit-scrollbar {
  width: 10px;
}

.searched_value_scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

.searched_value_scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
  -webkit-box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
}

//
.rm_bgfocus:focus {
  border-color: #80bdff;
  outline: 0;
  box-shadow: none;
}

// divider style

.divider-t {
  border-top: 1px solid #8888881f;
}

.divider-b {
  border-bottom: 1px solid #8888881f;
}

.divider-y {
  border-top: 1px solid #8888881f;
  border-bottom: 1px solid #8888881f;
}

.card_bg {
  background-color: #eafced !important;
  border: 1px solid #ccffd5;
  width: 155%;
}

.card_size {
  height: 129px;
}

.card_size.add_new_patient_card {
  border-color: #eee !important;
  border-style: dashed !important;
  border-width: 3px;
}

//css for button

.button-style {
  width: 200px;
  margin-top: 5px;
}

.discount_btn_success:disabled {
  border: 1px solid #a3a9b7;
  color: white;
  background-color: #a3a9b7;
}

.input-group-append .btn {
  color: #fff;
}

.discount_btn_success {
  color: white;
  background-color: #17bae0;
  border: 1px solid #17bae0;
  width: 100px;
}

.discount_btn_fail {
  width: 100px;
  color: #fff;
  border: 1px solid #f969a4;
  background-color: #f969a4;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.close-png {
  width: fit-content;
}

.delete-icon {
  width: 45px;
  height: 45px;
}

@media (max-width: 767px) {
  .physical-consult {
    .button-fixed {
      display: inline-block;
      width: -webkit-fill-available;
    }
  }

  .phno_style {
    width: 100%;
  }

  .card_bg {
    width: 100%;
  }

  .book-appointment-procedure-container .book-appointment-procedure-wrapper {
    height: 95vh;
  }
}

@media (min-width: 992px) {
  .step-mobile {
    display: none;
  }
}

@media (max-width: 992px) {
  .step-desktop {
    display: none;
  }

  .step-mobile {
    z-index: 0;
    width: 93%;
    margin: 0 13px;
  }
}

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
  .select-max-width {
    max-width: 100%;
  }

  .book-appointment-procedure-wrapper {
    height: 95vh;
  }
}

.doct-info {
  border-bottom: 2px solid white;
}

.profile-container {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 38px;
}

.no-image {
  background-color: #17bae0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 40px;
  min-height: 40px;
}

.doctor_name {
  font: normal normal 500 14px/19px "Rubik", sans-serif;
  letter-spacing: 0px;
  color: #1b2027;
  margin: 0px;
}

.doctor_specialization {
  font: normal normal 300 12px/27px "Rubik", sans-serif;
  letter-spacing: 0px;
  color: #1b2027;
  margin: 0px;
}

//age picker

.age-wrapper .form-group {
  position: relative;
  box-shadow: 2px 2px 6px pink;
  width: 50px;
}

.date-dropdown-ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  max-height: 200px;
  overflow-y: auto;
  z-index: 9;
  background: #fff;
  border: 1px solid #dbdbdb;
  padding: 0;
  top: 3rem;
  border-radius: 5px;
  width: 100%;
}

.date-dropdown-ul li {
  padding: 5px;
  margin: 0px !important;
  border-radius: 0;
  width: 100%;
  user-select: none;
}

.age-wrapper {
  width: 100%;
}

.age-wrapper .form-group input {
  height: 45px;
  padding: 10px;
  width: 100%;
  background-color: #f5f6f7 !important;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
}

.age-wrapper .form-group {
  box-shadow: none;
  width: 30%;
}

.up-down-icons-wrapper {
  position: relative; //absolute
}

.up-down-icons {
  padding: 6px 10px;
  left: 70%;
}

.up-down-icons i {
  color: #17bae0;
}

.active-dropdown {
  border: 1px solid #17bae0 !important;
}

.date_error_message {
  border: 1px solid red;
  border-radius: 5px;
}

.age-label {
  text-align: left;
  color: #c1c1c1;
  font-size: 14px;
}

@media all and (max-width: 280px) {
  .pro-content {
    padding: 0px;
  }
}

@media (max-width: 767px) {
  .date-dropdown-ul {
    display: none;
  }
}

@media (min-width: 540px) and (max-width: 767px) {
  .up-down-icons {
    left: 70%;
  }
}

@media (min-width: 767px) and (max-width: 1021px) {
  .up-down-icons {
    left: 70%;
  }
}

@media all and (min-width: 1024px) and (max-width: 1030px) {
  .age-wrapper .form-group input {
    padding: 5px;
    margin-right: 5px;
  }
}

.Popover {
  z-index: 99999 !important;
  max-width: 420px;
}

.pc_modal_body {
  height: 91vh;
  width: 90%;
  border-radius: 10px;
  margin: 2rem;
  background-color: #fff;
}

.pc_modal_body_sec {
  height: 91vh;
  width: 92%;
  border-radius: 10px;
  margin: 2rem;
  overflow-y: none;
  @media (max-width: 767px) {
    margin: 1rem;
  }
}

.mr-btm {
  margin-bottom: 100px;
}

@media (min-width: 500px) and (max-width: 767px) {
  .mr-btm {
    margin-bottom: 50px;
  }

  .fixed-button {
    width: 60%;
    position: fixed;
    bottom: 35px;
  }
}

.sc-btn-div {
  position: fixed;
  bottom: 6%;
  width: 66%;
  text-align: center;
  background-color: white;
  padding: 20px 0px 14px 0px;
  box-shadow: 0 -5px 10px -5px #e9e6e6;
}

.sc-btn-div-resource {
  position: fixed;
  bottom: 6%;
  width: 52%;
  text-align: center;
  background-color: white;
  padding: 20px 0px 14px 0px;
  box-shadow: 0 -5px 10px -5px #e9e6e6;
  @media (max-width: 767px) {
    width: 88%;
    left: 50%;
    transform: translateX(-50%);
    bottom: 3.5%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .sc-btn-div {
    position: fixed;
    bottom: 59px;
    width: calc(85% - 100px);
  }

  .pc_modal_body {
    height: 93vh;
    margin: auto;
  }
}

@media (min-width: 501px) and (max-width: 576px) {
  .sc-btn-div {
    position: fixed;
    width: calc(75% - 17px);
    bottom: 17px;
  }

  .pc_modal_body {
    height: 98vh;
    width: 75%;
    margin: auto;
  }
}

@media (max-width: 500px) {
  .sc-btn-div {
    position: fixed;
    bottom: 18px;
    width: 95%;
  }

  .clinic-slot-wrapper {
    height: 40vh;
    overflow-y: auto;
    width: 95%;
  }
}

.facheck {
  color: white;
  font-size: 15px;
  margin-top: 1px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mt--11 {
  margin-top: -11px !important;
}

.mt-20 {
  margin-top: 20px !important;
}

.input-box-middle-line {
  font-size: 30px;
}

.color-black {
  color: #2f2f2f !important;
}

.color-gray {
  color: #a3a9b7 !important;
}

.future_appointment_container {
  position: sticky;
  top: 185px;
  z-index: 1020;
}

@media (min-width: 768px) {
  .space_style1 {
    padding-right: 0px;
  }

  .space_style2 {
    padding-left: 0px;
  }

  .padding_inclinic {
    padding: 0px;
  }

  .mr-btm {
    margin-bottom: 50px;
  }

  .fixed-button {
    width: 90%;
    position: fixed;
    bottom: 18px;
  }
}

@media (max-width: 768px) {
  .physical-consult {
    .review-and-share-payment-methods {
      display: block;
      margin-bottom: 0;
    }

    .review-and-share-partial-amount-container {
      margin-left: 0;
      margin-top: 15px;
      margin-bottom: 12px;
    }

    .review-and-share-partial-amount-input {
      margin-top: 12px;

      .form-input-with-header {
        width: 100%;
      }
    }

    .review-and-share-partial-amount-error {
      margin-bottom: 12px;
    }
  }

  .reviewshare {
    width: 100%;
    padding: 20px;
  }
}

.resource-ammount-table-container {
  border: 1px solid #dddddd;
  border-radius: 5px;
  margin-bottom: 10px;
  width: 100%;

  .resource-table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dddddd;
    padding: 10px 14px 10px 10px;
    font-size: 15px;
    font-weight: 600;
  }

  .resource-body-content-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 14px 10px 10px;
  }

  .resource-body-content {
    max-height: 166px;
    overflow-y: auto;
    font-size: 15px;
  }

  .resource-body-content::-webkit-scrollbar {
    width: 10px;
  }

  .resource-body-content::-webkit-scrollbar-track {
    background-color: #fff;
  }

  .resource-body-content::-webkit-scrollbar-thumb {
    min-height: 10px;
    border-radius: 10px;
    background-color: #c1c1c1;
    box-shadow: none;
  }

  .resorce-discription {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .resource-fee {
    width: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .resource-gst {
    width: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .resource-amount {
    width: 13%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

.no_data-found_wrapper {
  .no-data-found {
    border: none;
    height: 54vh;
    align-items: center;
    justify-content: center;
  }
}

.date-selector-disable {
  pointer-events: none;
}

.cancel-slots-popup {
  width: 660px;
  @media screen and (max-width: 768px) {
    width: unset;
  }
}
.date-time-selector-wrapper {
  padding: 20px 15px 20px 15px;
  .dts-premium,
  .dts-date {
    max-width: fit-content;
  }
  .week-day {
    color: #21252a;
    font-size: 14px;
    font-weight: 400;
  }
  @media screen and (max-width: 768px) {
    .dts-premium,
    .dts-date {
      padding-top: 10px;
      padding-bottom: 10px;
      max-width: none;
    }
  }
  .date-time-selector-wrapper-premium-toggle {
    .MuiSwitch-root {
      width: 35px;
      height: 18px;
    }
    @media screen and (max-width: 768px) {
      .MuiSwitch-root {
        margin-left: 0;
        margin-right: 0;
      }
    }
    .MuiSwitch-thumb {
      height: 16px;
      width: 16px;
    }
    .MuiSwitch-track {
      background-color: #d6dae5;
      border: 0;
    }
  }
}

@media screen and (max-width: 768px) {
  .premium-slot-selection-wrapper-box {
    margin-bottom: 100px;
  }

  .pss-slot-message {
    text-align: center;
    width: 70%;
  }

  .pss-divider {
    margin-top: 0px;
  }
}

.premium-slot-selection {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  .pss-main-wrapper {
    display: flex;
    justify-content: center;
    align-items: end;
    // flex-direction: column;
    padding-bottom: 5px;
    border-bottom: 1px solid #e6e6e6;

    .pss-slot-apply {
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 768px) {
        flex-direction: column;
      }
      .pss-slot-apply-enter-slot {
        margin-bottom: 0px;
        display: flex;
        align-items: center;
        margin-top: 4px;
        @media screen and (max-width: 768px) {
          align-self: flex-start;
        }
      }
      .delay-input-container .delay-input-field {
        min-width: auto;
      }
      .pss-apply {
        height: 35px;
        width: 75px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 12px;
        @media screen and (max-width: 768px) {
          margin-top: 8px;
        }
      }
      .pss-start-end-wrapper {
        display: flex;
        justify-content: space-around;
        height: 70px;
        .pss-start {
          margin: 0 20px 0 20px;
          @media screen and (max-width: 768px) {
            margin: 0 5px 0 5px;
          }
        }
        .pss-end {
          margin: 0 5px 0 5px;
        }
      }
    }
    hr {
      margin-top: 24.5px;
      border: 0;
      clear: both;
      display: block;
      width: 96%;
      background-color: #d6dae5;
      height: 1px;
    }

    .delay-error-message {
      margin: 4px 0px;
    }
  }

  @media screen and (max-width: 768px) {
    .pss-main-wrapper {
      border: none;
    }

    .pss-apply-button {
      display: flex;
      justify-content: end;
      width: 100%;
    }
  }
}
.pss-selected-slot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .premium-slot {
    width: 190px;
    height: 40px;
    background: color.$C2C_PINK 0% 0% no-repeat padding-box;
    border-radius: 6px;
    border: 1px solid color.$C2C_PINK;
    color: color.$C2C_WHITE;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal normal 12px/27px Rubik, sans-serif;
    margin-bottom: 17px;
    .premium-slot-badge {
      color: color.$C2C_PINK;
      background-color: color.$C2C_WHITE;
      height: 16px;
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 3px;
      margin-right: 8px;
    }
  }
}
.pc_modal_body_sec {
  .appointment-steps-wrapper {
    @media screen and (max-width: 768px) {
      padding: 0px 16px 16px 16px;
    }
  }
}
.booking-calendar-add-booking-resource {
  .date-time-selector-wrapper {
    @media screen and (max-width: 768px) {
      padding: 0px 5px;
    }
  }
}
