.menu {
  font: normal normal 600 14px/17px Rubik, sans-serif;
  letter-spacing: 0.7px;
  color: #000000;
  text-transform: uppercase;
  opacity: 1;
}

.menu-list-style {
  font: normal normal normal 14px/17px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #000000;
  text-transform: capitalize;
  opacity: 1;
  font-weight: initial;
}

.bottom-box-list {
  background: #fafafa 0% 0% no-repeat padding-box;
  opacity: 1;
}

.drawer-customer-care {
  font: normal normal 600 14px/17px Rubik, sans-serif;
  letter-spacing: 0.7px;
  color: #ea78a5;
  text-transform: uppercase;
  opacity: 1;
}

.drawer-bottom-list {
  display: flex;
  flex-direction: column;
  height: 110px;
  background-color: pink;
  justify-content: space-around;
  //align-items: flex-start;
  margin-left: 4px;
}

.bottom-drawer-style {
  background: #fafafa 0% 0% no-repeat padding-box;
  opacity: 1;
}

.drawer-customer-support-align {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.drawer-a {
  color: #000000;
  &:hover {
    color: #000000;
  }
}
