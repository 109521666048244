@use "../../../Util/c2cConstants/colorConstants" as color;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.abha-aadhar-otp-view {
  .aaov-otp-input-container {
    display: flex;
    justify-content: center;
    margin-top: 16px;
  }

  .aaov-button-container {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  .aaov-aadhar-text {
    text-align: center;
    font-weight: 500;
    font-family: font.$C2C_FONT_FAMILY;
    color: color.$C2C_CYNICAL_BLACK;
    line-height: 22px;
    text-transform: capitalize;
  }

  .aaov-aadhar-otp-subheading {
    text-align: center;
    font-size: font.$PARAGRAPH_REGULAR;
    font-family: font.$C2C_FONT_FAMILY;
    color: color.$C2C_CYNICAL_BLACK;
    line-height: 22px;
    margin: 16px 50px;
  }

  .aaov-aadhar-otp-verification-heading {
    text-align: center;
    font-size: 30px;
    font-family: font.$C2C_FONT_FAMILY;
    font-weight: 600;
    color: color.$C2C_CYNICAL_BLACK;
    line-height: 36px;
    margin: 0px 100px;
  }

  .aavv-terms-checkbox {
    background: #d6dae5 0% 0% no-repeat padding-box;
    width: 27px;
    height: 20px;
    border-radius: 5px;
    margin-right: 10px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  .aavv-terms-checkbox-selected {
    background: #17bae0;
  }

  .aaov-aadhar-check-box-view {
    display: flex;
    align-items: center;
    padding: 20px;
    margin-top: 20px;
  }

  .aaov-mobile-container {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }

  .aaov-mobile-input-container {
    width: 350px;
  }
  .aaov-mobile-input-label {
    margin-bottom: 12px;
    font: normal normal 500 14px/22px font.$C2C_FONT_FAMILY;
    color: color.$C2C_CYNICAL_BLACK;
    text-transform: capitalize;
    text-align: center;
  }

  .aaov-mobile-text {
    margin-top: 12px;
    text-align: center;
    font: normal normal normal 12px/22px font.$C2C_FONT_FAMILY;
    color: #717171;
  }

  .aaov-label-mandatory {
    color: color.$C2C_RED;
  }

  .aaov-mobile-input-error {
    font: normal normal normal 14px/17px font.$C2C_FONT_FAMILY;
    color: #fd1717;
    margin-top: 8px;
  }

  @media screen and (max-width: 768px) {
    .aaov-aadhar-otp-verification-heading {
      margin: 0px 50px;
    }

    .aavv-terms-checkbox {
      width: 30px;
    }
  }
}
