@use "../../../Util/c2cConstants/colorConstants.scss" as color;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.doctor-signin-login-wrapper {
  width: 25vw;
  display: flex;
  align-items: center;
  justify-content: center;

  .doctor-signin-login-box {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .downloadapp-and-call-buttons-wrapper {
    height: 15vh;
  }

  .download-app-text {
    text-align: left;
    font: normal normal normal 20px/24px font.$C2C_FONT_FAMILY;
    letter-spacing: 0px;
    color: #242424;
    opacity: 1;
    margin-bottom: 5px;
  }

  .download-btn-container {
    display: flex;
    height: 50%;
    align-items: center;
  }

  .download-app-btn {
    background: #fff5d3 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 155px;
    height: 50px;
    margin-right: 15px;
    font: normal normal 500 16px/19px font.$C2C_FONT_FAMILY;
    color: #000000;
    cursor: pointer;
    text-decoration: none;
  }

  .apple-play-logo {
    width: 30px;
    height: 25px;
    margin-right: 6px;
  }

  .contact-email-coontainer {
    display: flex;
    height: 25%;
    align-items: center;
    width: 60vh;
  }

  .c2c-contact {
    margin-right: 7px;
    margin-top: 15px;
  }

  .contact-btn-text {
    font: normal normal normal 14px/17px font.$C2C_FONT_FAMILY;
    letter-spacing: 0px;
    color: #1a1a1a;
    cursor: pointer;
    text-decoration: none;
  }

  .c2c-contact-logo {
    margin-right: 7px;
  }

  .login-with-password-otp-container {
    height: auto;
    display: flex;
    flex-direction: column;
  }

  .doctor-login-phone-input {
    height: 75px
  }

  .doctor-login-password-input {
    height: 75px;
  }

  .login-password-and-otp {
    height: 40px;
    display: flex;
    align-items: flex-start;
  }

  .login-password-btn {
    font: normal normal 500 16px/19px font.$C2C_FONT_FAMILY;
    color: color.$C2C_CYNICAL_BLACK;
    cursor: pointer;
  }

  .login-with-otp-btn {
    font: normal normal normal 16px/19px font.$C2C_FONT_FAMILY;
    color: #b2b2b2;
    cursor: pointer;
    padding: 0px 20px 0px 20px;
  }

  .phone-number-otp-input-feild {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

  }

  .create-account-continue-btn-wrapper {
    width: 100%;
    height: 10vh;
    margin: 10px 0px 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .password-input-feild {
    background: color.$C2C_GRAY_5 0% 0% no-repeat padding-box;
    border: 1px solid #efefef;
    border-radius: 5px;
    height: 30%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px 0px 20px;
  }

  .password-number-input {
    border: none;
    background: color.$C2C_GRAY_5 0% 0% no-repeat padding-box;
    font: normal normal normal 20px/24px font.$C2C_FONT_FAMILY;
    color: color.$C2C_EERIE_BLACK;
  }

  .stay-signin-forgot-password {
    height: 15%;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 10px 0px 0px 0px;
  }

  .stay-signin-checkbox-text {
    font: normal normal normal 14px/17px font.$C2C_FONT_FAMILY;
    color: #000000;
    display: flex;
    align-items: center;
  }

  .forgot-password {
    font: normal normal 500 14px/17px font.$C2C_FONT_FAMILY;
    color: #43ceff;
    cursor: pointer;
  }

  .create-account-btn-wrapper {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }

  .dont-have-account-text {
    font: normal normal normal 14px/26px font.$C2C_FONT_FAMILY;
    color: #242424;
  }

  .create-account-btn {
    font: normal normal 500 22px/26px font.$C2C_FONT_FAMILY;
    color: #5dcbfa;
    cursor: pointer;
  }

  .doctor-stay-sign-in-checkbox {
    background: #d6dae5 0% 0% no-repeat padding-box;
    width: 22px;
    height: 22px;
    border-radius: 5px;
    margin-right: 5px;
    justify-content: center;
    display: flex;
    align-items: center;
    transition: ease-in-out 0.2s;
  }

  .doctor-stay-sign-in-checkbox-selected {
    background: #17bae0;
  }
}

@media (max-width: 768px) {
  .doctor-signin-login-wrapper {
    width: 100%;

    .login-with-password-otp-container {
      width: 100%;
      height: auto;
    }

    .download-app-btn {
      width: 46%;
      height: 48px;
    }

    .create-account-btn {
      font: normal normal 500 16px/26px font.$C2C_FONT_FAMILY;
    }

    .doctor-login-phone-input {
      height: 70px
    }

    .doctor-login-password-input {
      height: 70px;
    }

    .download-app-text {
      font: normal normal normal 16px/24px font.$C2C_FONT_FAMILY;

    }

  }
}