.content-1 {
  display: flex;
  flex-direction: row;
  // margin-left: 2%;
  font: normal normal normal 11px/16px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #2f2f2f;
  opacity: 1;
  justify-content: center;
  max-width: 500px;
  align-items: center;

  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    // margin-left: 5.5%;
    padding: 0;
    font: normal normal normal 14px/17px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #2f2f2f;
    opacity: 1;
    justify-content: flex-start;
    align-items: center;
    // max-width: 500px;
  }
}

.content-mob {
  display: none;

  @media screen and (min-width: 992px) and (max-width: 1024px) {
    display: flex;
    width: 200px;
    align-items: baseline;
  }

  @media screen and (min-width: 768px) and (max-width: 991px) {
    display: flex;
    max-width: 250px;
    align-items: baseline;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    text-align: center;
  }
}

.col-xl-6.content-1.col-sm-12 {
  @media screen and (max-width: 767px) {
    display: none;
  }

  @media screen and (max-width: 991px) {
    max-width: 410px;
  }
}

.mob_message {
  display: none;

  @media screen and (max-width: 767px) {
    display: flex;
    margin: 10px 2px;
    width: 370px;
  }
}

.content-mob div {
  font-size: 11px;
}

.content-2 {
  display: none;

  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    font: normal normal normal 14px/17px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #2f2f2f;
    opacity: 1;
    align-items: center;
    // margin-left: 70px;
    // margin-left: 8rem;
    margin-left: 100px;
  }
}

.content-3 {
  opacity: 1;

  display: none;

  @media screen and (min-width: 1200px) {
    display: flex;
    flex-direction: row;
    font: normal normal normal 14px/17px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #2f2f2f;
    opacity: 1;
    align-items: center;
    padding-left: 0;
  }
}

.image-style {
  margin-right: 1%;
  margin-left: 5%;

  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin-left: 10%;
  }
}

.image-style a img {
  width: 22px;

  @media screen and (max-width: 767px) {
    width: 20px;
  }
}

.bg-image-home {
  top: 0;
  height: 92vh;
  background-size: 43rem 70rem;
  background-color: #ffb5d4;
  background-image: url("../../../assets/forhomepage/Mob-top.png");
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;

  // height: auto;
  // min-height: 60vh;
  // max-height: 87vh;
}

.bg-image-home {
  background-color: #ffb5d4;
  background-image: url("../../../assets/forhomepage/Mob-top.png");
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  background-size: auto 100%;
  background-position: 100%;
  background-repeat: no-repeat;
  height: 850px;

  // @media screen and (max-width: 400px) {
  //   background: url("../../assets/Mobile.png") 0 0 / cover no-repeat;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  // }

  @media screen and (min-width: 850px) {
    background: url("../../../assets/forhomepage/bgimage-at-top.png") 0 0 / cover no-repeat;
    background: url("../../../assets/banner2.png") no-repeat;
    // display: flex;
    // flex-direction: column;
    // justify-content: flex-start;
    // width: 100%;
    // // height: 800px;
    // height: 87vh;
    // background-size: 100% 100%;
    background-position: right;
    background-color: #ffb5d4;
    // background-image: url(/static/media/bgimage-at-top.d18a0136.png);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 87vh;
    background-size: 54% 100%;
  }

  @media only screen and (min-width: 851px) and (max-width: 1024px) {
    height: auto;
    min-height: 500px;
  }

  @media screen and (max-width: 500px) {
    background: url("../../../assets/forhomepage/Mob-top.png") 0 0 / cover no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    // background-size: 492px 711px;
    background-position-y: 140px;
    background-color: #ffb5d4;
    background-size: 100% 80%;
    height: 700px;
  }
}

.button-faq {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #ffffff;
  border-radius: 25px;
  opacity: 1;
  display: flex;
  justify-content: center;
  color: #5ad5f2;
  align-items: center;
  width: 170px;
  height: 50px;
}

.button-position {
  display: none;

  @media screen and (min-width: 1000px) {
    display: flex;
    justify-content: flex-end;
    // margin-left: 85px;
  }
}

.pad-button-top {
  @media screen and (min-width: 1000px) {
    margin-top: 15px;
  }
}

.button-homepage {
  display: none;

  @media screen and (min-width: 1000px) {
    height: 50px;
    border: 1px solid #ffffff;
    border-radius: 25px;
    opacity: 1;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 180px;
    margin-left: -16px;
  }
}

.tex-content-homepage a {
  color: #ffffff;
}

.tex-content-homepage {
  display: none;

  @media screen and (min-width: 1000px) and (max-width: 1024px) {
    justify-content: space-evenly;
    min-width: 105px;
    text-align: center;
    align-items: center;
  }

  @media screen and (min-width: 1000px) {
    color: #ffffff;
    display: flex;
    justify-content: left;
    align-items: center;
    font: normal normal medium 13px/1px Rubik, sans-serif;
  }
}

.tex-content-homepage-about-us a {
  color: #ffffff;
}

.tex-content-homepage-about-us {
  display: none;

  @media screen and (min-width: 1000px) {
    color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font: normal normal medium 13px/1px Rubik, sans-serif;
  }
}

.tex-content-homepage-image {
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 240px;

  @media screen and (min-width: 1200px) {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}

.hr-color-homepage {
  background-color: #ffffff;
  z-index: 1100;
}

.hr-position-homepage {
  display: flex;

  justify-content: flex-start;
}

.text-of-nowconsult {
  // text-align: left;
  text-align: center;
  font: normal normal 600 35px/40px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: capitalize;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;

  @media screen and (min-width: 950px) {
    text-align: left;
    font: normal normal 600 45px/50px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    text-transform: capitalize;
    display: flex;
    flex-direction: row;
    margin-left: 10%;
    // margin-top: 12%;
    margin-top: 6rem;
    padding-top: 0;
  }
}

.tap-text {
  width: 800px;
  text-align: center;
  font: normal normal normal 16px/50px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (min-width: 950px) {
    margin-left: 10%;
    text-align: left;
    font: normal normal normal 16px/50px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.bigger-button-in-homepage {
  width: 255px;

  @media screen and (min-width: 950px) {
    margin-left: 10%;
  }
}

.doctor-patient-button-margin {
  height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0px;

  @media screen and (min-width: 950px) {
    // margin-top: 2%;
    // height: 400px;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    // padding-top: 40px;
    padding-top: 18px;
  }
}

.doctor-bb {
  height: 50px;
  background: #17bae0 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  min-width: 130px;
}

.patient-bb {
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 25px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #17bae0;
  min-width: 130px;
}

.tap-height {
  height: 50px;
}

.pap-position {
  width: 600px;
  height: 400px;

  background-color: white;
}

.pap-position1 {
  z-index: 1100;
  position: absolute;
}

.posit-paper {
  display: none;

  @media screen and (min-width: 770px) {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.hor {
  @media screen and (max-width: 770px) {
    display: none;
  }
}

.ver {
  @media screen and (min-width: 770px) {
    display: none;
  }
}

.vertical-posit-participant {
  display: flex;
  flex-direction: row;
  justify-content: center;

  @media screen and (min-width: 770px) {
    display: none;
  }
}

.about-us-color-code {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.align-the-text-over-patient {
  margin-top: 40%;
}

.menuNewHomepage {
  @media screen and (min-width: 1000px) {
    display: none;
  }
}

.mob-loginsection {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media screen and (min-width: 1000px) {
    // display: none;
  }

  @media screen and (min-width: 768px) {
    // right: 0;
    // position: absolute;
  }
}

.mob-loginsection.show {
  display: flex !important;
}

.mob-loginsection div {
  cursor: pointer;
}

.mob-loginsection .login-mob {
  @media screen and (max-width: 768px) {
    position: absolute;
    opacity: 0;
    left: 10px;
  }
}

.mob-loginsection .down_icon {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

// .loginsection_details{
//   cursor: pointer;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-end;
//   align-items: center;
// }

.login-mob {
  display: flex;
  flex-direction: column;
  color: #ffffff;
  justify-content: flex-end;
  padding: 2%;
}

.chat-button {
  box-shadow: 0px 10px 30px #c4f4ff36;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  bottom: 6.5rem;
  right: 2rem;
  background-color: #17bae0;
  background-position: center;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 767px) {
    z-index: 100;
    bottom: 10vh;
    right: 1rem;
  }
}

.top-covid-heading {
  margin: 6px 0;
  justify-content: space-between;
  padding: 0 5% 0 4%;

  @media screen and (max-width: 991px) {
    padding: 0;
    align-items: center;
  }

  @media screen and (max-width: 767px) {
    padding-left: 12px;
    text-align: center;
    flex-wrap: nowrap;
    align-items: baseline;
  }
}

.top-covid-heading a {
  color: #2f2f2f;
}

.home-header {
  &.home-header-center {
    align-items: center;
  }

  @media screen and (max-width: 767px) {
    align-items: center;
    padding: 0px 8% 0;
  }

  @media screen and (min-width: 767px) {
    justify-content: space-between;
    padding: 0 4%;
    align-items: baseline;
  }
}

.menuNewHomepage {
  @media screen and (max-width: 767px) {
    width: 28%;
  }
}

.mob-loginsection {
  @media screen and (max-width: 767px) {
    min-width: 20px;
    max-width: 20px;
    margin-left: 10px;
  }
}

.tex-content-homepage-image {
  @media screen and (max-width: 767px) {
    min-width: 100px;
    padding: 0px;
  }
}

.mob-loginsection .user_icon img {
  @media screen and (max-width: 767px) {
    width: 20px;
  }
}

.mob-loginsection .down_icon img {
  @media screen and (max-width: 767px) {
    width: 10px;
  }
}

.menuNewHomepage,
.mob-loginsection,
.tex-content-homepage-image {
  @media screen and (max-width: 767px) {
    // flex-basis: unset;
    // padding: 0 5px;
  }
}

.header-eamil-icon {
  max-width: unset;
  height: auto;
  width: 25px;
}

.sms_section {
  width: 100%;
  height: 100%;
  background-color: #17bae0;
}

.pdf-canvas,
.pdf_message {
  background-color: rgb(82, 86, 89);
}

.pdf-canvas canvas {
  margin: 50px auto;
  left: 25%;
  // width: 60%;
  position: relative;
}

.pdf_message {
  color: white;
  height: 100%;
  width: 100%;
  /* top: 50%; */
  position: absolute;
}

.pdf_message h3 {
  position: absolute;
  top: 50%;
  padding: 0 8rem;
  font-size: 30px;
  text-align: center;
  font-family: 'Rubik', sans-serif;
  font-weight: 400;
  color: white;
}

.pdf_message .download_img {
  width: 30px;
  height: 25px;
  margin: 10px;
}

@media screen and (max-width: 767px) {
  .pdf_message h3 {
    top: 30%;
    padding: 0 1rem;
    font-size: 25px;
  }

  .pdf-canvas canvas {
    margin: 50px;
    left: 0;
  }
}

.pdf_message img {
  position: absolute;
  top: 0;
  right: 25px;
  z-index: 1;
}

.search_container_for_mobile {
  transition: all 0.3s ease-in-out;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.search_container_for_mobile.openSerchBox {
  display: flex;
  position: absolute;
  left: 0;
  width: 93%;
  z-index: 99;
}

.search_icon_for_mobile {
  justify-content: flex-end;
  display: none;
  padding-top: 3px;

  @media screen and (max-width: 767px) {
    display: flex;
    justify-content: flex-start;
  }
}

.search-wrapper .close_serch {
  @media screen and (max-width: 767px) {
    display: flex;
  }
}

.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: transparent;
}

// .wrapper-login {
//   display: flex;
//   flex-direction: row;
//   align-items: center;
//   background-color: transparent;
//   column-gap: 8px;
// }

.right-menu {
  display: flex;
  flex: 1;
  flex-direction: column;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 16px;
  height: 16px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  border: 3px solid transparent;
  border-radius: 10px;
  box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
  background-clip: content-box;
  // background-color: #818B99;
  //   border: 3px solid transparent;
  //   border-radius: 9px;
  //   background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  // -webkit-box-shadow: inset 0 0 16px rgb(150, 150, 150);
}