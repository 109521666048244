.rectangle-shape-rounded-image-cell {
  height: 100px;
  display: flex;
  background-color: white;
  align-items: center;
  margin: 0px 16px;

  p {
    columns: #2f2f2f;
    font: normal normal medium 14px/20px Rubik, sans-serif;
    letter-spacing: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.ractangle-shape-rounded-image-share-cell {
  height: 100px;
  display: flex;
  align-items: center;
  background-color: #f5f6f7;
  border-radius: 10px;
  margin: 0px 16px 16px 16px;
  padding: 0px 10px;
}

.rectangle-shape-rounded-image-content {
  display: flex;
  width: 100%;
  button {
    padding: 0px;
    padding-right: 5px;
    padding-left: 5px;
    min-width: initial;
  }
  img {
    width: 20px;
  }
}

.rectangle-shape-rounded-image-content-right {
  padding: 10px;
  flex: 1;
}

.content-img-rounded {
  height: 80px;
  width: 80px;
  background-color: gray;
  border-radius: 10px;
  object-fit: cover;
}

.content-img-rounded-pdf {
  height: 80px;
  width: 80px;
  background-color: white;
  border-radius: 10px;
  object-fit: contain;
}

.rectangle-shape-rounded-image-content-title {
  color: #a3a9b7;
  word-break: break-all;
  font: normal normal normal 12px/19px Rubik, sans-serif;
}

.rectangle-shape-rounded-image-content-subtitle {
  columns: #2f2f2f;
  font: normal normal medium 14px/20px Rubik, sans-serif;
  letter-spacing: 0px;
}
.rectangle-shape-rounded-uncheck {
  height: 100%;
  color: #d6dae5;
  margin-top: 10px;
}

.rectangle-shape-rounded-check {
  height: 100%;
  color: #17bae0;
  margin-top: 10px;
}
