.phone-number-input-with-action {
  .pniwa-container {
    height: 55px;
    width: 100%;
    background: #f8f8f8;
    border: 1px solid #efefef;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }

  .pniwa-prefix-container {
    width: 110px;
    min-width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    height: 97%;
    margin-left: 1.5px;
    border-radius: 3px;
    font: normal normal normal 20px/24px Rubik, sans-serif;
  }

  .pniwa-prefix-image {
    width: 39px;
    height: 28px;
    margin-right: 10px;
  }

  .pniwa-input-container {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    width: -webkit-fill-available;
  }

  .pniwa-input {
    width: 100%;
    border: none;
    background-color: transparent;
    font: normal normal normal 20px/24px Rubik, sans-serif;

    // &::placeholder {
    //   //   font: normal normal normal 20px/24px Rubik;
    //   color: #bebebe;
    // }
  }

  .pniwa-suffix-container {
    width: 32px;
    min-width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    cursor: pointer;
  }

  .pniwa-suffix-image {
    width: 20px;
    height: 19px;
    cursor: pointer;
  }

  .pniwa-arrow-enabled {
    cursor: pointer;
  }

  .pniwa-arrow-disabled {
    cursor: not-allowed;
    color: #bebebe;
  }
}


@media (max-width: 768px) {

  .phone-number-input-with-action {

    .pniwa-container {      
      height: 49px;
    }
    
    .pniwa-input { 
      font-size: 14px;
    }
    .pniwa-prefix-container {
      font-size: 14px;
    }
  }
}