@use "../../../../Util/c2cConstants/colorConstants" as color;
@use "../../../../Util/c2cConstants/fontFileConstants.scss" as font;

.abha-patient-profile {
    .apc-patient-card {
        width: 208px;
        background: color.$C2C_WHITE;
        box-shadow: 0px 5px 8px color.$C2C_BLACK;
        border-radius: 10px;
        opacity: 1;
        margin-right: 16px;
        margin-bottom: 16px;
        padding: 24px;
    }

    .appc-patient-card {
        display: flex;
        flex-wrap: wrap;
    }

    .apc-profile-title {
        font: normal normal 500 18px/22px font.$C2C_FONT_FAMILY;
        color: #2E2F30;
        padding-top: 21px;
        padding-bottom: 21px;
    }

    .appc-patient-name {
        padding-top: 12px;
        font: normal normal normal 16px/19px font.$C2C_FONT_FAMILY;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        text-align: center;
    }

    .appc-patient-profile {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }

    .appc-verify-profile-button {
        display: flex;
        justify-content: center;
    }

    .appc-button {
        width: 98px;
        height: 30px;
        background: color.$PRIMARY_COLOR;
        border-radius: 16px;
        opacity: 1;
        color: color.$C2C_WHITE;
        font-size: 13px;
        font-weight: 500;
        font-family: font.$C2C_FONT_FAMILY;
        margin-top: 16px;
    }

    @media screen and (max-width: 768px) {

        .appc-patient-card {
            display: block;
        }

        .apc-patient-card {
            width: 100%;
            margin-right: 8px;
            margin-bottom: 8px;
            padding: 20px 16px;
            display: flex;
            align-items: center;
            box-shadow: 0px 5px 8px #00000008;
        }

        .appc-patient-profile {
            width: 35px;
            height: 35px;
            margin-right: 16px;
            margin-left: 0;
        }

        .appc-patient-details {
            display: flex;
            align-items: center;
            flex: 1;
        }

        .appc-patient-name {
            padding: 0;
        }

        .appc-button {
            font-size: 12px;
            margin: 0;
            margin-left: 10px;
        }
    }
}