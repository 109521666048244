.book-appoint-text {
  color: #1b2027;
  font-size: 16px;
  text-align: center;
}
.mobFollowUp {
  display: none;
}
.procedure__menu {
  align-items: left;
}
.book-appoint-text.appointment_type {
  font-size: 20px;
  line-height: 50px;
}
.patient-side-procedure {
  width: 100%; //50%;
}
.patient-side-procedure .select_procedure {
  width: 100%;
}
.patient-side-procedure .select_procedure div:nth-child(2) span {
  background-color: transparent;
}
.calendar-view > img {
  color: pink;
}

@media (max-width: 767px) {
  .book-appoint-text {
    font-size: 16px;
  }
  .mobFollowUp {
    display: block;
  }
  .consult-type-options {
    flex-direction: column;
  }
  .mobFollowUp.patient-side-procedure {
    width: 100%;
  }
  // .patient-side-procedure {
  //   display: none;
  // }
  .patient-side-procedure .select_procedure {
    width: 100%;
  }
}
.modal-dialog.modal-lg.video-modal {
  width: 800px;
}
.video-modal .video_content {
  margin: 10px 0px;
}
.video-modal .video-close {
  margin-top: -12px;
}
.booking-details {
  letter-spacing: 0px;
  color: #1b2027;
  font-size: 16px;
  font-weight: 400;
  margin-top: 6px;
}

.patient-conatiner-div {
  margin-top: 0px;
}

.doctor-profile-divider .doctor-profile-div {
  background: transparent 0% 0% no-repeat padding-box;
  border: 2px solid #ffffff;
  height: 95px;
  width: 95px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doctor-profile-initial {
  font-size: 20px;
  background: #f969a4;
  color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Rubik, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

.doctor-name {
  font-size: 25px;
  font-weight: 600;
  color: #1b2027;
}
.doctor-name.left_side_doc {
  font-size: 20px;
  word-break: inherit;
}
.doctor-prof {
  font-size: 18px;
  color: #1b2027;
  text-transform: capitalize;
}

.doctor-reg-no {
  color: #707070;
  font-size: 12px;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  width: 95%;
}
.for_profile.doctor-reg-no {
  align-items: initial;
  flex-direction: column;
}
.for_profile.doctor-reg-no p {
  margin: 0px;
}
// .for_profile.doctor-reg-no p .MuiTouchRipple-root{
//   top: -30px !important;
// }
.doctor-reg-no .MuiButton-root {
  min-width: 30px;
}
.doctor-reg-no button {
  width: 30px;
  height: 30px;
  padding: 0;
}
.doctor-reg-no img {
  width: 20px;
  height: 20px;
}
.verification-status {
  font-size: 10px;
  display: flex;
  align-items: center;
}
.verification-status img {
  width: 15px;
  margin-right: 5px;
}
.doctor-profile-divider {
  background: #ffffff;
  // padding: 24px 20px;
  border-radius: 12px;
  //   height: calc(100% - 15px);
  height: 100%;
}
.doctor-profile-right-section {
  background: #ffffff;
  border-radius: 2px;
  .right-section-title-box {
    // padding: 20px;
    h1 {
      line-height: 24px;
    }
  }
  .doctor-profile-right-section-calender {
    position: absolute;
    margin-left: -25px;
    z-index: 10;
  }
}

.add-new-patient-modal {
  &.show.fade {
    opacity: 1;
  }
  .modal-header {
    margin-bottom: 16px;
  }
  .modal-content {
    padding: 0 !important;
  }
  .modal-body {
    padding: 16px !important;
  }
}
.thankyou-profile-img {
  border-radius: 50%;
  width: 200px;
  height: 200px;
  max-width: 200px;
}

.thanks-section-main {
  width: 75%;
  background: #fff;
  margin: 0 auto;
  padding: 20px 20px;
  border-radius: 12px;
  text-align: center;
  .inner {
    display: flex;
    text-align: left;
    align-items: center;
    & > div {
      padding: 8px 16px;
    }
    p {
      margin: 0;
    }
  }
  .otp-btn {
    width: 220px;
  }
}
.patients .col-md-10.patient_list .inner {
  ::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
  }
}
.col-md-10.patient_list {
  padding: 0;
  flex: none;
  max-width: calc(100% - 160px);
  .inner {
    padding-bottom: 6px;
    &::-webkit-scrollbar {
      height: 16px; //4px;
    }
    .card-deck {
      overflow-x: auto;
      max-width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }
    @media (max-width: 767px) {
      .card {
        p {
          text-align: left;
          width: 50%;
          margin: 0;
        }
      }
    }
  }
}

.width-style {
  min-width: -webkit-fill-available;
}

.patient-detail-date-picker {
  width: 100%;
  div {
    width: 100%;
  }
}

.patient-doctor-profile,
.doctor-qualification-details {
  padding: 24px 10px 0px 10px;
}
.cal-date.ml-1 {
  font-size: 16px;
}
.week-day,
.cal-date {
  font-size: 15px;
  // font-weight: 600;
}

.cal-date {
  color: #17bae0;
  border-bottom: 1px dotted;
}

.cal-width {
  width: 100%;
}

.book-till {
  font-size: 18px;
  font-weight: 600;
}

.valid-till {
  font-size: 16px;
  font-weight: 500;
}

.existing-user-booking-screen {
  .existing-patient-list::-webkit-scrollbar {
    display: block;
  }
}

@media (max-width: 767px) {
  .patient-detail-date-picker {
    display: flex;
    flex-direction: column;
  }
  .patient-detail-date-picker span {
    margin-top: 10px;
  }
  .doctor-profile {
    padding: 0 !important;
  }
  .doctor-profile-right-section {
    border-radius: 0px;
  }
  .patient-doctor-profile {
    .media {
      display: flex;
      text-align: center;
      .doctor-profile-div {
        margin: 0 auto 16px !important;
        width: fit-content;
      }
      .media-body {
        width: 100% !important;
      }
    }
  }
  .thanks-section-main {
    width: 100%;
  }
  .thanks-section-main .inner {
    flex-wrap: wrap;
    justify-content: center;
  }
  .pro-content-col {
    padding: 20px 0;
  }
  .mobile-box-related {
    padding: 0 15px;
    .box-title {
      margin-left: 0 !important;
    }
  }
  .mobile-screen-checkbox {
    width: calc(100% - 30px);
    margin: 0 auto;
    label {
      font-size: 14px;
    }
  }
  .patient-content-col .form-group {
    margin-bottom: 20px;
  }
}

@media (max-width: 767px) {
  .week-day,
  .cal-date,
  .book-till,
  .valid-till {
    font-size: 16px;
  }
  .cal-width {
    width: 100%;
    min-width: initial;
  }
  .cal-width .cal-date {
    min-width: 150px;
  }
  .booking-date {
    justify-content: center;
  }
}
