@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.error-container {
  width: 100%;
  background: #f823231a 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 8px 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 10px 0px;

  .error-heading {
    text-align: left;
    font: normal normal 500 20px/24px font.$C2C_FONT_FAMILY;
    letter-spacing: 0px;
    color: #f82323;
  }

  .error-content {
    font: normal normal normal 14px/17px font.$C2C_FONT_FAMILY;
    letter-spacing: 0px;
    color: #0d0d0d;
  }
}

.error-with-message {
  height: 100px;
}

.error-without-message {
  .error-heading {
    text-align: left;
    font: normal normal 500 16px/24px font.$C2C_FONT_FAMILY;
    letter-spacing: 0px;
    color: #f82323;
  }
}
