@use "../../../Util/c2cConstants/colorConstants.scss" as color;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.home-page-partners-container {
  padding: 100px 0px 50px 0px;

  .home-page-partners-title {
    font: normal normal 500 30px/38px font.$C2C_FONT_FAMILY;
    letter-spacing: 0px;
    color: color.$C2C_DARK_GRAY;
    margin-bottom: 50px;
    padding: 0px 165px;
  }

  .home-page-partners-content-container {
    display: flex;
    position: relative;
    width: 100vw;
  }

  .home-page-partners-content {
    margin-right: 30px;
  }

  .home-page-partners-logo {
    width: 160px;
    height: 99px;
    border: 1px solid color.$C2C_BLEACHED_SILK;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin: 12px 20px;
    }
  }

  .home-page-partners-name {
    font: normal normal normal 12px/17px font.$C2C_FONT_FAMILY;
    color: color.$C2C_DARK_GRAY;
    width: 160px;
    overflow-wrap: break-word;
    margin-top: 10px;
  }

  .home-page-partners-slider {
    position: relative;
    width: 100%;
    display: grid;
    place-items: center;
    overflow: hidden;
  }

  .home-page-partners-slider::before,
  .home-page-partners-slider::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 25%;
    z-index: 2;
    pointer-events: none;
  }
  .home-page-partners-slider::before {
    left: 0;
    top: 0;
  }
  .home-page-partners-slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }

  .home-page-partners-slide-track {
    width: calc(190px * 20);
    display: flex;
    animation: scroll 30s linear infinite;
    justify-content: space-between;
  }

  .home-page-partners-slide {
    min-width: 160px;
    display: grid;
    place-items: center;
    transition: 0.5s;
    margin-left: 30px;
  }

  .home-page-partners-slide-track:hover {
    animation-play-state: paused;
  }

  @keyframes scroll {
    0% {
      transform: translateX(0px);
    }
    100% {
      transform: translateX(calc(-190px * 19));
    }
  }
}

@media (max-width: 768px) {
  .home-page-partners-container {
    padding: 40px 30px;
    margin-top: 0px;

    .home-page-partners-title {
      font-size: font.$HEADING_4;
      margin-bottom: 30px;
      padding: 0px;
    }
  }
}

@media only screen and (min-width: 820px) and (max-width: 1179px) {
  .home-page-partners-container {
    padding: 60px 30px 50px 30px;
  }
}
