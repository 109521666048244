@use "../../../Util/c2cConstants/colorConstants.scss" as color;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.home-page-feature-container {
    height: 637px;
    width: 100vw;
    display: flex;
    align-items: flex-end;
    margin-top: 45px;

    .img-explore-our-features-container {
        height: 606px;
        width: 100vw;
        display: flex;
        position: relative;
        align-items: flex-end;
    }

    .image-background-wrapper {
        height: 100%;
        width: 27%;
        background-color: color.$C2C_SAGA_GREEN;
    }

    .image-background-wrapper-sec {
        width: 73%;
        background-color: color.$C2C_PALE_IVORY;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-right: 200px;
    }

    .female-doctor-image {
        height: 637px;
        width: 594px;
        position: absolute;
        margin-left: 100px;
    }

    .explore-our-features-container {
        width: 420px;
        height: 520px;
    }

    .explore-our-features-heading {
        font: normal normal 500 25px/38px font.$C2C_FONT_FAMILY;
        text-align: left;
        letter-spacing: 0px;
        color: color.$C2C_CYNICAL_BLACK;
        opacity: 1;
        margin-bottom: 10px;
    }

    .explore-our-features-btns-wrapper {
        width: 410px;
        height: 467px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .explore-our-features-btn {
        width: 410px;
        height: 139px;
        display: flex;
        transition: box-shadow 0.2s;
        background-color: color.$C2C_WHITE;
        border-radius: 2px;
    }

    .explore-our-features-btn:hover {
        box-shadow: 0px 10px 12px 2px rgba(134, 133, 133, 0.2);
    }

    .explore-feature-btn-icon-wrapper {
        width: 100px;
        background-color: #17BAE00D;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .explore-feature-btn-text-wrapper {
        width: 310px;
        background-color: color.$C2C_WHITE;
        height: 100%;
        padding: 20px 10px 20px 15px;
    }

    .explore-feature-btn-icon {
        height: 50px;
        width: 50px;
    }

    .explore-feature-btn-title {
        font: normal normal 500 16px/23px font.$C2C_FONT_FAMILY;
        letter-spacing: 0px;
        color: color.$C2C_CYNICAL_BLACK;
        opacity: 1;
        margin-bottom: 5px;
    }

    .explore-feature-btn-text {
        font: normal normal normal 12px/17px font.$C2C_FONT_FAMILY;
        letter-spacing: 0px;
        color: color.$C2C_CYNICAL_BLACK;
        opacity: 1;
    }
}

.feature-points-container-wrapper {
    position: relative;
    margin-top: 5px;
}

.feature-points-container {
    padding: 0px 173px 0px 165px;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: space-between;


    .feature-points-content-wrapper {
        width: 255px;
        height: 195px;
        margin-right: 10px;
    }

    .feature-icon-title-wrapper {
        height: 66px;
        width: 100%;
        display: flex;
    }

    .feature-text-wrapper {
        height: 129px;
        width: 100%;
        padding: 10px 0px 0px 0px;
    }

    .feature-icon-wrapper {
        width: 66px;
        height: 66px;
        background-color: color.$C2C_BLUE;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
    }

    .feature-title {
        padding: 0px 0px 0px 10px;
        text-align: left;
        font: normal normal 500 16px/23px font.$C2C_FONT_FAMILY;
        letter-spacing: 0px;
        color: color.$C2C_CYNICAL_BLACK;
        opacity: 1;
        width: 189px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }


    .feature-text {
        text-align: left;
        font: normal normal normal 12px/19px font.$C2C_FONT_FAMILY;
        letter-spacing: 0px;
        color: color.$C2C_CYNICAL_BLACK;
        opacity: 1;
        font-size: 12px;
    }

    .feature-icons-image {
        height: 38px;
        width: 35px;
    }
}


.request-demo-container {
    height: 171px;
    width: 100vw;
    display: flex;
    align-items: flex-end;
    margin-top: 20px;

    .request-demo-content-wrapper {
        height: 120px;
        width: 100%;
        background: transparent linear-gradient(281deg, #FAECD6 0%, #B5D5C5 100%) 0% 0% no-repeat padding-box;
        padding: 0px 165px 0px 110px;
        display: flex;
        align-items: inherit;
    }

    .request-demo-phone-img {
        width: 253px;
        margin-bottom: 10px;
    }

    .request-demo-heading-btn-wrapper {
        height: 100%;
        width: 80%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .request-demo-heading {
        text-align: left;
        font: normal normal 500 30px/25px font.$C2C_FONT_FAMILY;
        letter-spacing: 0px;
        color: color.$C2C_DARK_GRAY;
        opacity: 1;
    }

    .request-demo-btn {
        width: 255px;
        height: 56px;
        background-color: color.$C2C_DARK_GRAY;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 2px;
    }

    .request-demo-btn-text {
        text-align: left;
        font: normal normal 500 20px/25px font.$C2C_FONT_FAMILY;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        text-decoration: none;
    }

    .request-demo-icon {
        margin-left: 15px;
    }
}

.rated-by-doctors-and-patients-container {
    width: 100vw;
    height: 250px;
    display: flex;
    align-items: flex-end;
    padding: 0px 200px 0px 165px;
    margin: 15px 0px 25px 0px;

    .rated-by-doctors-and-patients-wrapper {
        height: 176px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .rated-by-doctors-and-patients-heading {
        text-align: left;
        font: normal normal 500 30px/38px font.$C2C_FONT_FAMILY;
        letter-spacing: 0px;
        color: color.$C2C_DARK_GRAY;
        opacity: 1;
    }

    .rating-logo-star-wrapper {
        height: 90px;
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .company-logo-star-box {
        width: 24%;
        height: 100%;
        display: flex;
        flex-direction: row;
        border-radius: 2px;
    }

    .company-logo-wrapper {
        width: 65%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid color.$C2C_BLEACHED_SILK;
    }

    .star-rating-wrapper {
        width: 35%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid color.$C2C_BLEACHED_SILK;
        padding: 10px;

    }
}


@media (max-width: 768px) {


    .home-page-feature-container .img-explore-our-features-container {
        flex-direction: column-reverse
    }

    .home-page-feature-container .image-background-wrapper-sec {
        width: 100%;
        justify-content: flex-start;
        height: 100%;
        margin-top: 20px;
    }

    .home-page-feature-container .image-background-wrapper {
        width: 100%;
        height: 40%;
    }

    .home-page-feature-container {
        height: 1000px;
        margin-top: 0px;
    }

    .home-page-feature-container .img-explore-our-features-container {
        height: 100%;
        width: 100vw;
        display: flex;
        flex-direction: column-reverse;
    }

    .home-page-feature-container .explore-our-features-container {
        width: 420px;
        height: 655px;
    }

    .home-page-feature-container .explore-our-features-btn {
        width: 88vw;
    }

    .home-page-feature-container .explore-our-features-btns-wrapper {
        width: 100vw;
        align-items: center;
        padding-right: 10px;
    }

    .home-page-feature-container .explore-our-features-heading {
        margin: 0px 20px 20px 20px;
    }

    .home-page-feature-container .female-doctor-image {
        height: 385px;
        width: 390px;
    }

    .feature-points-container {
        padding: 0px 30px 0px 30px;
        overflow-x: auto;
        position: relative;
    }

    .feature-points-container::-webkit-scrollbar {
        display: none;
    }

    .feature-points-container .feature-points-content-wrapper {
        margin: 0px 25px 20px 0px;
    }

    .request-demo-container {
        height: 250px;
    }

    .request-demo-container .request-demo-content-wrapper {
        height: 225px;
        padding: 0px;
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .request-demo-container .request-demo-heading-btn-wrapper {
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 25px;
    }

    .request-demo-container .request-demo-heading {
        font-size: 20px;
        padding: 5px
    }

    .request-demo-container .request-demo-phone-img {
        position: absolute;
        left: 20%;
        bottom: 120px;
    }

    .rated-by-doctors-and-patients-container {
        padding: 0px 30px 0px 30px;
        margin-bottom: 40px;
        align-items: center;
    }

    .rated-by-doctors-and-patients-container .rated-by-doctors-and-patients-heading {
        font: normal normal 500 23px/38px font.$C2C_FONT_FAMILY;
    }

    .rated-by-doctors-and-patients-container .rating-logo-star-wrapper {
        flex-wrap: wrap;
        height: 75px;
    }

    .rated-by-doctors-and-patients-container .company-logo-star-box {
        width: 47%;
        margin: 4px;
        height: 80%;
    }

    .company-logo-img {
        max-width: 80%;
    }

    .star-rating-img {
        max-width: 80%;
    }

    .rated-by-doctors-and-patients-container .company-logo-wrapper {
        width: 60%;
    }

    .rated-by-doctors-and-patients-container .star-rating-wrapper {
        width: 40%;
    }

    .scroll-right-btn {
        position: absolute;
        left: 88%;
        top: -2%;
        height: 356px;
        width: 50px;
        display: flex;
        box-shadow: -16px -8px 20px 0px color.$C2C_WHITE inset;
        opacity: 1;
    }

    .scroll-right-arrow-icon {
        width: 25px;
    }
}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .home-page-feature-container .explore-our-features-heading {
        margin: 0px 20px 20px 40px;
    }

    .home-page-feature-container .explore-our-features-btns-wrapper {
        width: 100vw;
        align-items: center;
    }

    .home-page-feature-container .explore-our-features-btn {
        width: 90%;
    }

    .home-page-feature-container .img-explore-our-features-container {
        align-items: center;
    }

    .home-page-feature-container .female-doctor-image {
        height: 385px;
        width: 390px;
        margin-left: 0px;
    }

    .scroll-right-btn {
        left: 94%;
    }

    .request-demo-container .request-demo-phone-img {
        left: 34%;
    }

    .home-page-feature-container .explore-feature-btn-text-wrapper {
        width: 65%;

    }

    .home-page-feature-container .explore-feature-btn-title {
        font: normal normal 500 14px/23px Rubik, sans-serif;
    }

    .home-page-feature-container .explore-feature-btn-text {
        font: normal normal normal 13px/17px Rubik, sans-serif;
    }

}

@media only screen and (min-width: 820px) and (max-width: 1179px) {

    .feature-points-container {
        padding: 0px 30px 0px 30px;

    }

    .request-demo-container .request-demo-content-wrapper {
        padding: 0px 30px 0px 30px;
    }

    .rated-by-doctors-and-patients-container {
        padding: 0px 30px 0px 30px;
    }

    .home-page-feature-container {
        height: 465px;
    }

    .home-page-feature-container .img-explore-our-features-container {
        height: 450px;

    }

    .home-page-feature-container .female-doctor-image {
        height: 350px;
        width: 350px;
        margin-left: 5px;
        position: absolute;
        bottom: 0;
    }

    .home-page-feature-container .image-background-wrapper-sec {
        padding-right: 30px;
    }

    .home-page-feature-container .explore-our-features-btns-wrapper {
        width: 409px;
        height: 330px;
    }

    .home-page-feature-container .explore-our-features-container {
        width: 423px;
        height: 364px;
    }

    .home-page-feature-container .explore-our-features-btn {
        width: 370px;
        height: 96px;

    }

    .home-page-feature-container .explore-our-features-btn {
        width: 391px;
        height: 103px;
    }

    .home-page-feature-container .explore-feature-btn-text-wrapper {
        width: 310px;
        height: 100%;
        padding: 7px 10px 4px 15px;
    }

}