/*[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: #060606; font-size:14px;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height:20px;
    border: 1px solid #989898;
    border-radius: 100%;
    background: #989898;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width:20px;
    height:20px;
    background: #eeae01;
    position: absolute;
    top: 0px;
    left:0px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}*/
/*.radio-col:before {
	content: "\f007";
    font: normal normal normal 14px/1 FontAwesome;
    left: 7px;
    position: absolute;
    z-index: 999;
    top: 6px; color:#eeeeee;

}
.radio-col2:before {
	content: "\f067";
    font: normal normal normal 14px/1 FontAwesome;
    left: 7px;
    position: absolute;
    z-index: 999;
    top:8px; color:#eeeeee;

}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
.radio-col{ width:50%; float:left; position:relative;}
.radio-col2{ width:50%; float:left; position:relative;}*/

input[type="radio"]:after {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  top: -4px;
  left: -2px;
  position: relative;
  background-color: #d1d3d1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 6px solid #d1d3d1;
}

input[type="radio"]:checked:after {
  border-color: #17bae0;
  background-color: #fff;
}

.patientDetails-gender-label-align {
  margin-top: 10px;
  margin-left: 10px;
}

.patient-content-col h3 {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 40px;
  color: #060606;
}
.patient-content-col h4 {
  text-align: center;
  font-size: 20px;
  margin-bottom: 40px;
  color: #eeae01;
}
.patient-content-col h5 {
  margin-bottom: 20px;
  font-size: 22px;
  display: flex;
  align-items: center;
}
.patient-content-col h4 span {
  color: #060606;
}
.patient-content-col label {
  font-size: 18px;
  color: #1b2027;
  font-weight: 600;
  line-height: 22px;
  margin-bottom: 20px;
  text-transform: uppercase;
}
.radio-col3 {
  width: 50%;
  float: left;
}
.patient-content-col .form-group {
  margin-bottom: 40px;
}

.patient-content-col input[type="radio"] {
  width: auto !important;
}
.patient-content-col input[type="radio"]:checked {
  width: auto !important;
}

.color-pink {
  color: #f969a4 !important;
}

input:focus {
  outline: none;
}

input[type="file"] {
  background: #f5f6f7 !important;
}

.upload-input {
  background-color: #fff;
  border-bottom: 1px solid #c6c6c6 !important;
  border-radius: 0;
  padding: 0.25em 0 0.25em 120px;
  padding-right: 1.5em;
  width: 250px;
  font-size: 18px;
  border: none;
}
.input-date {
  background: url("../images/calendar.png") #f5f6f7 no-repeat center right !important;
  /*border-bottom: 1px solid #c6c6c6 !important;*/
  border-radius: 0;
  padding: 0.25em 0;
  /*padding-right: 1.5em;*/
  width: 100%;
  font-size: 22px;
  border: none;
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url("../images/down-arrow.png") #f5f6f7 no-repeat center right;
  /*border-bottom: 1px solid #c6c6c6 !important;*/
  border-radius: 0;
  padding: 0.25em 10px;
  /*padding-right: 1.5em;*/
  width: 100%;
  font-size: 18px;
  border: none;
}
.custom-file-uploader {
  position: relative;
}
.custom-file-uploader input[type="file"] {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  width: 50%;
  height: 100%;
  opacity: 0;
  cursor: default;
}

/*[type="radio"] + label::before, [type="radio"] + label::after {
  border-radius: 50%;
}
[type="radio"]:checked:active + label::before, [type="radio"]:checked:focus + label::before {
  -webkit-animation: none;
          animation: none;
  -webkit-filter: none;
          filter: none;
  -webkit-transition: none;
  transition: none;
}
[type="radio"]:checked + label::before {
  -webkit-animation: none;
          animation: none;
  background-color: #fff;
}
[type="radio"]:checked + label::after {
  -webkit-transform: scale(1);
          transform: scale(1);
}*/

@-webkit-keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px #900;
  }
}

@keyframes borderscale {
  50% {
    box-shadow: 0 0 0 2px #900;
  }
}
.checkbox-col {
  margin-bottom: 70px !important;
}
.checkbox-col input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkbox-col label {
  position: relative;
  cursor: pointer;
}

.checkbox-col label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid #666;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}
.checkbox-col label a {
  font-weight: 700;
  color: #eeae01;
}
.checkbox-col label {
  color: #000;
  line-height: 38px;
}
.upload-btn {
  position: absolute;
  z-index: 999;
  background-color: #ccc;
  left: 0px;
  width: 100px;
  text-align: center;
}
.pattern-img {
  width: 100%;
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.pattern-img img {
  width: 100%;
}
.checkbox-col input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #0079bf;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.patient-form {
  position: relative;
  z-index: 999999;
}
.cancel-btn {
  width: 150px;
  float: right;
  margin-right: 15px;
  border-radius: 40px;
  background: #454545;
  font-style: 18px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  line-height: 50px;
  height: 50px;
  border: none;
}
.save-btn {
  width: 150px;
  float: right;
  margin-right: 15px;
  border-radius: 40px;
  background: #eeae01;
  font-style: 18px;
  text-align: center;
  color: #fff;
  font-weight: 700;
  line-height: 50px;
  height: 50px;
  border: none;
}

.col-md-8.patient-content-col {
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: initial;
  border-radius: 10px;
  /* padding: 60px 75px; */
  margin: 0 0 0 3%;
  padding: 2rem 0;
  max-width: 63.66%;
}
.patient-details-wraper {
  padding-bottom: 2rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #8888880d;
}

.patient-content-col label {
  font-size: 14px;
  color: #1b2027;
  line-height: 22px;
  margin-bottom: 6px;
  font-weight: 600;
}

@media screen and (max-width: 960px) {
  .patient-content-col {
    padding: 20px 16px;
  }
}

.rw-multiselect-taglist {
  display: inline-block !important;
}

.rw-multiselect-taglist {
  font-size: 12px !important;
}

.rw-multiselect .rw-input-reset {
  width: 100% !important;
}

.rw-select {
  background: url("../images/down-arrow.png") #f5f6f7 no-repeat center right !important;
}

.address_bg {
  background-color: #f5f6f7 !important;
  min-height: 50px !important;
}

.pincode_puropse_border {
  border: 1px solid #ced4da !important;
}

.container.patient-details-wrapper {
  max-width: 100%;
  margin-bottom: 4rem;
}
.patient-content-col .patient-details-heading {
  margin: 0;
  padding: 1.5rem 6% 0.5rem;
  width: 100%;
  border-bottom: 1px solid #ddd;
}
.patient-content-col .patient-details-heading h5 {
display: flex;
align-items: center;
}
.patient-content-col .patient-details-heading h5 .pdw-back-icon {
  cursor: pointer;
  filter: invert(1);
  transform: rotate(180deg); margin-right: 10px;
  width: 16px;
height: 16px;

}
.patient-content-col .patient-details-inputs {
  padding: 0 6%;
}
.patient-content-col .patient-details-radio-option {
  margin-bottom: 0;
}
.patient-content-col .react-date-picker__inputGroup__input {
  width: auto;
  padding: 3px;
  font-size: 16px;
  text-align: center;
}
.gender-options {
  display: flex;
}
.gender-options input {
  cursor: pointer;
}
input.agreement-checkbox {
  width: 22px;
  height: 22px;
  margin: 0 10px 0 0;
  position: unset;
  cursor: pointer;
}
.agreement-outer-row {
  padding: 0 6%;
  margin: 0;
}
.agreement-outer {
  display: flex;
  align-items: center;
}
label.agreement-text {
  margin-bottom: 0;
  color: #606060;
  text-transform: capitalize;
 
}
.patient-details-inputs .row {
  margin-top: 32px;
}
.form-check-inline .form-check-input.patient-details-radio-btn {
  margin-right: 12px;
}
.form-check-input.patient-details-radio-btn:after,
.form-check-input.patient-details-radio-btn:checked:after {
  top: -5px;
  cursor: pointer;
}
.drug-allergy-wraper,
.medication-wraper {
  width: 100%;
}
.drug-allergy-input,
.medication-input {
  margin-bottom: 6px;
  padding: 10px 10px;
}
.habit-wraper {
  height: auto;
  padding: 12px 15px;
  margin-top: 16px;
}
.habit-wraper .rw-multiselect .rw-select {
  display: table-cell;
  width: 44px;
}
.review-confirm-wraper {
  background-color: #fff;
  padding: 20px 65px;
  bottom: 0%;
  height: 80px;
  width: 100%;
  position: fixed;
  z-index: 9999;
  box-shadow: 0px 0px 20px #8888881f;
}
.review-confirm-wraper .row {
  height: 100%;
}
.review-confirm-wraper button {
  height: 45px;
  float: right;
  width: auto;
  padding: 0 65px 0 48px;
}
.review-confirm-wraper .book-appoint-arrow {
  position: absolute;
  right: 102px;
  top: 36.5px;
}

@media (min-width: 320px) and (max-width: 767px) {
  .col-md-8.patient-content-col {
    margin: 0 15px 3.5rem;
    padding: 0;
    max-width: unset;
  }
  .patient-content-col .patient-details-heading {
    margin: 0;
    padding: 1rem 6% 0;
  }
  .patient-content-col .patient-details-inputs {
    padding: 1rem 5% 0;
  }
  .patient-details-inputs .row {
    margin-top: 0;
  }
  .agreement-text{
    display: inline;
  }
  .patient-details-textfield {
    margin-bottom: 1rem;
  }
  .patient-content-col label {
    margin-bottom: 10px;
  }
  .agreement-outer-row {
    padding: 0 15px;
  }
  .patient-details-radio-option {
    margin-bottom: 0;
  }
  .habit-wraper,
  .drug-allergy-outer,
  .medication-outer {
    margin: 10px 15px 20px;
  }
  .agreement-outer {
    align-items: flex-start;
    display: block;
  }
  .review-confirm-wraper button {
    float: unset;
    padding: 0 55px 0 48px;
  }
  .review-confirm-wraper .book-appoint-arrow {
    right: 115px;
  }
}

.patient-inputs-form {
  background: #f5f6f7 0% 0% no-repeat padding-box;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  height: 45px;
  padding: 0 13px;
}

.danger-text {
  text-align: left;
  letter-spacing: 0px;
  color: #e03535;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
}

.patient-details-user-whatsapp-container .whatsapp_checkbox {
  margin-bottom: 0;
  width: 15px;
}

.patient-details-user-whatsapp-container .whatsapp_ntfcn {
  color: #1b2027;
}

.caregiver-you-someone-btns-wrapper {
  display: flex;
  text-align: left;

  .caregiver-you-btn {
    border-radius: 4px;
    padding: 0px 0px 0px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .caregiver-someone-btn {
    border-radius: 4px;
    padding: 0px 0px 0px 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 15px;
  }

  .caregiver-buttons {
    padding: 7px 0px;
  }

  .btn-icons {
    height: 20px;
    margin: 0px 7px 0px 0px;
  }

  .person-btn-icon {
    height: 25px;
    margin: 0px 7px 0px 0px;
  }

  .btn-icon-gray {
    height: 25px;
    margin: 0px 7px 0px 0px;
    filter: invert(82%) sepia(0%) saturate(1%) hue-rotate(346deg)
      brightness(150%) contrast(92%);
  }

  .btn-plus-icon-gray {
    height: 20px;
    margin: 0px 7px 0px 0px;
    filter: invert(13%) sepia(0%) saturate(1%) hue-rotate(346deg)
      brightness(140%) contrast(92%);
  }

  .checked-icon {
    height: 16px;
  }

  .btnBorderBlue {
    border: 1px solid #17bae0;
  }

  .btnBorderGray {
    border: 1px solid #b2b2b2;
  }

  .check-icon-container {
    width: 20px;
    height: 100%;
    margin-left: 10px;
    padding: 2px 0px 0px 0px;
  }
}
