.swal2-container {
  z-index: 2000;
}
.presciption-component-main-header {
  cursor: pointer;
  color: #f969a4;
  font-size: medium;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.animation-rotation {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.primary-cta-button {
  min-width: 100px;
  height: auto;
  background-color: #17bae0;
  color: white;
  border: none;
  transition: 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    background-color: darken($color: #17bae0, $amount: 5%);
  }
}
.secondary-button {
  min-width: 100px;
  height: auto;
  background-color: white;
  color: #17bae0;
  border: 1px solid lightgray;
  transition: all 0.3s cubic-bezier(0.075, 0.82, 0.165, 1);

  &:hover {
    background-color: darken($color: #17bae0, $amount: 5%);
    color: white;
  }
}

.bottom-action-row {
  z-index: 9999;
  position: fixed;
  bottom: 0px;
  left: 0px;
  height: auto;
  padding-top: 2vh;
  padding-bottom: 2vh;
  background-color: #eaeaea;
  width: 100vw;
  align-content: center;
  align-items: center;

  .c2c-secondary-button {
    margin: 0px 2vw;
    color: #17bae0;
    background-color: white;
    border: none;
    border-radius: 15%;
    transition: 0.3s;

    &:hover {
      color: white;
      background-color: #17bae0;
    }
  }

  .c2c-primary-button {
    margin: 0px 2vw;
    color: white;
    background-color: #17bae0;
    border: none;
    border-radius: 15%;
    transition: 0.3s;

    &:hover {
      transform: scale(1.03, 1.03);
      color: white;
      background-color: #17bae0;
    }
  }
}
.prescription-section-title {
  color: black;
}

.prescription-col-float-right {
  color: #56cde8;
  text-align: right;
}

.presctiptionClass {
  input {
    padding: 14px;
    // border-radius: 5px;
    // border: 0.5px solid #D6DAE5;
    // background-color: white;

    &::placeholder {
      color: #2f2f2f;
      opacity: 0.5;
    }
  }

  & .toggleButonGroup {
    border-radius: 0px;
    float: right;

    .toggleButtonItem {
      padding: 5px;
      vertical-align: middle;
      text-align: center;
      background-color: white;
      color: #2f2f2f;
      opacity: 0.5;
      border: 2px solid #17bae0;
      border-radius: 0px;
      text-transform: capitalize;

      &.focus {
        box-shadow: none;
      }

      &.active {
        vertical-align: middle;
        text-align: center;
        text-transform: capitalize;
        background-color: #17bae0 !important;
        opacity: 1;
        color: white !important;
        border: none;
        border-radius: 0px;
      }
    }
    @media only screen and (max-width: 1200px) {
      margin-top: 10px;
      width: 100%;
      padding: auto;
    }
  }
}

.customDatePickerWidth,
.customDatePickerWidth > div.react-date-picker,
.customDatePickerWidth > div > div.react-date-picker__wrapper,
.customDatePickerWidth > div > div.react-date-picker__inputGroup,
.customDatePickerWidth > div > div.react-date-picker__inputGroup input {
  width: 100%;
}

.customDatePickerWidth
  > div.react-date-picker
  > span
  > div.react-date-picker__calendar {
  width: 100%;
}

.followupdate {
  background-color: #dff6fc;
  color: #17bae0;
  font-size: 14px;
  border-radius: 50px;

  @media only screen and (max-width: 1200px) {
    font-size: 13px;
  }
}

.deletefollowup {
  border: none !important;
}

.input_container {
  background: rgb(245, 246, 247);
  display: flex;
  width: inherit;
  border-radius: 0.25rem;
  border: 1px solid #ced4da;
  input {
    width: 94%;
    background: rgb(245, 246, 247);
  }
  .form-control {
    border-color: transparent !important;
  }
  .form-control:focus {
    outline: none;
    border-color: transparent !important;
    box-shadow: none;
  }
  .span_css {
    margin: auto;
  }

  .spinner_css {
    width: 20px;
    height: 20px;
    color: rgb(23, 186, 224) !important;
    border: 3px solid currentColor;
    display: inline-block;
    vertical-align: text-bottom;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
  }
}

.history_container {
  input {
    border-radius: 5px;
    background-color: white;
  }
  border-radius: 5px;
  border: 0.5px solid #d6dae5;
  background-color: white;
  display: flex;
  .form-control {
    margin-bottom: 0px !important;
  }

  .spinner_css {
    position: absolute;
    top: 14px; //7px;
    right: 28px;
    width: 20px;
    height: 20px;
    color: rgb(23, 186, 224) !important;
    border: 3px solid currentColor;
    display: inline-block;
    vertical-align: text-bottom;
    border-right-color: transparent;
    border-radius: 50%;
    animation: 0.75s linear infinite spinner-border;
  }
}

.cal_input {
  display: flex;
  border: 1px solid black;
  input {
    width: -webkit-fill-available;
    border: none;
    font-size: 16px;
    color: transparent;
    text-shadow: 0 0 0 black;
  }
  .icon_div {
    margin: auto;
    padding: 10px;

    input {
      font-size: 14px;
    }
  }
}
.customDatePickerWidth {
  .rdrCalendarWrapper {
    width: inherit;
  }
  .rdrMonth {
    width: inherit;
  }
  .rdrDayToday .rdrDayNumber span:after {
    background: #f969a4;
  }
}

.prescription_card {
  background-color: #f5f6f7;
}

.history_input {
  input {
    border-radius: 5px;
    border: 0.5px solid #d6dae5;
    background-color: white;
  }
}

.prescription_card {
  background-color: #f5f6f7;
}

.history_input {
  input {
    border-radius: 5px;
    border: 0.5px solid #d6dae5;
    background-color: white;
  }
}

.custom-control-label:before {
  background-color: #56cde8;
  border-color: #56cde8;
  box-shadow: none;
}
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
  background-color: #56cde8;
  border-color: #56cde8;
  box-shadow: none;
}

.custom-control {
  padding-left: 0rem !important;
}

label {
  font-size: "14px";
  font-weight: "bold";
  color: #2f2f2f !important;
  text-transform: none !important;
}

.textarea-doct {
  border: 1px solid #ced4da !important;
}

.textarea-doct::-webkit-scrollbar {
  width: 7px;
}
.textarea-doct::-webkit-scrollbar-track {
  border-radius: 10px;
}

.textarea-doct::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 16px rgba(240, 232, 232, 1);
  min-height: 4px;
}

//css for shared manual rx

.m_rx_heading {
  background: #f5f6f7 0% 0% no-repeat padding-box;
  font: normal normal 600 16px/31px Rubik, sans-serif;
  color: #1b2027;
}
.m_rx_title {
  font: normal normal 600 14px/19px Rubik, sans-serif;
  color: #1b2027;
  margin: 0px;
  word-break: break-word;
}

.m_rx_details {
  color: #a3a9b7;
  margin: 3px 0px;
  font: normal normal normal 14px/17px Rubik, sans-serif;
}

.rx_icons {
  background: #e8fbff 0% 0% no-repeat padding-box;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 5px;
}

.rx_icons .rx_view_img {
  width: 20px;
  font-size: 20px;
}

.rx_icons:disabled {
  background: #f2f2f2;
}

.rx_border {
  border: 1px solid black;
}

.m-rx-border-b {
  border-bottom: 1px solid #d6dae5;
}

@media (max-width: 768px) {
  .rx_border {
    padding: 0px 10px;
  }
}

.rx_subheading {
  font: normal normal bold 18px/22px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #2f2f2f;
}

.mob-i-icon {
  display: none;
}

@media (max-width: 768px) {
  .mob-i-icon {
    display: contents;
  }
  .desktop-i-icon {
    display: none;
  }
}

.on-hold-modal {
  width: 570px;
}
.primary-vitals {
  .vital-component {
    height: 40px;
    width: 100%;
  }
  .vital-component-input {
    padding: 0;
    width: 100%;
  }
  .vital-component-input input {
    height: 38px;
    border-radius: 5px;
    background-color: #fff;
  }
  .vital-component-selected-unit {
    margin-right: 8px;
  }
  .vital-component-unit {
    height: 38px;
    min-width: 70px;
    max-width: 70px;
    padding: 5px;
    border-radius: 5px;
    background-color: #fff;
  }
  .vital-component-input input::placeholder {
    font-size: 9px;
  }
}
.add-more-vitals {
  display: flex;
  justify-content: center;
  .add-more-vitals-btn {
    width: 100%;
    border: 2px dotted #17bae0;
    color: #17bae0;
    font-size: 14px;
    font-family: Rubik, sans-serif;
    background-color: transparent;
    border-radius: 3px;
    height: 40px;
  }
}

.vital-lmp {
  .vdpi-input-container {
    border-radius: 5px;
    font: normal normal normal 13px/15px "Rubik", sans-serif;
    border: 0.5px solid #d6dae5;
    background-color: #ffffff;

    input {
      padding: 0px;
      color: #2f2f2f;
    }
    input::placeholder {
      font-size: 9px;
      color: #a3a9b7;
    }
  }
  .vdpi-calendar {
    right: 0px;
    div {
      color: #2f2f2f;
      font-weight: normal;
    }
  }
  .react-calendar {
    margin-bottom: 10px;
  }

  
  .vdpi-input {
    width: 70%;
  }
  .vdpi-calendar-icon {
    border-left: 0.5px solid #d6dae5;
    width: 45%;
    margin: 0px 5px;
  }
}
