.patient-details-header {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
}

.online-patient-container {
  margin: 0 6rem;
  padding: 6rem 0;

  @media screen and (max-width: 600px) {
    margin: 0 1.5rem;
    padding: 6rem 0;
  }
}

.welcome-head {
  background-color: #e4f3f7;
  border-radius: 5px;
  border: 4px solid #ffffff;
  font-size: 16px;
  margin-top: 1rem;

  @media screen and (max-width: 600px) {
    margin-top: 0;
  }
}

.doctor-profile-info {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #8888880d;
  border-radius: 5px;
  height: 100%;
}

.more-about-doctor {
  .image-position {
    display: flex;
    align-self: flex-start;

    #image-book {
      width: 24px;
    }
  }

  ul {
    li {
      list-style-type: disc;
      display: list-item;
    }
  }
}

.online-book-appointment {
  font-size: 20px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
}

.for-mob-view {
  margin-top: 0;

  @media screen and (max-width: 600px) {
    margin-top: 1.5rem;
  }
}

.details-form {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #8888880d;
  padding-bottom: 1.5rem;

  .patient-details {
    font-size: 20px;
    padding: 1.5rem 15% 0.5rem;
    font-weight: 700;
  }

  .details-field {
    padding: 0 15%;

    .online-patient-gender-options {
      display: flex;
      flex-wrap: wrap;
      align-items: baseline;

      input {
        cursor: pointer;
      }
    }
  }

  .online-patient-details {
    label {
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  .gap-for-mob {
    margin-top: 0;

    @media screen and (max-width: 600px) {
      margin-top: 1.5rem;
    }
  }
}

.online-patient-continue {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  color: #ffffff;
  font-size: 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px -3px 20px #8888881f;
  z-index: 9999;

  .continue-btn {
    background: #17bae0 0% 0% no-repeat padding-box;
    border-radius: 25px;
  }

  .continue-disabled-btn {
    background: #A3A9B7 0% 0% no-repeat padding-box;
    border-radius: 25px;
  }
}
