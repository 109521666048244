@use "../../../Util/c2cConstants/colorConstants.scss" as color;
@use "../../../Util//c2cConstants/fontFileConstants.scss" as font;

.custom-checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
  margin-bottom: 6px;

  .custom-checkbox-box {
    display: flex;
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    background-color: #d6dae5;
    border: none;
    appearance: none;
    cursor: pointer;
    border-radius: 3px;
  }

  input[type="checkbox"]:checked {
    background-color: #56cde8;
  }

  label {
    margin-bottom: 0px;
    font: normal normal normal 14px/17px Rubik, sans-serif;
    color: #21252a;
  }

  .checkmark {
    position: absolute;
    left: 4px;
    bottom: 5px;
    height: 10px;
    width: 13px;
    cursor: pointer;
  }

  .visible {
    display: block;
    top: 4px;
    background: transparent;
  }

  .hidden {
    display: none;
  }

  input[type="checkbox"]:checked::before {
    border-color: #ffffff;
  }
}
