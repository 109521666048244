@use "../../Util/c2cConstants/colorConstants" as color;
@use "../../Util/c2cConstants/fontFileConstants" as font;

// abhaHeadingViewUi component css
.abha-main-containt-view {
  .abha-view-container {
    height: 42.29vh;
    width: 100vw;
    background: color.$C2C_WHITE 0% 0% no-repeat padding-box;
    display: flex;
    align-items: center;
    justify-content: center;

    .abha-heading-pera-wrapper {
      width: 80%;
      height: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .abha-heading-wrapper {
      font: normal normal 500 35px/38px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
      height: 30%;
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }

    .abha-peragraph-wrapper {
      height: 60%;
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
      font: normal normal normal 16px/27px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
      text-align: center;
    }
  }

  .doctor-search-input-wrapper .input-holder {
    border: 1px solid color.$C2C_ANCESTRAL_WATER_COLOR;
  }

  // whatISAbhaUi component css

  .what-is-abha-container {
    height: 69.3vh;
    width: 100vw;
    display: flex;
    flex-direction: row;
    position: relative;

    .abha-gray-background-box {
      background-color: color.$C2C_SILVER_POLISH;
      height: 97%;
      width: 28.5%;
    }

    .abha-white-background-box {
      background-color: color.$C2C_WHITE;
      height: 100%;
      width: 71.5%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .man-with-qr-image {
      position: absolute;
      bottom: 15px;
    }

    .whats-is-abha-heading-pera-box {
      width: 45%;
      height: 50%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .whatsis-abha-heading {
      text-align: left;
      font: normal normal 500 35px/38px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_EERIE_BLACK;
    }

    .whatsis-abha-peragraph {
      text-align: left;
      font: normal normal normal 14px/25px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
    }
  }

  // areateAbhaUi component css

  .create-abha-ui-container {
    height: 90vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;

    .create-abha-heading-wrapper {
      text-align: center;
      font: normal normal 500 35px/45px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
      height: 25%;
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
    }

    .create-abha-mobile-img-process-wrapper {
      height: 75%;
      width: 85%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }

    .create-abha-phone-img-text-wrapper {
      width: 25%;
      height: 78%;
    }

    .create-abha-phone-img-container {
      height: 85%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .create-abha-process-text-container {
      height: 15%;
      width: 100%;
      text-align: center;
      font: normal normal normal 16px/25px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
    }
  }

  .abha-health-account-container {
    height: 72.41vh;
    width: 100vw;
    background: transparent
      linear-gradient(
        180deg,
        color.$C2C_LIGHT_PALE_BLUE 0%,
        color.$C2C_SAGA_GREEN 100%
      )
      0% 0% no-repeat padding-box;
    opacity: 0.8;
    margin-bottom: 50px;
    display: flex;
    justify-content: center;
    align-items: center;

    .abha-health-account-heading-peragraph {
      width: 40%;
      height: 85%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }

    .abha-health-account-video-container {
      width: 40%;
      height: 85%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .abha-health-account-heading {
      text-align: left;
      font: normal normal 500 35px/45px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
      width: 65%;
    }

    .health-account-videoclip {
      width: 80%;
      height: 83%;
      background-color: color.$C2C_WHITE;
      display: flex;
      justify-content: center;
      align-items: center;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    }

    .video-play-btn {
      cursor: pointer;
    }
  }

  .benefits-of-abha-container {
    height: 80vh;
    width: 100vw;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    .benefits-of-abha-image-wrapper {
      height: 80%;
      width: 45%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .benefits-of-abha-text-wrapper {
      height: 80%;
      width: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .benefits-old-Man-image {
      width: 500px;
      height: 400px;
    }

    .benefits-heading-list-wrapper {
      width: 80%;
      height: 85%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .benefits-abha-heading {
      font: normal normal 500 35px/45px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
    }

    .list-of-benifits-box {
      height: 82%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .benifit-list {
      height: 18%;
      width: 85%;
      background: color.$C2C_WHITE 0% 0% no-repeat padding-box;
      box-shadow: 2px 2px 40px color.$C2C_BLACK;
      border-radius: 3px;
      border-left: 5px solid color.$C2C_BLUE;
      display: flex;
      align-items: center;
    }

    .list-serial-no {
      text-align: left;
      font: normal normal 500 20px/45px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_BLUE;
      padding: 0px 15px;
    }

    .benifit-list-title {
      text-align: left;
      font: normal normal normal 16px/45px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
    }
  }

  .c2c-adbm-serivce-container {
    height: 85vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 20px;

    .c2c-adbm-serivce-content-wrapper {
      height: 90%;
      width: 100%;
      display: flex;
      position: relative;
    }

    .c2c-adbm-serivce-background-first {
      width: 75%;
      height: 100%;
      background: color.$C2C_PALE_IVORY 0% 0% no-repeat padding-box;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .c2c-adbm-serivce-background-sec {
      width: 25%;
      height: 100%;
      background: color.$C2C_SAGA_GREEN 0% 0% no-repeat padding-box;
    }

    .servies-heading-pera-btn-wrapper {
      width: 70%;
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .man-with-abha-card-img {
      position: absolute;
      bottom: 0%;
      left: 53%;
      height: 115%;
    }

    .servies-heading {
      text-align: left;
      font: normal normal 500 35px/45px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
      width: 57%;
    }

    .servies-pera-wrapper {
      text-align: left;
      font: normal normal normal 14px/23px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
      width: 80%;
    }

    .servies-btns-wrapper {
      height: 55%;
      font: normal normal 500 14px/45px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
    }

    .servies-points-wrapper {
      height: 80%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .servies-point-list {
      background: color.$C2C_WHITE 0% 0% no-repeat padding-box;
      border-radius: 3px;
      width: 32%;
      height: 25%;
      display: flex;
      align-items: center;
      padding: 0px 15px;
    }
  }

  .faq-abha-integration-container {
    height: 90vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-bottom: 80px;
    background-color: color.$C2C_WHITE;

    .faq-abha-integration-content-wrapper {
      height: 90%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
    }

    .faq-abha-integration-heading-text-wrapper {
      width: 50%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .faq-abha-integration-heading {
      text-align: left;
      font: normal normal 500 35px/38px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
    }

    .faq-abha-integration-peragraph {
      text-align: left;
      font: normal normal normal 14px/23px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
      width: 90%;
    }

    .faq-abha-integration-questions-wrapper {
      width: 85%;
      height: 65%;
      // border: 1px solid gray;
    }

    .faq-question-list-box {
      border-bottom: 1px solid color.$C2C_GRAY_4;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      font: normal normal 500 15px/25px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_TUNDORA;
    }

    .faq-abha-integration-images-wrapper {
      width: 30%;
      height: 90%;
      position: relative;
    }

    .faq-abha-abhacard-with-questionmark {
      position: absolute;
      right: 0;
    }

    .faq-abha-question-mark-img {
      position: absolute;
      left: 72%;
      bottom: 20%;
      background: color.$C2C_WHITE;
    }

    .faq-answer-list-box {
      text-align: left;
      font: normal normal normal 14px/23px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: #626262;
    }
  }

  .wrapper-login {
    filter: brightness(0.8) invert(1);
    font-weight: 400;
  }

  .home_page_header_content_main .home_page_header_details_view {
    color: color.$C2C_DARK_GRAY;
    font-weight: 500;

    .arrow_icon_color {
      margin-left: 10px;
      filter: brightness(5) invert(1);
    }
  }

  .abha-view-header-containt {
    width: 100%;
    background: transparent
      linear-gradient(180deg, #17bae000 0%, color.$C2C_SAGA_GREEN 100%) 0% 0%
      no-repeat padding-box;
  }

  .abha-view-header-heading {
    display: flex;
    justify-content: space-between;
    padding: 165px 165px 55px 165px;
    margin-top: -100px;
  }

  .abha-view-header-title-containt {
    font-size: 38px;
    font-weight: 500;
    color: color.$C2C_WINE_BLUE;
    width: 395px;
    font-family: font.$C2C_FONT_FAMILY;
  }

  .abha-view-header-subtitle-containt {
    font-size: 43px;
    font-weight: bold;
    color: color.$C2C_WINE_BLUE;
    width: 395px;
    height: 47px;
    font-family: font.$C2C_FONT_FAMILY;
  }

  .abha-view-header-text {
    margin-top: 100px;
  }

  .abha-view-header-width {
    width: 164px;
    background-color: color.$C2C_EASTER_BLUE;
    color: color.$C2C_WHITE;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }

  .abha-view-header-logo {
    margin-top: 20px;
  }

  .abha-view-header-subtitle-containt-sec {
    font-size: 43px;
    font-weight: bold;
    color: color.$C2C_WINE_BLUE;
    width: 395px;
    height: 47px;
    font-family: font.$C2C_FONT_FAMILY;
  }

  .home_page_header_content_main .home_page_header_containt_login_view {
    margin-right: 150px;
  }

  .circle-color {
    color: color.$C2C_GRAY_2;
  }

  .about-text-color {
    color: color.$C2C_DARK_GRAY;
  }

  @media screen and (max-width: 768px) {
    .abha-view-mobile-container {
      background: color.$C2C_WHITE 0% 0% no-repeat padding-box;
      padding: 20px;
    }

    .create-abha-heading-mobile-wrapper .create-abha-heading-mobile-text-one {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .create-abha-mobile-ui-container .create-abha-mobile-text-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10px;
    }

    .create-abha-mobile-ui-container .create-abha-mobile-subtext-mobile {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .abha-view-header-text-mobile {
      margin-top: 150px;
      padding: 30px;
    }

    .abha-heading-wrapper-mobile-view {
      margin-left: 40px;
      width: 258px;
      height: 74px;
      text-align: center;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
      font-weight: 500;
      font-size: font.$HEADING_1;
      font-family: font.$C2C_FONT_FAMILY;
    }

    .abha-peragraph-wrapper-mobile-view {
      width: 374px;
      height: 187px;
      text-align: center;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
      margin-top: 30px;
      padding-right: 30px;
      font-size: font.$HEADING_6;
      font-family: font.$C2C_FONT_FAMILY;
    }

    .what-is-abha-container-mobileview {
      padding: 0px;
    }

    .abha-health-account-container {
      height: 120vh;
      width: 100vw;
      background: transparent
        linear-gradient(
          180deg,
          color.$C2C_LIGHT_PALE_BLUE 0%,
          color.$C2C_SAGA_GREEN 100%
        )
        0% 0% no-repeat padding-box;
      opacity: 0.8;
      margin-top: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .vedio-icon-img {
      width: 100%;
      height: 200px;
      margin-top: 20px;
      background-color: white;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    }

    .wrapper-login {
      filter: brightness(0) invert(1);
    }

    .video-play-btn {
      margin-left: 150px;
      margin-bottom: -160px;
    }

    .benefits-of-abha-image-wrapper {
      padding: 20px;
    }

    .whats-is-abha-heading-pera-box-mobileView {
      font-weight: 500;
      font-size: font.$HEADING_1;
      padding: 10px;
      font-family: font.$C2C_FONT_FAMILY;
    }

    .whatsis-abha-peragraph-mobile-view {
      font-size: font.$HEADING_6;
      padding: 10px;
      font-family: font.$C2C_FONT_FAMILY;
    }

    .benefits-heading-list-wrapper {
      padding: 20px;
      margin-top: -30px;
    }

    .abha-gray-background-box {
      background: linear-gradient(
        to right,
        color.$C2C_SAGA_GREEN 0%,
        color.$C2C_SAGA_GREEN 65%,
        color.$C2C_WHITE 65%,
        color.$C2C_WHITE 100%
      );
      display: inline-block;
      height: 100%;
      margin-left: -110px;
    }

    .create-abha-mobile-ui-container {
      padding: 20px;
      margin-bottom: -50px;
    }

    .create-abha-heading-mobile-wrapper {
      font-weight: 500;
      font-size: font.$HEADING_3;
      font-family: font.$C2C_FONT_FAMILY;
    }

    .create-abha-mobile-img-process-wrapper-mobile-UI {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 10px;
    }

    .abha-health-account-heading-peragraph-mobile {
      padding: 20px;
      margin-top: 0px;
    }

    .abha-health-account-heading-peragraph-mobile-view {
      font-size: font.$HEADING_2;
      font-weight: 500;
      font-family: font.$C2C_FONT_FAMILY;
    }

    .list-of-benifits-box {
      height: 82%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .benifit-list {
      height: 50px;
      background: color.$C2C_WHITE 0% 0% no-repeat padding-box;
      box-shadow: 2px 2px 40px color.$C2C_BLACK;
      border-radius: 3px;
      border-left: 5px solid color.$C2C_EASTER_BLUE;
      display: flex;
      align-items: center;
      margin-top: 30px;
    }

    .benifit-list-title {
      text-align: left;
      font: normal normal normal 16px/45px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_CYNICAL_BLACK;
    }

    .servies-heading-mobile {
      text-align: left;
      font-size: font.$HEADING_3;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
      font-weight: 500;
      font-family: font.$C2C_FONT_FAMILY;
    }

    .abha-view-header-containt-mobile-view {
      width: 100%;
      height: 1391px;
      background: transparent
        linear-gradient(
          180deg,
          color.$C2C_LIGHT_PALE_BLUE 0%,
          color.$C2C_SAGA_GREEN 100%
        )
        0% 0% no-repeat padding-box;
    }

    .servies-pera-wrapper-mobile {
      text-align: left;
      font: normal normal normal 14px/23px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
      width: 80%;
    }

    .servies-btns-wrapper-mobile {
      height: 55%;
      letter-spacing: 0px;
      color: color.$C2C_DARK_GRAY;
    }

    .servies-point-list-mobile {
      background: color.$C2C_WHITE 0% 0% no-repeat padding-box;
      border-radius: 3px;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      padding: 0px 15px;
      margin-top: 10px;
    }

    .man-with-abha-card-img-mobile {
      position: initial;
      background: linear-gradient(
        to top,
        color.$C2C_SAGA_GREEN 0%,
        color.$C2C_SAGA_GREEN 70%,
        color.$C2C_PALE_IVORY 70%,
        color.$C2C_PALE_IVORY 100%
      );
    }

    .benefits-abha-heading {
      font-size: font.$HEADING_1;
      font-weight: 500;
      font-family: font.$C2C_FONT_FAMILY;
    }

    .faq-abha-integration-heading-text-wrapper {
      width: 50%;
      height: 90%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    .faq-abha-integration-container {
      padding: 20px;
      margin-top: 30px;
    }

    .faq-abha-integration-container .faq-question-list-box {
      border-bottom: 1px solid color.$C2C_GRAY_4;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;
      font: normal normal 500 15px/25px font.$C2C_FONT_FAMILY;
      letter-spacing: 0px;
      color: color.$C2C_TUNDORA;
      height: 60px;
    }

    .faq-title-header-view {
      font-size: font.$HEADING_1;
      font-weight: 500;
      font-family: font.$C2C_FONT_FAMILY;
    }

    .faq-subtitle-view {
      margin-top: 10px;
    }

    .abha-view-header-heading-mobile {
      display: flex;
      justify-content: space-between;
      padding: 20px;
      margin-top: 30px;
    }

    .abha-view-header-title-containt-mobile {
      font-size: 35px;
      font-weight: 500;
      color: color.$C2C_WINE_BLUE;
      width: 395px;
      height: 47px;
      font-family: font.$C2C_FONT_FAMILY;
    }

    .abha-view-header-subtitle-containt-sec-mobile {
      font-size: font.$HEADING_10;
      font-weight: bold;
      color: color.$C2C_WINE_BLUE;
      width: 395px;
      height: 47px;
      font-family: font.$C2C_FONT_FAMILY;
    }

    .abha-view-header-text {
      margin-top: 180px;
      padding-left: 20px;
    }

    .abha-view-header-logo {
      padding-left: 20px;
      margin-top: 30px;
    }

    .abha-view-header-subtitle-containt-mobile {
      font-size: font.$HEADING_10;
      font-weight: bold;
      font-family: font.$C2C_FONT_FAMILY;
    }
  }

  @media only screen and (max-width: 375px) {
    .create-abha-ui-container {
      height: 90vh;
    }
  }
}
