@use "../../../Util/c2cConstants/colorConstants.scss" as color;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.create-account-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25vw;

  .doctor-create-account-box {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .doctor-create-number-otp-setpassword-card {
    height: 50vh;
  }
}

.create-account-with-otp-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .doctor-create-number-otp-setpassword-wrapper {
    width: 90%;
    display: flex;
    flex-direction: column;
  }

  .set-password-input-title-feild {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .set-new-password-continue-btn-wrapper {
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.login-account-card {
  height: 80%;

  .create-account-input-terms-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .login-to-vitrual-btn-wrapper {
    height: 100px;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: end;
  }

  .terms-and-policy-wrapper {
    height: 35%;
    font: normal normal normal 20px/30px font.$C2C_FONT_FAMILY;
    color: #1d1d1d;
  }

  .privacy-text-btn {
    color: color.$C2C_EASTER_BLUE;
    font: normal normal 500 20px/30px font.$C2C_FONT_FAMILY;
    padding-right: 5px;
  }

  .term-text-btn {
    color: color.$C2C_EASTER_BLUE;
    font: normal normal 500 20px/30px font.$C2C_FONT_FAMILY;
    padding-left: 5px;
  }

  .login-vitrual-btn {
    font: normal normal 500 20px/30px font.$C2C_FONT_FAMILY;
    color: #43ceff;
    cursor: pointer;
  }

  .create-account-input-wrapper {
    height: 65px;
  }

  .enter-otp-input-feild {
    padding: 10px 0px 10px 0px;
  }

  .create-account-password-input-header {
    height: 140px;
    display: flex;
    align-items: center;
  }

  .create-account-action-button-wrapper {
    display: flex;
    justify-content: flex-end;
    height: 40px
  }
}

.create-account-card {
  height: 55vh;

  .create-account-number-otp-inputs-wrapper {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .set-new-password-input-feild-wrapper {
    height: 50%;
  }
}


@media (max-width: 768px) {

  .create-account-container {
    width: 100%;
  }

  .login-account-card {
    .terms-and-policy-wrapper {
      font: normal normal normal 14px/30px font.$C2C_FONT_FAMILY;
    }

    .privacy-text-btn {
      font: normal normal 500 14px/30px font.$C2C_FONT_FAMILY;
    }

    .term-text-btn {
      font: normal normal 500 14px/30px font.$C2C_FONT_FAMILY;
    }

    .login-vitrual-btn {
      font: normal normal 500 14px/30px font.$C2C_FONT_FAMILY;
    }
  }
}