.modal-backdrop.show {
  pointer-events: none;
}
.custom-modal-message-design {
  &.fade {
    pointer-events: initial;
  }
}
#star-rating-container-div {
  padding: 0px;
  .top_scroll {
    overflow-y: auto;
    padding: 0px 20px !important;
    .top_scroll_body {
      max-height: calc(100vh - 140px);
    }
  }
  .bottom_no_scroll {
    padding: 15px 20px !important;
  }
  .header-div {
    padding: 21px 0;
  }

  .heading-title {
    color: #2f2f2f;
    font-weight: 600;
    font-size: 20px;
  }

  .close-conatiner {
    cursor: pointer;
  }

  .w-100-imp {
    width: 100% !important;
  }

  .body-div {
    background: #f5f6f7 0% 0% no-repeat padding-box;
    padding: 10px 20px 20px 20px;

    input {
      background: #ffffff 0% 0% no-repeat padding-box;
      border-radius: 5px;
      height: 50px;
      padding: 16px;
      width: 100%;
      border: 0;
    }
  }

  .body-question {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #1b2027;
    padding-bottom: 10px;
  }

  .star-rating-div {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: 0 16px;
    width: 100%;
    border: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    margin-bottom: 10px;

    .star-detail {
      display: flex;
      flex-direction: column;
      margin: 10px;
      width: 57px;
      text-align: center;
      cursor: pointer;
    }
    .star-detail img {
      width: 30px;
      margin: 0 auto;
    }
    .rating-text {
      font-size: 12px;
      color: #707070;
      margin-top: 3px;
    }
  }

  .star-rating-div:focus {
    outline: none !important;
  }

  .star-rating-features-div {
    border-radius: 5px;
    padding: 0 0 0 16px;
    // width: 100%;
    border: 0;
    // justify-content: center;
    // align-items: center;
    display: flex;
    margin-bottom: 10px;

    .star-detail {
      display: flex;
      flex-direction: column;
      margin: 5px;
      width: 15px;
      text-align: center;
    }
    .star-detail img {
      width: 15px;
      margin: 0 auto;
      cursor: pointer;
    }
    .rating-text {
      font-size: 12px;
      color: #707070;
      margin-top: 3px;
    }
  }

  .star-rating-features-div:focus {
    outline: none !important;
  }

  .star-rating-value {
    color: #f969a4 !important;
  }

  .star-rating-button {
    background: #16bae0 0% 0% no-repeat padding-box;
    border-radius: 25px;
    color: #ffffff;
    width: 150px;
    height: 44px;
    border: none;
    &.disable {
      cursor: not-allowed;
      background-color: #a3a9b7;
      pointer-events: none;
    }
  }

  .star-cancel-button {
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 25px;
    color: #16bae0;
    width: 150px;
    height: 44px;
    border: 1px solid #e1e1e1;
  }

  .message-body-div {
    justify-content: center;
    font-size: 20px;
    color: #000000;
    margin-top: 50px;
    margin-bottom: 50px;
    line-height: 35px;
  }

  .message-title {
    font-size: 25px;
    color: #2f2f2f;
    font-weight: 600;
  }

  .circle-icon {
    color: #17bae0;
    font-size: 65px;
  }

  .times-circle-icon {
    color: #da1346;
    font-size: 75px;
  }

  .error-star {
    color: red;
    font-size: 12px;
    line-height: 28px;
  }
  .feedback_message {
    width: 100%;
    border: 1px solid lightgrey;
    border-radius: 6px;
    padding: 10px;
    min-height: 100px;
    max-height: 100px;
    word-break: revert;
    &.new {
      max-height: initial;
    }
  }

  .rate_features_feedback_message {
    .rate_features_continer {
      .rate_features_heading {
        color: #2f2f2f;
        font-weight: 600;
        font-size: 16px;
        padding: 5px 0px 10px 0px;
      }
      .rate_features_rating_container {
        display: flex;
        flex: 1;
        .rate_features_rating_heading {
          flex: 1;
          p {
            margin-bottom: 10px;
            font-size: 14px;
            color: #1b2027;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .rate_features_feedback_message {
      .rate_features_continer {
        .rate_features_rating_container {
          flex-direction: column;
          .star-rating-features-div {
            padding-left: 0px;
            .star-detail:first-child {
              margin-left: 0px;
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 320px) and (max-width: 560px) {
    .body-div {
      padding: 12px;

      input {
        padding: 14px;
      }
    }

    .message-body-div {
      font-size: 14px;
      margin-top: 30px;
      margin-bottom: 20px;
      line-height: 25px;
    }

    .message-title {
      font-size: 20px;
    }

    .circle-icon {
      font-size: 65px;
    }

    .star-rating-div {
      padding: 0 7px;

      .star-detail {
        margin: 4px;
      }

      .rating-text {
        font-size: 9px;
      }
    }

    .star-rating-features-div {
      padding: 0 7px;

      .star-detail {
        margin: 6px;
      }

      .rating-text {
        font-size: 9px;
      }
    }
  }
}

@media (max-width: 767px) {
  .custom-modal-message-design {
    .modal-dialog {
      max-width: 75vw;
      margin: auto;
    }
    #star-rating-container-div {
      &.container {
        padding-left: 10px !important;
        padding-right: 10px !important;
        margin: initial;
      }
    }
  }
}
