// <==== scss for delay marking button

.delay-marking-btn {
  background: #ededed 0% 0% no-repeat padding-box;
  border: 1px solid #222222;
  border-radius: 20px;
  font: normal normal normal 13px/19px Rubik, sans-serif;
  color: #242424;
}

.card-delay-marking-btn {
  border: 1px solid #3e455d;
  border-radius: 2px;
  background-color: transparent;
  font: normal normal 500 10px/27px Rubik, sans-serif;
  color: #3e455d;
  padding: 0px 10px;
}

// ===>

// <==== scss for delay time picker

.delay-input-label {
  text-align: left;
  font: normal normal normal 13px/15px "Rubik", sans-serif;
  letter-spacing: 0px;
  color: #9b9b9b;
}

.delay-input-container {
  display: flex;
  border: 1px solid #d6dae5;
  border-radius: 2px 0px 0px 2px;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    color: black;
    background: url("../../assets/down-arrow-black.svg") no-repeat 45px center;
    border-radius: 0;
    // width: 68px;
    border: none;
    max-width: 68px;
  }

  .delay-input-field {
    background-color: transparent;
    border: none;
    border-right: 1px solid #d6dae5;
    // width: 68px;
    min-width: 68px;
    text-align: center;
    max-width: 68px;
  }

  .input-field-error {
    border-right: 1px solid #ff0000;
  }
}

.input-container-error {
  border: 1px solid #ff0000;
}

.delay-error-message {
  text-align: left;
  font: normal normal normal 10px/12px "Rubik", sans-serif;
  letter-spacing: 0px;
  color: #ff0000;
}

.delay-meridiem {
  background-color: white;
  width: 68px;
  outline: none;
}

//  ====>

// <==== scss for delay modal

.border-btm {
  border-bottom: 2px solid #ebebeb;
}
.delay-heading {
  font: normal normal 500 18px/22px "Rubik", sans-serif;
  color: #1b2027;
}

.delay-close-icon {
  font: normal normal normal 16px/18px "Rubik", sans-serif;
  color: #1b2027;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.delay-doctor-name {
  font: normal normal 500 14px/17px "Rubik", sans-serif;
  color: #000000;
}
.delay-consultation-dropdown {
  width: 210px;
}

.delay-button-container {
  .cancel-btn {
    font: normal normal 500 11px/13px "Rubik", sans-serif;
    color: #17bae0;
    background-color: white;
    height: 35px;
    width: 50px;
  }

  .mark-delay-btn {
    background: #17bae0;
    border: 1px solid #17bae0;
    border-radius: 25px;
    color: #ffffff;
    font: normal normal 500 11px/13px "Rubik", sans-serif;
    height: 35px;
    padding: 0px 20px;
  }
}

// =====>

.delay-modal-container {
  width: 523px;
  min-height: 250px;
}

.delay-radio-btn-container {
  input[type="radio"]:after {
    width: 18px;
    height: 18px;
    border-radius: 15px;
    top: -3px;
    left: -1px;
    position: relative;
    background-color: #d1d3d1;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 5px solid #d1d3d1;
  }

  input[type="radio"]:checked:after {
    border-color: #17bae0;
    background-color: #fff;
  }

  .delay-radio-btn {
    height: 15px;
    width: 15px;
    margin-top: 20px;
  }
}

.timepicker-wrapper-container {
  min-height: 85px;
  min-width: 350px;

  .timepicker-wrapper {
    border-bottom: 1px solid #a3a9b733;
  }
}

@media (max-width: 768px) {
  .delay-modal-container {
    width: 100%;
    min-height: 372px;
  }

  .delay-input-container {
    .delay-input-field {
      width: 100%;
      max-width: 100%;
    }
  }

  .delay-meridiem {
    width: 100%;
  }

  .delay-consultation-dropdown {
    width: 100%;
    padding-top: 15px;
  }
  .delay-radio-btn-container {
    .delay-radio-btn {
      margin-top: 30px;
    }
  }
  .timepicker-wrapper-container {
    min-width: 310px;
  }
}
