@use "../../../../Util/c2cConstants/colorConstants" as color;
@use "../../../../Util/c2cConstants/fontFileConstants.scss" as font;

.abha-otp-input {
  width: fit-content;

  .aoi-otp-input {
    height: 41px;
    width: 41px;
    margin: 0 4px;
    text-align: center;
    background: color.$C2C_GRAY_5;
    border: 1px solid #efefef;
    border-radius: 5px;
    font-size: font.$PARAGRAPH_REGULAR;
    line-height: 2;
    font-family: font.$C2C_FONT_FAMILY;
    padding: 8px;
  }

  .aoi-otp-input::placeholder {
    position: relative;
    top: 4px;
    color: #bebebe;
  }

  .aoi-otp-input-error {
    border: 1px solid #fd1717;
  }

  .aoi-otp-input-error-content {
    font: normal normal normal 14px/17px font.$C2C_FONT_FAMILY;
    color: #fd1717;
    margin: 8px 4px 0px 4px;
    padding: 0px 10px;
  }

  .aoi-resend-time-with-button {
    display: flex;
    justify-content: space-between;
    margin: 16px 10px 0px 10px;
    width: 300px;
    padding: 0px 5px;
  }

  .aoi-resend-timer-container {
    font-size: font.$HEADING_6;
    line-height: 19px;
    font-family: font.$C2C_FONT_FAMILY;
    color: #7b7b7b;
  }

  .aoi-resend-time {
    color: #249f24;
  }

  .aoi-resend-button {
    text-decoration: underline;
    font-size: font.$HEADING_6;
    line-height: 19px;
    font-family: font.$C2C_FONT_FAMILY;
    letter-spacing: 0px;
    color: color.$PRIMARY_COLOR;
    cursor: pointer;
  }

  .aoi-resend-button-disabled {
    color: #b2b2b2;
    cursor: not-allowed;
  }

  .abha-otp-input-container {
    display: flex;
    justify-content: center;
  }

  .aoi-input-container {
    width: 300px;
  }

  .aoi-otp-button {
    display: flex;
    justify-content: center;
  }
}
