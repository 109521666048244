@use "./Util/c2cConstants/colorConstants.scss" as color;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.disabled-mylauncher-button {
  pointer-events: none;
  opacity: 0.8;
}
:focus-visible {
  outline: 3px solid color.$C2C_PINK !important;
  &.focus-border-none {
    outline: none !important;
  }
}
input:focus-visible,
textarea:focus-visible {
  outline: none !important;
}
