@use "../../../Util/c2cConstants/colorConstants" as c;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.home_page_header_content_main {
  .home_page_header_View {
    display: flex;
    justify-content: space-between;
    background-color: c.$C2C_EERIE_BLACK;
    height: 40px;
    padding-left: 165px;
  }

  .home_page_header_c2clog_view {
    height: 25px;
    margin-top: 10px;
  }

  .home_page_header_logoSpace_call_email {
    margin-left: 21px;
    width: 19px;
    height: 23px;
  }

  .home_page_header_logoSpace {
    width: 19px;
    height: 23px;
    margin-left: 10px;

    .web-home-page-apple-play-logo {
      height: 20px;
    }

    .web-home-page-call-logo {
      margin-left: 5px;
    }

    .web-home-page-mail-logo {
      margin-left: 10px;
    }
  }

  .home_page_header_details_view {
    display: flex;
    justify-content: space-between;
    font-weight: 500px;
    color: c.$C2C_WHITE;
    padding-left: 165px;
  }

  .home_page_header_about_view {
    display: flex;
    margin-top: 30px;
    cursor: pointer;
    height: 17px;
    width: 40%;
    white-space: nowrap;
  }

  .home_page_header_feature_view {
    margin-left: 30px;
  }

  .home_page_header_containt_download {
    color: c.$C2C_WHITE;
    margin-left: 5px;
  }

  .home_page_header_containt_color {
    color: c.$C2C_WHITE;
  }

  .home_page_header_containt_doctor_search {
    width: 500px;
    margin-left: 100px;
    margin-top: 30px;

    .searched_value {
      box-shadow: 0px 20px 20px #0d1c3b33;
      border-radius: 0px 0px 2px 2px;

      ul {
        padding: 0px;
      }

      li {
        border-bottom: 0px;
      }
    }

    .close_serch img {
      width: 20px;
      height: 20px;
      margin-left: -15px;
    }

    .search-input {
      padding: 10px;
    }

    .searched_item_text {
      h5 {
        font-weight: 500;
        font-size: 14px;
      }

      p {
        font: normal normal normal 12px/14px font.$C2C_FONT_FAMILY;
      }
    }
  }

  .home_page_header_containt_login_view {
    margin-top: 40px;
    margin-right: 180px;
  }

  .home_page_title_display {
    margin-right: 30px;
  }

  .home_page_Logo_view {
    display: flex;
    margin-right: 170px;
    justify-content: center;
    align-items: center;
  }

  .home_page_header_Menu_view {
    display: none;
  }

  .home_page_menu_bar_view {
    display: none;
  }

  .home_page_header_logo_size {
    color: c.$C2C_WHITE;
    font-size: font.$PARAGRAPH_REGULAR;
    margin-left: 10px;
  }

  .home_page_header_download_size {
    color: c.$C2C_WHITE;
    font-size: font.$PARAGRAPH_REGULAR;
    margin-left: 10px;
  }

  .home_page_header_phone_size {
    color: c.$C2C_WHITE;
    font-size: font.$PARAGRAPH_REGULAR;
    margin-left: 10px;
  }

  .doctor-search-input-wrapper {
    width: 445px;
    height: 40px;
  }

  .wrapper-login {
    margin-left: 30px;
  }

  .hoverable-button {
    width: 109px;
    height: 38px;
    background-color: white;
    color: black;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    border-radius: 4px;
  }

  .arrow_icon_color {
    margin-left: 10px;
    filter: brightness(0) invert(1);
  }

  @media screen and (max-width: 768px) {
    .home_page_Logo_view {
      display: flex;
      margin-right: 130px;
      justify-content: center;
      align-items: center;
    }

    .home_page_header_content_main .home_page_header_details_view {
      display: none;
    }

    .home_page_header_Menu_view {
      display: none;
    }

    .home_page_menu_bar_view {
      display: block;
    }

    .home_page_header_logo_size {
      color: c.$C2C_WHITE;
      font-size: font.$PARAGRAPH_REGULAR;
      margin-left: 20px;
    }

    .home_page_header_download_size {
      color: c.$C2C_WHITE;
      font-size: font.$PARAGRAPH_REGULAR;
      margin-left: 10px;
    }

    .home_page_header_phone_size {
      color: c.$C2C_WHITE;
      font-size: font.$PARAGRAPH_REGULAR;
      margin-left: 20px;
    }

    .hoverable-button {
      width: 109px;
      height: 38px;
      background-color: white;
      color: black;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      border-radius: 4px;
    }

    .wrapper-login {
      margin-left: 10px;
    }

    .side_bar_main_view .bar-icons {
      justify-content: flex-start;
    }

    .playstore-logo {
      margin-left: 10px;
    }

    .call-logo {
      margin-left: 30px;
    }

    .side_bar_company_download_view {
      margin-left: 20px;
    }

    @media screen and (max-width: 768px) {
      .home_page_Logo_view {
        display: none;
      }

      .home_page_menu_bar_view {
        display: block;
      }

      .home_page_header_View {
        display: flex;
        background-color: c.$C2C_EERIE_BLACK;
        height: 50px;
        margin-top: 1px;
        padding-left: 20px;
        padding-right: 20px;
        align-items: center;
      }

      .home_page_header_details_view {
        display: none;
      }

      .home_page_header_c2clog_view {
        margin: 0px 5px;
        height: 25px;
        margin-top: 5px;
      }

      .home_page_header_Menu_view {
        height: 30px;
        margin-top: 5px;
        display: block;
      }

      .arrowdown-mob {
        display: block;
        margin-left: 10px;
      }

      .menu-custom-button .right-menu {
        display: block;
      }

      .wrapper-login {
        column-gap: 0px;
        margin-right: 10px;
      }
    }
  }

  @media only screen and (min-width: 769px) and (max-width: 819px) {
    .home_page_header_details_view {
      padding-left: 30px;
    }
    .home_page_header_View {
      padding: 0px 30px;
    }
    .home_page_header_c2clog_view {
      width: 120px;
    }

    .home_page_Logo_view {
      margin-right: 0px;
    }

    .doctor-search-input-wrapper {
      width: 100%;
    }

    .home_page_header_containt_login_view {
      margin-right: 30px;
    }
  }
}

@media only screen and (min-width: 820px) and (max-width: 1179px) {
  .home_page_header_content_main .home_page_header_View {
    padding-left: 30px;
    height: 45px;
  }

  .home_page_header_content_main .home_page_Logo_view {
    margin-right: 30px;
  }

  .home_page_header_content_main .home_page_header_details_view {
    padding-left: 30px;
  }
}
