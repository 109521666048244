.header-categories {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  height: 70px;
  align-items: center;
  font: normal normal bold 18px/34px Rubik, sans-serif;

  @media screen and (min-width: 992px) {
    text-align: center;
    font: normal normal medium 20px/24px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #2f2f2f;
    opacity: 1;
  }
}

.marginIcon-tabsparent {
  margin-right: 5px;
}
