.headsticky {
  position: fixed;
  top: 0;
  width: 100%;
  height: 66px;
  background-color: #ffffff;
  border: 1px solid #0000001a;
  box-shadow: 0px 5px 5px #00000018;
  display: flex;
  align-items: center;
}
.headlogo {
  margin-left: 40px;
}
.fnt-18 {
  font-size: 17px !important;
}
.fnt-16 {
  font-size: 16px;
  color: #2f2f2f;
  margin-bottom: 4px;
}
.fnt-14 {
  font-size: 14px;
  color: #717171;
}
.mar-0 {
  margin: 0;
}

.patientFeedback-body {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.PF-defaultQus-bdy {
  padding: 15px 20px 0px 20px;
  border: 1px solid #0000001a;
  box-shadow: 0px 5px 5px #00000018;
  border-radius: 15px;
  margin-bottom: 70px;
}
.PF-defaultQus-bdy-div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
}
.PF-star-detail {
  display: flex;
  justify-content: space-between;
  width: 348px;
}
.PF-star-detail-star {
  display: flex;
}
.PF-star-img-div {
  padding-right: 15px;
  cursor: pointer;
  text-align: center !important;
  display: flex;
  justify-content: space-evenly;
}
.PF-star-img-div > div > div {
  font-size: 13px;
}
.skip-btn {
  border: 1px solid #17bae0;
  background: #fff;
  color: #17bae0;
  border-radius: 3px;
  padding: 4px 10px;
  font-size: 14px;
  flex-basis: 100px;
  flex-grow: 0;
  flex-shrink: 0;
}

.footsticky {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 70px;
  background-color: #ffffff;
  border: 1px solid #0000001a;
  box-shadow: 0px -5px 5px #00000018;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.footbtn {
  padding: 12px 70px;
  border-radius: 25px;
  font-size: 16px;
  background-color: #d6d6d4;
  color: #ffffff;
}
.footbtn-active {
  background-color: #17bae0;
}
.h-color {
  color: #2f2f2f;
}

.main-div-feedback-box {
  width: 750px;
  margin-left: 50px;
}

.main-empty-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  img {
    width: 220px;
    height: 250px;
  }
}

.main-empty-text-thankyou {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  font-family: Rubik, sans-serif;
}

.main-empty-text1 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  font-family: Rubik, sans-serif;
}

.feedback-circle {
  width: 108px;
  height: 108px;
  background-color: #00cd1c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  position: relative;
}
.thank-you-page img {
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 50%;
}
.feedback-circle div {
  position: absolute;
  left: 40%;
  top: 19%;
  content: "";
  width: 24px;
  height: 55px;
  border-right: 5px solid #ffffff;
  border-bottom: 5px solid #ffffff;
  transform: rotate(45deg);
}

.thank-you-page {
  width: 270px;
  margin: auto;
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.thank-you-page p {
  text-align: center;
  font-size: 20px;

  line-height: 25px;
  font-weight: 500;
  color: #2f2f2f;
}

.scale-rating {
  padding: 0px 9px;
  border: 1px solid #dcdcdc;
  color: #dcdcdc;
  border-radius: 4px;
}

.scale-rating-bottom-text {
  width: 200px;
  padding-top: 5px;
  color: #707070;
  font: normal normal normal 12px/14px Rubik, sans-serif;
}

.for-yes-and-no-container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font: normal normal normal 16px/27px Rubik, sans-serif;

}

/* Hide the browser's default radio button */
.for-yes-and-no-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.for-yes-and-no-container .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: #e4e1e1;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.for-yes-and-no-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.for-yes-and-no-container input:checked ~ .checkmark {
  background-color: #56cde8;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.for-yes-and-no-container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.for-yes-and-no-container .checkmark:after {
  top: 7px;
  left: 7px;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: white;
}
.PF-defaultQus-bdy > div:first-child {
  color: #fa69a4;
  font: normal normal medium 18px/22px Rubik, sans-serif;
}

.appointment-subtitle {
  color: #717171;
  font-weight: 400 !important;
  font-size: 13px !important;
}

@media screen and (max-width: 752px) {
  .main-div-feedback-box {
    width: 90%;
    margin: auto;
  }
  .headlogo {
    margin-left: 15px;
  }
  .PF-star-detail {
    width: 100%;
  }
  .PF-defaultQus-bdy-div > div:first-child {
    width: 80%;
  }
}

@media screen and (max-width: 768px) {
  .patient-default-qus {
    .main-empty-text-thankyou {
      font-size: 20px;
    }
  }
}
