.password-input-with-header {
  width: 100%;
  margin: 0px 0px 10px 0px;

  .piwh-header-title {
    font: normal normal normal 20px/30px Rubik, sans-serif;
    color: #1d1d1d;
    margin-bottom: 5px;
  }

  .piwh-header-title-mandatory,
  .piwh-input-error {
    color: #f82323;
  }

  .piwh-input-with-icon-container {
    display: flex;
    height: 55px;
    background: #f8f8f8;
    border: 1px solid #efefef;
    border-radius: 5px;
  }

  .piwh-input-with-icon-container-error {
    border: 1px solid #f82323;
  }

  .piwh-input-container {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0px 10px;
  }

  .piwh-input {
    background-color: transparent;
    width: 100%;
    border: none;
    font-size: 20px;
  }

  .piwh-image-container {
    display: flex;
    align-items: center;
    margin: 0px 20px;
  }

  .piwh-image {
    width: 30px;
    height: 27px;
    cursor: pointer;
  }

  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
}


@media (max-width: 768px) {

  .password-input-with-header {

    .piwh-input-with-icon-container {
      height: 49px;
    }

    .piwh-input {
      font-size: 20px;
    }
  }
}