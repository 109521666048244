.clinic-slot-wrapper {
    height: 44vh;
}

.resource-slot-wrapper {
    height: 38vh;

}

.slots-container {
    overflow-x: hidden;
    overflow-y: auto;
    margin-top: 1rem;
    width: 100%;
    padding: 0px 0px 65px 0px;
}

.slots-container::-webkit-scrollbar {
    width: 12px;
}

.slots-container::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
}

.no-slot-found-alert-wrapper {
    margin: 0px 15px;
}


