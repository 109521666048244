body {
    width: 100%;
    font-family: "Rubik", sans-serif !important;
    margin: 0px auto;
    background-color: #eeeeee;
}

.modal-body {
    /* padding: 90px 15px 60px !important; */
    padding: 0 !important;
}

.labelPatient {
    color: #f969a4;
}

.main-table {
    width: 100%;
    padding: 0px;
    margin: 0px;
    background: #f5f6f7;
}

.headerr {
    /*display: flex;*/
    width: 100%;
    background-color: #1b2027;
    align-items: center;
    /*padding-right: 16px;*/
    position: relative;
    box-sizing: border-box;
    min-height: 750px;
    /*border-bottom: solid 1px #000;*/
}

.doctor-appoitmrnt {
    width: 100%;
    /*background: #fff;*/
    /* padding: 0px opx 0px 0; */
    min-height: auto;
    /* background-color: #f5f6f7; */
}

.dashboard-for-mobile {
    margin-top: 0rem;
}

@media only screen and (max-width: 600px) {
    .dashboard-for-mobile {
        margin-top: -3rem !important;
    }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .dashboard-for-mobile {
        margin-top: -3rem !important;
    }
    .doctor-appoitmrnt {
        margin-top: 0px !important;
    }
}

.header__logo img {
    width: 220px;
}

.ttl-bar {
    width: 100%;
    display: inline-block;
    margin-bottom: 30px;
}

.ttl-bar h3 {
    display: inline-block;
    font-size: 18px;
    color: #1b2027;
    font-weight: 600;
    text-transform: uppercase;
}

.ttl-bar a {
    float: right;
    text-decoration: none;
    font-size: 12px;
    color: #fff;
    background: #17bae0;
    padding: 4px 25px;
    border-radius: 30px;
    font-weight: 600;
}

.ttl-bar a:hover {
    background: #09a4ce;
    color: #fff;
}


/* .dr-welcom-sec {
} */

.dr-welcom-sec h3 {
    display: inline-block;
    font-size: 24px;
    font-weight: 600;
    padding-bottom: 15px;
    color: #1b2027;
}

.dr-welcom-sec p {
    font-weight: 600;
    font-size: 15px;
    color: #a3a9b7;
}

.dr-welcom-sec p span {
    color: #f969a4;
}

.wel-rgt {
    width: 100%;
    /*background: #4da5c5;*/
    color: #1b2027;
    padding: 25px 15px 15px 15px;
    text-align: left;
    border-radius: 20px;
}

.wel-rgt p {
    color: #fff;
}

.wel-rgt a {
    font-size: 11px;
    text-decoration: none;
    color: #fff;
    background: #17bae0;
    padding: 6px 15px;
    border-radius: 30px;
    font-weight: 600;
    margin: 7px 5px 0px;
    display: inline-block;
}

.wel-rgt a:hover {
    color: #fff;
}

.up-coming-apmn {
    width: 100%;
    /*background: #f5f5f5;*/
    padding: 0px 15px 50px;
    border-radius: 10px;
    /* margin-top: 40px; */
    max-height: 483px;
    overflow-y: scroll;
    overflow-x: hidden;
}

.up-coming-apmn h3 {
    font-size: 16px;
}

.up-coming-apmn ul li {
    list-style: none;
    width: 100%;
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    display: inline-block;
}

.up-coming-apmn {
    max-height: 400px;
    overflow-y: scroll;
}

.patientList h5 {
    padding-bottom: 30px;
}

.patientList p {
    color: #1b2027;
}

.name {
    width: 28%;
    float: left;
    font-size: 14px;
    font-weight: bold;
    line-height: 18px;
}

.link-up {
    width: 25%;
    float: left;
    font-size: 11px;
    background: #82d319;
    color: #fff;
    text-align: center;
    padding: 3px 0px;
    border-radius: 50px;
    margin-top: 4px;
}

.vieww {
    width: 25%;
    float: left;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
}

.timee {
    width: 22%;
    text-align: center;
    float: left;
    font-weight: 600;
    margin-top: 5px;
    font-size: 14px;
}

.up-coming-apmn i {
    color: #eeae01;
    font-size: 21px;
    display: inline-block;
    padding-right: 5px;
}

.bg-c {
    background: #199cd3;
}

.name span {
    color: #eeae01;
}

.dr-prfl {
    width: 100%;
    padding: 0 20px;
    float: left;
}

.dr-prfl img {
    width: 150px;
}

.prfl-tt {
    width: 100%;
    margin-top: 15px;
    display: flex;
    margin-bottom: 15px;
}

.prfl-tt h3 {
    font-size: 18px;
    font-weight: bold;
    display: inline-block;
    /* float: left; */
}

.prfl-tt a {
    text-decoration: none;
    color: #17bae0;
    font-size: 20px;
}

.prfl-tt h3 span {
    font-weight: 400;
    color: #1b2027;
    font-size: 14px;
}

.dr-prfl ul li {
    list-style: none;
    margin-bottom: 10px;
    display: inline-block;
}

.dr-prfl ul li i {
    width: 35px;
    color: #f969a4;
    float: left;
    font-size: 21px;
}

.dr-prfl ul li p {
    width: calc(100% - 35px);
    float: left;
    margin: 0px;
    font-size: 12px;
    color: #1b2027;
}

.dr-prfl ul li svg {
    width: 25px;
    float: left;
}

.dr-prfl ul li:last-child p {
    font-weight: 600;
    font-size: 14px;
    width: calc(100% - 25px);
    padding-left: 10px;
}

.ham-menu__line:nth-of-type(1) {
    top: 5px;
}

.ham-menu__line:nth-of-type(2) {
    top: 11px;
}

.ham-menu__line:nth-of-type(3) {
    bottom: 4px;
}

.ham-menu.active {
    border: none;
    height: 31px;
}

.ham-menu.active .ham-menu__line:nth-of-type(1) {
    transform: translateY(10px) rotate(-45deg);
    background-color: #fff;
}

.ham-menu.active .ham-menu__line:nth-of-type(2) {
    transition-duration: 0s;
    opacity: 0;
}

.ham-menu.active .ham-menu__line:nth-of-type(3) {
    transform: translateY(-10px) rotate(45deg);
    background-color: #fff;
}

#item_a1 {
    color: #fff;
    font-size: 14px;
}

.downloadButton {
    color: white;
    font-size: 10px;
    border-radius: 40px;
    background-color: #4dcaf9;
}

.pname:hover {
    color: #09a4ce;
    font-size: 20px;
}

.patientMid {
    /* background: url("../images/app-bg.jpg"); */
    background-size: cover;
    background-color: #f5f5f5;
}

.goLiveText {
    color: #1b2027;
    font-size: 14px;
}

.goLiveText small {
    color: #a3a9b7;
}

.res-header {
    color: #f969a4;
    font-size: 12px;
}

.rescheduled-list {
    background-color: #fff;
}

.res-btn {
    background-color: #fff !important;
    color: #09a4ce !important;
    border-color: 1px solid #f5f5f5 !important;
    border-radius: 50px !important;
}

.cancel-button {
    background-color: #fff !important;
    color: #c41818 !important;
    border: none !important;
}

.slug-image {
    width: auto;
}

.couponCard {
    background-image: url("../assets/coupontemplate.svg");
    background-repeat: no-repeat;
    background-size: contain;
    color: white;
    position: relative;
}

.couponCard:hover {
    cursor: pointer;
}

.card-count {
    font-size: 45px;
}

.card-desc {
    line-height: 98%;
}

.centered {
    position: absolute;
    top: 39%;
    left: 50%;
    transform: translate(-50%, -50%);
}


/* .swal-wide {
  width: 620px;
} */

@media screen and (min-width: 576px) and (max-width: 768px) {
    .up-coming-apmn {
        width: 150%;
    }
    .whiteeer {
        background-color: #fff;
    }
    /* .swal-wide {
    width: 380px !important;
  } */
}

@media (max-width: 576px) {
    .doc-vac-row {
        margin-top: -60px;
    }
    .vaction-home {
        margin: 92px 0px 16px -360px;
    }
}

.sweetButton {
    background-color: #17bae0;
    padding: 10px 30px;
    border-radius: 999px;
}

.doc-name {
    width: 22rem;
}

.vaction-home {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
}

.up-vac-text {
    border-right: 1px solid #0000001a;
}

.doc-vac-row {
    width: fit-content;
}

.doc-novac-row {
    width: fit-content;
}

.vac-date-text {
    color: #17bae0;
    width: 6.9rem;
}

.instant_consultation {
    padding-bottom: 20px;
}

.instant_consultation_btn {
    border: 1px solid;
    padding: 10px 30px;
    border-radius: 20px;
    display: flex;
    align-items: center;
    color: #fff;
}

.instant_consultation_btn.isActive {
    background-color: #09a4ce;
    border-color: #09a4ce;
}

.instant_consultation_btn.noActive {
    background-color: #ccc;
    border-color: #e1e1e1;
    pointer-events: none;
}

.instant_consultation_btn img {
    width: 12px;
    margin-right: 4px;
}

.badge_bg {
    background-color: #f969a4;
    color: white;
    margin: 0px 0px 0px 5px;
}

.blink {
    animation: blink-animation 2s steps(5, start) infinite;
    -webkit-animation: blink-animation 2s steps(5, start) infinite;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@media (max-width: 768px) {
    .badge {
        width: 30px;
    }
}

@media only screen and (max-width: 1024px) {
    .headerr {
        /*display: flex;*/
        width: 100%;
        background-color: #f5f6f7;
        align-items: center;
        /*padding-right: 16px;*/
        position: relative;
        box-sizing: border-box;
        min-height: 750px;
        /*border-bottom: solid 1px #000;*/
    }
}

.patient_details_modal .modal-content {
    width: 750px;
}

.patient_details_modal .input-wrapper {
    display: flex;
    flex: 2;
    flex-direction: column;
    position: relative;
}

.patient_details_modal .input-wrapper .input-holder {
    display: flex;
    flex: 1;
    align-items: center;
    background-color: #f5f6f7;
    border-radius: 6px;
    position: relative;
    padding: 0px 10px;
    z-index: 9;
}

.patient_details_modal .input-wrapper .input-holder {
    border: 1px solid #d6dae5;
}

.patient_details_modal .input-wrapper .input-holder .search-input {
    width: 100%;
    padding: 10px 10px 10px 0px;
    background: #f5f6f7;
    box-sizing: border-box;
    border: none;
    outline: none;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    transition-delay: 0.3s;
    color: #000;
    border-radius: 5px;
}

.patient_details_modal .close_search {
    display: block;
    font-size: 18px;
    opacity: 0.5;
}

.patient_details_modal .close_search img {
    cursor: pointer;
}

.patient_details_modal .note {
    font-size: 12px;
    color: #a3a9b7;
    font-weight: normal;
}