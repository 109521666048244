.bookSchedule {
  max-width: 800px;
}
/* .timeBox {
  display: flex;
  justify-content: space-evenly;
  padding: 0 10px;
  width: 200px;
  height: 45px;
  line-height: 45px;
  font-size: 14px;
  border: none;
  font-weight: 700;
  background-color: #f5f6f7;
  border-radius: 10px;
  color: #17bae0;
  text-align: center;
  outline: none !important;
  margin: 1rem;
} */
.timeBox {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 8px;
  width: 144px;
  height: 38px;
  font-size: 13px;
  font-weight: 700;
  border-radius: 10px;
  color: #000;
  text-align: center;
  outline: none !important;
  margin: 8px 19px 8px 14px;
  background: #fff;
  border: 1px solid #ddd;
}
.timeBox p {
  margin: 0;
  font-weight: 400;
}
.doctorBookingPage {
  margin-top: 1rem;
  margin-bottom: 3rem;
}

.selected {
  background-color: #f969a4; /*rgb(27, 32, 39)*/
  color: white; /* #17bae0;*/
  border: 2px solid #f969a4; /*rgb(27, 32, 39)*/
}
.slots {
  /* margin: 3rem 0 1.6rem; */
  margin: 0 0 15px;
}

.slotTimes {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0;
}
.datePicker {
  max-width: 100%;
  background-color: inherit;
}

.submitButton {
  font-size: 16px;
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  width: 130px;
  height: 45px;
  line-height: 45px;
  border-radius: 40px;
  background-color: #17bae0;
  padding: 0;
  text-decoration: none;
  border: none;
  transition: 0.3s;
}

.submitButton:hover {
  background-color: #10a2c3;
}

.submitButton:disabled {
  background-color: #89d7e9;
  cursor: not-allowed;
}

.consultationInfoRoot {
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 24px;
  margin-top: 10px; */
}

.consultationInformation {
  display: flex;
  height: 60px;
  justify-content: space-around;
  align-items: center;
  padding: 0 16px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #8888880d;
  border-top: 1px solid #d6dae5;
  border-bottom: 1px solid #d6dae5;
}

.followSubmit {
  letter-spacing: 0px;
  color: #17bae0;
  font-size: 16px;
  font-weight: 600;
  margin-left: auto;
  border-bottom: 2px solid;
  cursor: pointer;
}

.consultationInformation i {
  color: #ef75a4;
  font-size: 32px;
  margin-right: 8px;
}

.consultationDuration {
  font-size: 12px;
  font-weight: bold;
  color: #000;
  display: flex;
  align-items: center;
}

.consultationFees {
  font-size: 13px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.consultationDuration p,
.consultationFees p {
  margin: 0 !important;
}

.followUpPrompt {
  text-align: right;
  display: flex;
  flex-direction: column;
}

.followUpPrompt h1 {
  font-size: 18px;
  font-weight: bold;
}

.followUpPrompt button {
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-top: 4px;
}

.btn-showMore {
  width: auto;
}

.walk_in_slot {
  background: #17BAE0 0% 0% no-repeat padding-box;
  border-radius: 2px;
  padding: 0px 6px;
  color: #fff;
  font-weight: normal;
}

.walk_in_slot_selected {
  background: #fff;
  color: #f969a4;
}

@media (max-width: 600px) {
  .consultationInfoRoot {
    flex-flow: column;
    align-items: center;
  }

  /* .consultationInformation {
    width: 80%;
    margin-bottom: 1rem;
  } */
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  /* .timeBox {
    width: 46%;
    padding: 0;
    margin: 8px 6px 8px 6px;
  } */

  .consultationInformation {
    width: 100%;
  }
  .followUpPrompt {
    margin: 0 auto;
    margin-top: 10px;
    display: none;
  }
  .followUpPrompt h1 {
    font-size: 16px;
  }
  .followUpPrompt.mobFollowUp {
    display: block;
    margin: 0 auto;
    margin-top: 10px;
  }
  .btnShowMore {
    width: 100%;
  }
}
.mobFollowUp {
  display: none !important;
}

.slotWrap {
  flex-wrap: wrap;
}
.slotWidth {
  width: 16.6666%;
  margin: 1%;
}
.modalWrap {
  flex-wrap: wrap;
}
.modalSlotWidth {
  /* width: 16.6666%;-5748-bug   */
  margin: 1%;
}
.disabled
{
    opacity:0.5 !important;
}


@media only screen and (max-width: 300px) {
  .modalSlotWidth {
    width: 100%;
    margin: 1%;
    margin-bottom: 2%;
  }
}
@media (min-width: 301px) and (max-width: 350px) {
  .modalSlotWidth {
    width: 47%;
    margin: 1%;
    margin-bottom: 2%;
  }
}
@media screen and (min-width: 360px) {
  .slotWidth {
    width: 28%;
    margin: 2%;
  }
}
@media only screen and (min-width: 480px) {
  .slotWidth {
    width: 100%;
    margin: 1%;
  }
}
@media screen and (min-width: 500px) {
  .slotWidth {
    width: 47%;
    margin: 1%;
    margin-bottom: 2%;
  }
  .slotWrap {
    padding-left: 1%;
  }
}
@media only screen and (max-width: 600px) {
  .slotWidth {
    width: 47%;
    margin: 1%;
    margin-bottom: 2%;
  }
  .modalSlotWidth {
    width: 48%; /*45%*/
    margin: 1%; /*2%*/
    margin-bottom: 2%;
  }
  .modalWrap {
    padding-left: 5%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .slotWidth {
    width: 31%;
    margin: 1%;
    margin-bottom: 2%;
  }
  .slotWrap {
    padding-left: 2%;
  }
}
@media (min-width: 601px) and (max-width: 640px) {
  .slotWidth {
    width: 29%;
    margin: 1%;
  }
  .slotWrap {
    padding-left: 5%;
  }
  .modalSlotWidth {
    width: 46%;
    margin: 2%;
  }
  .modalWrap {
    padding-left: 2%;
  }
}
@media (min-width: 641px) and (max-width: 669px) {
  .slotWidth {
    width: 46%;
    margin: 2%;
  }
}
@media (min-width: 670px) and (max-width: 767px) {
  .slotWidth {
    width: 46%;
    margin: 2%;
  }
  .modalSlotWidth {
    width: 29%;
    margin: 2%;
  }
}
@media only screen and (min-width: 720px) {
  .slotWidth {
    width: 29%;
    margin: 1%;
  }
  .slotWrap {
    padding-left: 2%;
  }
  .modalSlotWidth {
    width: 30%;
  }
  .modalWrap {
    padding-left: 5%;
  }
}

@media (min-width: 730px) and (max-width: 760px) {
  .slotWidth {
    width: 21%;
    margin: 2%;
  }
  .modalSlotWidth {
    width: 29%;
    margin: 2%;
  }
}

@media only screen and (min-width: 768px) {
  .slotWidth {
    width: 31%;
  }
  .slotWrap {
    padding-left: 1%;
  }
  .modalSlotWidth {
    width: 30%;
  }
  .modalWrap {
    padding-left: 5%;
  }
}

@media only screen and (min-width: 900px) and (max-width: 767px) {
  .slotWidth {
    width: 46%;
  }
  .slotWrap {
    padding-left: 5%;
  }
  .modalSlotWidth {
    width: 23%;
  }
  .modalWrap {
    padding-left: 2%;
  }
}

@media (min-width: 992px) and (max-width: 1023px) {
  .slotWidth {
    width: 21.6666%;
  }
  .slotWrap {
    padding-left: 4%;
  }
  .modalSlotWidth {
    width: 31%;
  }
  .modalWrap {
    padding-left: 2%;
  }
}
@media screen and (device-aspect-ratio: 2/3) {
  .slotWidth {
    width: 100%;
  }
  .slotWrap {
    padding-left: 5%;
  }
  .modalSlotWidth {
    width: 100%;
  }
  .modalWrap {
    padding-left: 5%;
  }
}

@media screen and (device-aspect-ratio: 40/71) {
  .slotWidth {
    width: 100%;
  }
  .slotWrap {
    padding-left: 5%;
  }
  .modalSlotWidth {
    width: 100%;
  }
  .modalWrap {
    padding-left: 5%;
  }
}
/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .slotWidth {
    /* width: 17.6666%; */
    margin-bottom: 1%;
  }
  .slotWrap {
    padding-left: 1%;
  }
  .modalSlotWidth {
    width: 22.6666%;
    margin-bottom: 1%;
  }
  .modalWrap {
    padding-left: 5%;
  }
}
@media (min-width: 1200px) and (max-width: 1240px) {
  .slotWidth {
    width: 20.9999%;
    margin-bottom: 1%;
  }
  .slotWrap {
    padding-left: 5%;
  }
  .modalSlotWidth {
    width: 26.3333%;
    margin-bottom: 1%;
  }
  .modalWrap {
    padding-left: 10%;
  }
}
@media (min-width: 1240px) and (max-width: 1280px) {
  .slotWidth {
    width: 19.9999%;
    margin-bottom: 1%;
  }
  .slotWrap {
    padding-left: 10%;
  }
  .modalSlotWidth {
    width: 26.3333%;
    margin-bottom: 1%;
  }
  .modalWrap {
    padding-left: 10%;
  }
}
@media (min-width: 1281px) and (max-width: 1300px) {
  .slotWidth {
    width: 17.9999%;
    margin-bottom: 1%;
  }
  .slotWrap {
    padding-left: 1%;
  }
  .modalSlotWidth {
    width: 26.3333%;
    margin-bottom: 1%;
  }
  .modalWrap {
    padding-left: 10%;
  }
}
@media (min-width: 1301px) and (max-width: 1400px) {
  .slotWidth {
    /* width: 17.6666%; */
    margin-bottom: 2%;
  }
  .slotWrap {
    padding-left: 1%;
  }
}
@media only screen and (min-width: 1401px) {
  .slotWidth {
    width: 16.6666%;
    margin-bottom: 1%;
  }
  .slotWrap {
    padding-left: 5%;
  }
  .modalSlotWidth {
    /* width: 17.6666%; */
    margin-bottom: 1%;
  }
  .modalWrap {
    padding-left: 3%;
  }
}
