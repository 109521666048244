.headerBar a {
    text-decoration: none;
    color: #1b2027;
}

.headerBar a:hover {
    text-decoration: none;
    color: #09a4ce;
}

.headerBar a:active {
    color: #09a4ce;
}

.adminHeader {
    background-color: #fff;
    /*background: url('/images/goliveButton.png');*/
}

.adminHeader h5 {
    text-align: center;
    border: 2px solid #09a4ce;
    color: #09a4ce;
}

.adminBg {
    background: url("../../images/admin.jpg");
    background-size: cover;
    height: 900px;
}

.adminCard {
    box-shadow: 4px 4px 14px -12px;
}

.blueBar {
    background-color: #09a4ce;
}

.alignment {
    text-align: center;
}

.logoutbtn {
    color: #fff !important;
}

.warning-bar {
    background-color: #ffc107;
    color: #fff;
    padding: inherit;
    width: fit-content;
    border-radius: 15%;
    vertical-align: middle !important;
}

.success-bar {
    background-color: #28a745;
    color: #fff;
    padding: inherit;
    width: fit-content;
    border-radius: 15%;
    vertical-align: middle !important;
}

.update-profile {
    float: right;
    width: 10rem;
    margin: 0px 10px 10px auto;
    margin-top: 0px !important;
}

.update-profile button {
    width: inherit;
}

.danger-bar {
    background-color: #bd2130;
    color: #fff;
    padding: inherit;
    width: fit-content;
    border-radius: 15%;
    vertical-align: middle !important;
}

.warning-color {
    color: #ffc107;
}

.success-color {
    color: #28a745;
}

.danger-color {
    color: rgb(245, 0, 0);
}

.approve-reject {
    margin-right: 55%;
}

.imageModal {
    width: 80%;
}

.navbar-links {
    color: #000000;
}

button#dsmMenuButton {
    background-color: white;
    border-color: white;
    color: #545b62;
}

button#dropdownMenuButton {
    background-color: white;
    border-color: white;
    color: #545b62;
}

button#listsMenuButton {
    background-color: white;
    border-color: white;
    color: #545b62;
}

.mandatory {
    color: #f969a4;
}

.width-fit-content .modal-content {
    width: fit-content;
}

.modal-border {
    padding: 0px 30px;
    background-color: white;
}

.clearSearch {
    right: 8px;
    top: calc(50% - 0.5rem);
}

.cursor-pointer {
    cursor: pointer;
}

.activePage {
    color: #443f3f;
}

.inactivePage {
    color: #09a4ce;
    cursor: pointer;
}

.page-number-row span {
    margin: 0 1rem;
}

.searchBar .react-date-picker__wrapper {
    display: none !important;
}

.border-1 {
    background: white;
    padding: 10px;
    margin-right: 20px;
    border: 1px solid black;
}

.container.appoint-history-search-bar {
    margin-left: 24px;
}

.appoint-history-search-bar .searchPatientContainer {
    margin-right: 3rem;
}


/* .dropdown-menu {
  transform: translate3d(-70px, 30px, 0px);
} */

.modal_scroll {
    max-height: 670px;
    overflow-y: scroll;
    overflow-x: hidden;
}

@media (max-width: 576px) {
    .searchBarPatient {
        width: 100% !important;
    }
    .filters {
        margin-left: 5rem;
    }
}

.searchBarPatient {
    width: 60%;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .searchBarPatient {
        width: 60%;
    }
}

.searchBar .react-date-picker__calendar {
    margin-right: 1rem;
}

.searchBarBtn:hover,
.searchBarBtn:focus,
.searchBarBtn:active {
    outline: none;
    box-shadow: none;
}

.text-red {
    color: red;
}

.text-grey {
    color: #4e4c4c;
}

@media (max-width: 576px) {
    .searchBar .react-calendar {
        width: 75vw;
        left: -75%;
        position: absolute;
        max-width: unset;
    }
}

.hidden {
    display: none !important;
}

@media (max-width: 768px) {
    .tab-form-sec {
        width: 100% !important;
    }
    .tab-select-sec {
        width: 100% !important;
    }
    .custom-bck {
        padding: 10px 30px !important;
    }
    .appoint-history-filters {
        position: absolute;
        right: 5%;
        top: 13px;
    }
    .appoint-history-heading strong b {
        font-size: 4.5vw;
    }
    .appoint-history-heading-outer.col-sm-12.col-lg-12 {
        padding-left: 0;
        padding-right: 0;
    }
    .date-filter-dropdown .dropdown-menu {
        transform: translate3d(-174px, 30px, 0px);
    }
    .container.appoint-history-search-bar {
        margin: 0 0.5rem;
    }
    .appoint-history-search-bar .searchPatientContainer {
        margin-right: 2%;
    }
}

.no-border {
    border: none !important;
}

.text-align {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (min-width: 900px) {
    .previous-patients-prescription-edit-drawer {
        width: 40vw;
        max-width: 30vw;
    }
}

@media (max-width: 498px) {
    .previous-patients-prescription-edit-drawer {
        width: 100vw;
        max-width: 100vw;
    }
}

.css-2b097c-container {
    /* width: 70%; */
}

.file-container {
    border: 1px solid #d6dae5;
    border-radius: 2px;
}


/* style for navigation bar new */

.navigation-link .nav-link {
    color: #545b62 !important;
    font-weight: bold;
}

@media only screen and (min-width: 900px) {
    .prescription-drawer {
        width: 38vw;
        max-width: 38vw;
    }
}

@media (max-width: 498px) {
    .prescription-drawer {
        width: 100vw;
        max-width: 100vw;
    }
}