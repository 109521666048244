@use "../../../Util/c2cConstants/colorConstants" as color;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.otp-input-wrapper {
  width: 100%;

  .oi-otp-input {
    height: 55px;
    width: 55px;
    text-align: center;
    background: color.$C2C_GRAY_5;
    border: 1px solid #efefef;
    border-radius: 5px;
    font-size: 20px;
    line-height: 2;
    font-family: font.$C2C_FONT_FAMILY;
    padding: 8px;
  }

  .oi-otp-input::placeholder {
    position: relative;
    top: 5px;
    color: #bebebe;
  }

  .oi-otp-input-error {
    border: 1px solid #fd1717;
  }

  .oi-otp-input-error-content {
    font: normal normal normal 14px/17px font.$C2C_FONT_FAMILY;
    color: #fd1717;
    margin: 0px 4px 0px 0px;
    padding: 0px 8px;
  }

  .otp-timer-and-otp-error-wrong-msg-wrapper {
    display: flex;
    align-items: center;
  }

  .oi-resend-time-with-button {
    display: flex;
    justify-content: space-between;
    margin: 16px 0px 0px 0px;
    width: 100%;
    padding: 0px 5px;
    text-decoration: none;
  }

  .oi-resend-timer-container {
    font-size: font.$HEADING_6;
    line-height: 19px;
    font-family: font.$C2C_FONT_FAMILY;
    color: #7b7b7b;
    width: 70px;
    height: 20px;
    display: flex;
    align-items: center;
  }

  .oi-resend-time {
    color: #249f24;
    margin: 0px 0px 0px 8px;
  }

  .oi-resend-button {
    text-decoration: none;
    font: normal normal 500 14px/17px font.$C2C_FONT_FAMILY;
    letter-spacing: 0px;
    color: #43ceff;
    cursor: pointer;
  }

  .oi-resend-button-disabled {
    color: #b2b2b2;
    cursor: not-allowed;
  }

  .oiw-input-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .oi-input-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .oi-otp-button {
    display: flex;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .otp-input-wrapper {
    .oi-otp-input {
      height: 49px;
      width: 49px;
      font-size: 14px;
    }
  }
}
