.thecolor {
  color: #2f2f2f;
}

.thecolor:hover {
  color: #2f2f2f;
}

.menu-button-font {
  font: normal normal normal 16px/19px Rubik, sans-serif;
}
