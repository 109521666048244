// PRIMARY COLORS

$PRIMARY_COLOR: #17bae0;

$C2C_BLUE: #17bae0;
$C2C_DARK_BLUE: #09a4ce;
$C2C_PINK: #f969a4;
$C2C_RED: #f82323;
$C2C_GREEN: #00b569;
$C2C_GREEN_2: #25d366;

$STAR_JASMINE: #d6dae5;
$CLEAN_POOL: #56cde8;
$WASHED_BLACK: #21252a;
$WHITE: #ffffff;

// SECONDARY COLORS

$C2C_WHITE: #ffffff;
$C2C_CYNICAL_BLACK: #171717;
$C2C_BLEACHED_SILK: #f2f2f2;
$C2C_DARK_PINK: #e0228b;
$C2C_ORANGE: #f07223;
$C2C_YELLOW: #ffb400;
$C2C_DARK_ORCHID: #9a2ebb;
$C2C_DARK_ORCHID_2: #9a2eb8;
$C2C_DARK_RED: #da1346;

// GRAY COLORS

$C2C_DARK_GRAY: #101010;
$C2C_GRAT_1: #484848;
$C2C_GRAY_2: #676767;
$C2C_GRAY_4: #e1e1e1;
$C2C_GRAY_5: #f8f8f8;
$C2C_GRAY_6: #b2b2b2;

// WEBSITE PASTEL COLORS

$C2C_PALE_IVORY: #faecd6;
$C2C_SAGA_GREEN: #b5d5c5;
$C2C_LIGHT_IVORY: #fff5d3;
$C2C_WINE_BLUE: #1f4695;
$C2C_LIGHT_BLUE: #19c8f0;
$C2C_PALE_YELLOW: #ffb400;
$C2C_LIGHT_PALE_BLUE: #17bae00d;
$C2C_LIGHT_SHADE_GRAY: #b5d5c5;
$C2C_EASTER_BLUE: #17bae0;
$C2C_EERIE_BLACK: #1d1d1d;
$C2C_SILVER_POLISH: #c8dbca;
$C2C_ANCESTRAL_WATER_COLOR: #d0d0d0;
$C2C_BLACK: #00000012;
$C2C_TUNDORA: #454545;
$C2C_CYAN_BLUE: #707070;
