.imageBackground {
  background-image: url("../assets/Onboarding.png");
  background-repeat: no-repeat;
  height: 589px;
  width: 385px;
  display: block;
}

.onboardingBody {
  width: 100%;
}

.main_onboard_modal {
  margin-left: 15%;
}

.otpInput {
  background-color: #f5f6f7;
  border-radius: 5px;
  border: none;
  letter-spacing: 20px;
}
@media screen and (max-width: 768px) {
  .for-ipad-onboarding {
    min-width: fit-content;
  }
  .imageBackground {
    display: none;
  }
}
@media screen and (max-width: 576px) {
  .otpInput {
    max-width: 60%;
    margin-left: 20%;
  }
  .responsive-div {
    margin-left: 10vh;
    margin-right: 10vh;
    margin-bottom: 2vh;
  }
}

::placeholder {
  letter-spacing: normal;
}

.failureotp {
  background-color: #707070;
}

.left-to-expire {
  font-size: 13px;
}
.btn-new-resend {
  color: #17bae0;
  font-size: 13px;
  margin-left: auto;
  cursor: pointer;
}

.custom-container {
  padding-left: 8vw !important;
  padding-right: 8vw !important;
}

@media screen and (max-width: 576px) {
  .custom-container {
    padding-top: 10vw !important;
  }
}

@media screen and (max-width: 576px) {
  .custom-item:nth-of-type(1) {
    order: 2;
  }
  .custom-item:nth-of-type(2) {
    order: 1;
  }
}

.masked-doctor {
  background-color: #91d1d7;
  background-image: url("../assets/doctor-login/masked-doctor.png");
  background-repeat: no-repeat;
  background-position: right -15% bottom 0%;
  background-size: contain;
  margin-top: 0px;
  padding-top: 3rem;
  padding-bottom: 4rem;
}

@media screen and (max-width: 576px) {
  .masked-doctor {
    padding-bottom: 50vh;
    background-position: center bottom;
    padding-top: 0rem;
  }
}

#heading {
  font-size: 35px;
  color: #ffffff;
}

@media screen and (max-width: 576px) {
  #heading {
    font-size: 20px;
    width: 100%;
    text-align: center;
  }
}

.details {
  color: #ffffff;
  width: 65%;
  margin: 0;
}

@media screen and (max-width: 576px) {
  .details {
    width: 100%;
    font-size: 14px;
  }
}

.grid-system {
  padding-bottom: 2vh;
}

@media screen and (max-width: 576px) {
  #grid-system {
    padding-bottom: 2vh;
  }
}

.grid-details {
  color: #ffffff;
  width: 60%;
  font-size: 15px;
}

@media screen and (max-width: 576px) {
  .grid-details {
    width: 80%;
    font-size: 14px;
  }
}

.sub-heading {
  color: #ffffff;
  font-size: 23px;
}

@media screen and (max-width: 576px) {
  .sub-heading {
    width: 100%;
    text-align: center;
    font-size: 20px;
  }
}

.input-mobile {
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.label-mobile {
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 30px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

#patient-login {
  color: #17bae0;
  border-bottom: 2px solid #17bae0;
  padding-bottom: 2px;
}

.responsive-login {
  text-align: center;
  width: 100%;
}

@media screen and (max-width: 576px) {
  .responsive-login {
    text-align: center;
    margin-top: -5px;
    margin-bottom: 15px;
  }
}

.register-confirmation-btn {
  background-color: #17bae0;
  color: #ffffff;
  border-radius: 456px;
  padding: 10px 50px;
}
