%common-style {
  display: flex;
  flex-direction: row;
}

.the-searched-question {
  opacity: 1;

  @media screen and (min-width: 992px) {
    opacity: 1;

    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.App {
  @extend %common-style;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.c2clogo {
  width: 85%;
  @extend %common-style;
  justify-content: center;
  align-items: flex-end;
  margin-left: 3%;

  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
    width: 15%;
    justify-content: center;
  }
}

.header-color {
  padding: 0px;
  @media screen and (min-width: 992px) {
    // padding: 20px;
  }
}

.bg-image {
  background: url("../../assets/Tablet.png") 0 0 / cover no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (max-width: 400px) {
    background: url("../../assets/Mobile.png") 0 0 / cover no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media screen and (min-width: 992px) {
    background: url("../../assets/Group\ 4001.png") 0 0 / cover no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 80px;
  }
}

.user-entry {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 10px 30px #00000029;
  border-radius: 50px;
  opacity: 1;
  width: 100%;
  align-self: center;
  padding: 5px;
  @extend %common-style;
  justify-content: space-between;

  @media screen and (min-width: 992px) {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #00000029;
    border-radius: 50px;
    opacity: 1;
    //width: 100%;
    align-self: center;
    padding: 5px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    width: 300px;
  }
}

.selected-user {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 12px;
  flex-direction: row;

  @media screen and (min-width: 992px) {
    width: 100%;
    padding: 8px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.faq-button-pad {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.user-doctor {
  box-shadow: "0px 3px 6px #00000029";
  border-radius: 25px;
  background: white 0% 0% no-repeat padding-box;
  width: 120px;
  justify-content: center;
  align-items: center;
  color: black;
  display: flex;
  flex-direction: column;
  font: normal normal medium 14px/17px Rubik, sans-serif;
  cursor: pointer;

  @media screen and (min-width: 992px) {
    box-shadow: "0px 3px 6px #00000029";
    border-radius: 25px;
    background: white 0% 0% no-repeat padding-box;
    width: 50%;
    justify-content: center;
    align-items: center;
    color: black;
    display: flex;
    flex-direction: column;
    font: normal normal medium 16px/19px Rubik, sans-serif;
    cursor: pointer;
    height: 45px;
  }
}

.layout-area {
  @extend %common-style;
  width: 100%;
  justify-content: center;
}

.layout-help {
  width: 100%;
  text-align: center;
  opacity: 1;
  //padding: 5px;

  margin: auto;
  font: normal normal bold 18px/22px Rubik, sans-serif;
  color: #ffffff;

  @media screen and (min-width: 992px) {
    width: 100%;
    text-align: center;
    font: normal normal bold 35px/42px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    padding: 10px;
    font-family: system-ui;
  }
}

.search-area {
  @extend %common-style;
  width: 100%;
  justify-content: center;
}

.design-for-question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (min-width: 992px) {
    opacity: 1;
  }
}

.the-alignment-question {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-bottom: 24px;

  @media screen and (min-width: 600px) {
    display: flex;
    flex-direction: column;
    padding: 4%;
  }
}

.the-column-style {
  display: flex;
  flex-direction: column;
}

.close-collapse {
  color: #17bae0;
  font: normal normal bold 16px/19px Rubik, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  // height: 30px;
  align-items: center;
  cursor: pointer;
  border-bottom: solid;
  border-width: unset;
  width: 40px;
}

.close-collapse-container {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.question-style {
  font: normal normal normal 16px/22px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #2f2f2f;
  opacity: 1;

  @media screen and (min-width: 600px) {
    font: normal normal normal 18px/25px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #2f2f2f;
    opacity: 1;
  }
}

.bottom-default-question {
  font: normal normal normal 16px/22px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #2f2f2f;
  opacity: 1;
  height: 55px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 992px) {
    font: normal normal normal 16px/19px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #2f2f2f;
    opacity: 1;
    height: 55px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.answer-style {
  text-align: left;
  font: normal normal normal 14px/20px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #868686;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: 992px) {
    text-align: left;
    font: normal normal normal 16px/22px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #868686;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 800px;
  }
}

.align-question {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  align-items: center;
  padding-top: 25px;

  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;
  }
}

.yes-no-button {
  width: 94px;
  height: 45px;
  background: #17bae0 0% 0% no-repeat padding-box;
  border-radius: 25px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
}

.yes-no-button-toggle {
  border: 1px solid #d6dae5;
  border-radius: 25px;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 94px;
  height: 45px;
  cursor: pointer;
}

.cross {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  height: 34px;

  @media screen and (min-width: 992px) {
    cursor: pointer;
  }
}

.cross:hover {
  height: 25px;
  cursor: pointer;
}

.align-click-button {
  display: flex;
  flex-direction: row;
  height: 60px;
  align-items: center;
  width: 200px;
  justify-content: space-between;
}

.bottom-nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.align-nav-button {
  @extend %common-style;
  justify-content: space-around;

  @media screen and (min-width: 992px) {
    text-align: center;

    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    padding: 0px;
  }
}

.bottom-nav-tag-name {
  width: 100%;

  @media screen and (min-width: 992px) {
    width: 50%;
  }
}

.bottom-text {
  font: normal normal normal 14px/17px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #bcbcbc;
  opacity: 1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-width: unset;

  @media screen and (min-width: 992px) {
    letter-spacing: 0px;
    color: #bcbcbc;
    opacity: 1;
    text-align: center;
    font: normal normal normal 18px/25px Rubik, sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-width: unset;
  }
}

.bottom-text1 {
  color: #2f2f2f;
  border-bottom: 2px solid pink;
  text-align: center;
  font: normal normal 600 14px/19px Rubik, sans-serif;
  letter-spacing: 0px;
  opacity: 1;
  line-height: 2.5em;

  @media screen and (min-width: 992px) {
    color: black;
    border-bottom: 2px solid pink;
    text-align: center;
    font: normal normal 600 18px/25px Rubik, sans-serif;
    letter-spacing: 0px;
    opacity: 1;
    line-height: 2.5em;
  }
}

.bottom-text-switch {
  text-align: center;
  font: normal normal bold 16px/19px Rubik, sans-serif;
  letter-spacing: 0px;
  color: black;
  opacity: 1;
}

.user-patient {
  box-shadow: "0px 3px 6px #00000029";
  border-radius: 25px;
  background: #17bae0 0% 0% no-repeat padding-box;
  width: 120px;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  height: 30px;
  font: normal normal normal 14px/17px Rubik, sans-serif;

  @media screen and (min-width: 992px) {
    box-shadow: "0px 3px 6px #00000029";
    border-radius: 25px;
    background: #17bae0 0% 0% no-repeat padding-box;
    width: 50%;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    height: 45px;
    font: normal normal medium 16px/19px Rubik, sans-serif;
  }
}

.search-area .search-box {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 28px;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 320px;
  height: 46px;

  @media screen and (min-width: 700px) {
    margin: auto;
    width: 650px;
    height: 56px;
  }
}

.search-box-space {
  padding: 15px;
  width: 1000px;
  height: 90px;
  justify-content: center;
  display: flex;
}

.layout-popular-searches {
  @extend %common-style;
  letter-spacing: 0px;
  color: #ffffff;
  width: 100%;
  justify-content: center;
  margin-bottom: 50px;

  @media screen and (min-width: 700px) {
    display: flex;
    flex-direction: row;
    letter-spacing: 0px;
    color: #ffffff;
    width: 100%;
    justify-content: center;
  }
}

.in-layout1 {
  font: normal normal normal 14px/19px Rubik, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media screen and (min-width: 992px) {
    font: normal normal normal 16px/19px Rubik, sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}

.in-layout2 {
  font: normal normal bold 14px/19px Rubik, sans-serif;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-left: 5px;

  @media screen and (min-width: 992px) {
    font: normal normal bold 16px/19px Rubik, sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    margin-left: 5px;
  }
}

.header-content-container {
  display: none;

  @media screen and (min-width: 992px) {
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: flex-end;
    margin-right: 5%;
  }
}

.drawer-nav {
  display: flex;
  flex-direction: row;
  margin-right: 5%;
  width: 80%;
  justify-content: flex-end;

  @media screen and (min-width: 992px) {
    display: none;
  }
}

.header-content {
  @extend %common-style;
  justify-content: space-around;
  align-items: center;
}

.align-header {
  @extend %common-style;
  align-items: center;
  justify-content: center;
  width: 200px;
}

.align-header2 {
  @extend %common-style;
  align-items: center;
  justify-content: center;
  width: 250px;
}

.wrapper {
  width: "100%";
  @extend %common-style;
  align-items: center;
  background-color: transparent;
}

.text {
  color: black;
  width: 130px;
  justify-content: flex-start;
  font: normal normal normal 16px/19px Rubik, sans-serif;
  display: flex;
  &:hover {
    color: black;
    width: 130px;
    justify-content: flex-start;
    font: normal normal normal 16px/19px Rubik, sans-serif;
    display: flex;
  }
}

.text2 {
  color: black;
  width: 210px;
  font: normal normal normal 16px/19px Rubik, sans-serif;
  &:hover {
    color: black;
    width: 210px;
    font: normal normal normal 16px/19px Rubik, sans-serif;
  }
}

.menu-button {
  background-color: transparent;
  padding: 0px;
  width: 88px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: 15px;
  cursor: pointer;
  border: 0px solid #fff;
}

.right-menu {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.dropdown-menu {
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 18px;
  align-items: center;
  justify-content: flex-start;
  padding: 0px;
}

// .dropdown-menu a:hover {
//   background-color: #000;
//   color: red;
// }

.right-menu:hover .dropdown-menu {
  display: inline-block;
}

.right-menu:hover .menu-button {
  background-color: transparent;
}

.both-user-data {
  max-width: 50vw;
  width: 50%;
  margin: auto;
  padding: 10px;
  background-color: red;

  @media screen and (min-width: 992px) {
    width: 300px;
  }
}

.ticket {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.ticket-position {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (min-width: 992px) {
    width: 50%;
  }
}

#zsfeedbackFrame {
  width: 100%;

  @media screen and (min-width: 601px) {
    max-width: 600px;
  }
}

@import "~bootstrap/scss/bootstrap";
