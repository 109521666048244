@use "../../../Util/c2cConstants/colorConstants" as color;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.abha-mobile-link-view {
  .amlv-aadhar-verified-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 24px;
  }

  .amlv-aadhar-verified-content {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #4ea944;
    border-radius: 100px;
    width: auto;
    height: 40px;
    padding: 0px 12px 0px 0px;
    margin-top: 13px;
  }

  .amlv-aadhar-verified-text {
    font-weight: 500;
    font-size: font.$PARAGRAPH_SMALL;
    font-family: font.$C2C_FONT_FAMILY;
    line-height: 22px;
    color: #4ea944;
    text-transform: capitalize;
  }

  .amlv-mobile-input-label {
    text-align: center;
    font-weight: 500;
    font-size: font.$PARAGRAPH_REGULAR;
    font-family: font.$C2C_FONT_FAMILY;
    line-height: 22px;
    color: color.$C2C_CYNICAL_BLACK;
    text-transform: capitalize;
    margin-bottom: 16px;
  }

  .amlv-mobile-link-button-container {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  .amlv-mobile-input-container {
    display: grid;
    justify-content: center;
  }

  .amlv-mobile-input-content {
    display: flex;
    border: 1px solid #efefef;
    border-radius: 5px;
    background: #f8f8f8;
    width: 350px;
  }

  .amlv-mobile-input-prefix {
    width: 90px;
    background: color.$C2C_WHITE;
    border-radius: 3px;
    padding: 11px 12px;
    display: flex;
    align-items: center;
    min-width: 90px;
  }

  .amlv-mobile-input-prefix-image {
    width: 21px;
    height: 16px;
    margin-right: 5px;
  }

  .amlv-mobile-input {
    width: 90%;
    padding: 11px 12px;

    input {
      width: inherit;
      background: none;
      height: inherit;
      border: none;
    }
  }

  .amlv-otp-container {
    margin-top: 12px;
    display: flex;
    justify-content: center;
  }

  .amlv-link-mobile-heading {
    text-align: center;
    font-size: 30px;
    font-family: font.$C2C_FONT_FAMILY;
    font-weight: 600;
    color: color.$C2C_CYNICAL_BLACK;
    line-height: 36px;
    margin: 0px 100px;
  }

  .amlv-mobile-input-content-error {
    border: 1px solid #fd1717;
  }

  .amlv-mobile-input-error {
    font: normal normal normal 14px/17px font.$C2C_FONT_FAMILY;
    color: #fd1717;
    margin-top: 8px;
  }

  .amlv-card-tick-gif {
    height: 30px;
    border-radius: 50%;
    margin: 0px 5px 0px 0px;
  }

  .amlv-mobile-number-text {
    text-align: center;
    font: normal normal normal 14px/22px font.$C2C_FONT_FAMILY;
    color: color.$C2C_CYNICAL_BLACK;
    text-transform: capitalize;
  }

  .amlv-mobile-number {
    font-weight: 500;
  }

  .amlv-get-otp-button {
    width: 140px;
    display: flex;
    align-items: center;
    margin: 0px 10px;
  }

  .amlv-get-otp-button-text {
    cursor: pointer;
    font: normal normal normal 16px / 19px font.$C2C_FONT_FAMILY;
    color: #17bae0;
  }

  .amlv-get-otp-button-text-disabled {
    cursor: not-allowed;
    color: #b2b2b2;
  }

  .amlv-mobile-content-text {
    margin-top: 12px;
    text-align: center;
    font: normal normal normal 12px/22px font.$C2C_FONT_FAMILY;
    color: #717171;
  }

  .amlv-mobile-number-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .amlv-edit-icon {
    margin-left: 5px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .amlv-mobile-input-content {
      width: 100%;
    }

    .amlv-link-mobile-heading {
      margin: 0px 50px;
    }
  }
}
