@use "../../../../Util/c2cConstants/colorConstants" as color;
@use "../../../../Util/c2cConstants/fontFileConstants.scss" as font;

.terms-and-condition-popup {
  width: 100%;
  background-color: color.$C2C_WHITE;
  .terms-and-condition-popup-body {
    .terms-and-condition-div {
      font: font.$C2C_FONT_FAMILY;
      h3 {
        font-size: 24px;
        color: color.$C2C_BLUE;
        text-align: start;
      }
      p {
        color: color.$C2C_EERIE_BLACK;
        text-align: start;
      }
    }
  }
}
