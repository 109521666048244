.search-card-text {
  padding: 10px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  text-align: left;
  font: normal normal normal 18px/22px Rubik, sans-serif;
  letter-spacing: 0px;
  color: #2f2f2f;
  opacity: 1;
  width: 260px;

  @media screen and (min-width: 600px) {
    padding: 10px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    text-align: left;
    font: normal normal normal 18px/22px Rubik, sans-serif;
    letter-spacing: 0px;
    color: #2f2f2f;
    opacity: 1;
    width: 625px;
  }
}

.hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
  width: 100%;
}

.keypress-active {
  background-color: green;
}

.root {
  max-height: 440px;
  padding: 0px;
  overflow-y: scroll;
  width: 310px;
  overflow-x: hidden;

  @media screen and (min-width: 700px) {
    max-height: 440px;
    padding: 0px;
    overflow-y: scroll;
    width: 640px;
    overflow-x: hidden;
  }
}
