.payment-wrap {
  background-color: #f5f6f7;
  height: 80vh;

  .payment-content {
    width: 100%;
    height: 100%;

    .payment-title {
      color: #484848;
      font: normal normal 500 16px / 19px Rubik, sans-serif;
      margin-top: 50px;
      margin-bottom: 16px;
    }
  }

  .payment-details {
    width: 40.5%;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #8888880d;
    border: 1px solid #E1E1E1;
    border-radius: 8px;
    padding: 30px 30px 20px 30px;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .payment-details-title {
    color: #676767;
    font: normal normal normal 12px / 14px Rubik, sans-serif;
  }

  .payment-details-value {
    color: #1D1D1D;
    font: normal normal 500 12px / 14px Rubik, sans-serif;
  }

  .payment-details-section {
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    padding: 12px;
    margin-bottom: 4px;
  }

  .payment-details-gst-title {
    color: #B2B2B2;
    font: normal normal normal 10px / 12px Rubik, sans-serif;
  }
}

.payment-proceed-btn {
  font: normal normal 500 14px/17px Rubik, sans-serif;
  background-color: #17bae0;
  color: #ffffff;
  border-radius: 50px;
  padding: 8px 16px;
  border: 1px solid transparent;
}

.payment-proceed-disabled-btn {
  background-color: #a3a9b7;
}

.payment-back-btn {
  font: normal normal 500 14px/17px Rubik, sans-serif;
  background-color: #ffffff;
  color: #17bae0;
  border-radius: 50px;
  border: 1px solid #17BAE0;
  padding: 8px 16px;
  margin-right: 8px;
}

.time-slot-not-available-modal,
.link-expired-modal {
  .modal-content {
    width: 70%;

    @media screen and (max-width: 600px) {
      width: 100%;
    }
  }

  .time-slot-not-available-modal-head,
  .link-expired-modal-head {
    font-size: 24px;
    font-weight: 600;

    @media screen and (max-width: 600px) {
      font-size: 20px;
    }
  }

  .time-slot-not-available-modal-body,
  .link-expired-modal-body {
    font-size: 18px;

    @media screen and (max-width: 600px) {
      font-size: 16px;
    }
  }
}

.payment-countdown-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  background-color: #404760;
  color: white;
  position: relative;
}

.payment-margin {
  margin-top: 8px;
  margin-bottom: 8px;
}
