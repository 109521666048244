@charset "utf-8";

header {
  width: 100%;
  padding: 15px 50px;
  background-color: #fff;
}
.logo img {
  height: 70px;
}
.login-btn {
  width: 150px;
  height: 50px;
  background: #17bae0;
  float: right;
  border-radius: 50px;
  line-height: 45px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
.login-btn:hover {
  color: #fff !important;
  text-decoration: none;
}
.pro-body-content {
  width: 100%;
  /* padding-bottom: 100px; */
  background-color: #f5f6f7;
}
.pro-content-col {
  width: 100%;
  /* background-color: #fff;
  border-radius: 10px;
  padding: 30px 50px;
  -webkit-box-shadow: 0 10px 55px 5px rgba(196, 196, 196, 0.4);
  box-shadow: 0 10px 55px 5px rgba(196, 196, 196, 0.4); */
}
.pro-content {
  width: 100%;
  padding: 40px 65px;
  /* padding-top: 80px; */
  position: relative;
}

.pro-content2 {
  width: 80%;
  padding: 40px;
  /* padding-top: 80px; */
  margin-left: 10%;
  position: relative;
}
.pro-content-col h2 {
  font-size: 20px;
  font-weight: 700;
  color: #1b2027;
  margin-bottom: 30px;
}
.profile-img {
  /* width: 200px;
  height: 200px; */
  width: 90px;
  height: 90px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 30px;
}
.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.pro-top-banner {
  width: 100%;
}
.pro-top-banner img {
  width: 100%;
}
.doctor-profile h3 {
  font-size: 24px;
  font-weight: 700;
  color: #000;
  margin: 0 0 4px;
  width: 100%;
}
.doctor-profile h4 {
  font-size: 18px;
  font-weight: 500;
  color: #1b2027;
  margin: 0 0 4px;
  width: 100%;
}
.doctor-profile ul.doc-info {
  list-style: none;
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
}
.doctor-profile ul.doc-info li {
  width: 100%;
  margin: 0 0 15px;
  padding: 0;
  font-size: 16px;
  color: #616161;
  display: flex;
  flex-direction: row;
}

.display-flex {
  display: flex;
}
.doctor-profile ul.doc-info li img {
  width: 26px;
  height: auto;
  margin-right: 12px;
}
.doctor-profile ul.doc-info li p,
h5 {
  margin: auto 0;
}
.doctor-profile ul.doc-info li p {
  color: #1b2027;
  font-family: "Rubik", sans-serif;
  text-align: left;
  font-size: 14px;
}
.doctor-profile ul.doc-info li h5 {
  margin: auto 0;
  font-family: "Rubik", sans-serif;
}
.doctor-profile ul.doc-info h5 {
  font-size: 20px;
  color: #1b2027;
  font-weight: 700;
}
.pro-calender-top {
  width: 100%;
  background: #000;
  border-radius: 10px;
}
.follow_up_wrapper {
  margin: 0 3rem !important ;
}
.doctor_info {
  text-align: left;
}
.otp-label {
  color: #1b2027;
  /*float: left;*/
  padding: 3.5px 5px;
  background: #f5f6f7;
  height: 30px;
  border-right: 1px solid #ededed;
}
/* tab css */
.scrtabs-tab-container * {
  box-sizing: border-box;
}
.scrtabs-tab-container .tab-content {
  clear: left;
}

.scrtabs-tabs-fixed-container {
  /* overflow: hidden; */
  /* width: 99%; */
  width: 100%;
  /* border-bottom: 2px solid #ddd; */
  background-color: #fcfcfc;
  margin-bottom: 2rem;
  height: 65.5px;
  overflow: visible;
  border-bottom: 1px solid #fcfcfc;
  border-top: 1px solid #fcfcfc;
}

.scrtabs-tabs-movable-container {
  position: relative;
}
.scrtabs-tabs-movable-container .tab-content {
  display: none;
}

.scrtabs-tab-container.scrtabs-rtl
  .scrtabs-tabs-movable-container
  > ul.nav-tabs {
  padding-right: 0;
}

.scrtabs-tab-scroll-arrow {
  border: 0px solid #dddddd;
  border-top: none;
  color: #fff;
  cursor: pointer;
  display: none;
  float: left;
  font-size: 24px;
  margin-bottom: 0px;
  padding-left: 0px;
  padding-top: 13px;
  width: 25px;
  text-align: center;
}
.scrtabs-tab-scroll-arrow.scrtabs-disable {
  color: #ddd;
  cursor: default;
}

.otpFields {
  display: flex;
  padding: 0 90px 30px 90px !important;
  justify-content: center;
}

.otpFields input {
  margin: 0 10px;
  /*border-bottom: 2px solid #c6c6c6 !important;*/

  border: 1px solid #4bbbe140;
  background-color: #f5f6f7;
  color: #1b2027;
}

.mobileFixedBook {
  display: block;
  background-color: #404760;
  color: #fff;
  padding: 20px;
  bottom: 0%;
  height: 80px;
  position: fixed;
  text-align: center;
  z-index: 9999;
}

.scrtabs-tabs-fixed-container ul.nav-tabs > li {
  white-space: nowrap;
  text-align: left;
  /* padding: 0 25px; */
  padding: 0;
  text-transform: uppercase;
  width: 15%;
  margin: 0;
}
.nav-tabs {
  border-bottom: 0px !important;
}
.nav-tabs::-webkit-scrollbar {
  width: 0px;
}
.scrtabs-tabs-movable-container .nav-tabs {
  background-color: initial;
  padding: 14px 0 0 0;
  /* border-radius: 20px; 
  box-shadow: 0 10px 55px 5px rgba(196, 196, 196, 0.4); */
  flex-wrap: nowrap !important;
  flex-direction: row;
  overflow-x: hidden;
  scrollbar-width: none;
}
.scrtabs-tabs-movable-container .nav-tabs::-webkit-scrollbar {
  display: none;
}
.scrtabs-tabs-fixed-container ul.nav-tabs > li h4 {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 0px;
  padding: 3px 0;
  line-height: 22px;
  text-align: center;
  color: #a3a9b7;
}
.scrtabs-tabs-fixed-container ul.nav-tabs > li h4 span {
  display: block;
  font-size: 14px;
  font-weight: 400;
  width: initial;
}
.scrtabs-tabs-fixed-container ul.nav-tabs > li a {
  color: #1b2027;
  text-decoration: none;
}
.scrtabs-tabs-fixed-container ul.nav-tabs > li.active a {
  color: #eeae01;
  outline: none;
}
.scrtabs-tabs-fixed-container ul.nav-tabs > li.active a:focus {
  outline: none;
}
.pro-calender .tab-content {
  width: 100%;
  margin-top: 30px;
  float: left;
}
.morning-date {
  width: 100%;
  float: left;
  margin-bottom: 35px;
}
.morning-date ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.morning-date ul li {
  float: left;
  margin-right: 15px;
}
.morning-date ul li .time-box {
  width: 120px;
  border: none;
  margin-bottom: 15px;
  height: 50px;
  font-size: 18px;
  font-weight: 700;
  background-color: #f5f6f7;
  border-radius: 10px;
  color: #17bae0;
  text-align: center;
  line-height: 50px;
}
.morning-date ul li.active .time-box {
  background-color: #b9e35f;
}
.morning-top {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #1b2027;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding: 10px 8px;
}
.morning-top img {
  margin-right: 10px;
  height: 16px;
}
.pro-bottom-col {
  width: 100%;
  float: left;
}
.pro-bottom-col h5 {
  font-size: 18px;
  float: left;
  line-height: 40px;
}
.pro-bottom-col h5 span {
  font-weight: 700;
  color: #f969a4;
}
.proc-btn {
  font-size: 16px;
  color: #fff;
  border: none;
  margin-top: 20px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  width: 150px;
  height: 45px;
  line-height: 45px;
  border-radius: 40px;
  background-color: #17bae0;
  text-align: center;
  float: right;
}
.proc-btn:hover {
  color: #fff;
  background-color: #09a4ce;
  text-decoration: none;
}
.next-btn {
  font-size: 16px;
  color: #fff;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  width: 130px;
  height: 45px;
  line-height: 45px;
  border-radius: 40px;
  background-color: #17bae0;
  text-align: center;
  float: right;
}
.next-btn:hover {
  color: #fff !important;
  background-color: #09a4ce;
  text-decoration: none;
}
/*.modal-body{ padding: 90px 15px 60px !important;}*/
/* .close {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #17bae0 !important;
} */
.modal-content {
  /*background: url(../images/modal-img.png) no-repeat top center; */
  border-radius: 0px !important;
  padding: 30px 40px;
  background-color: #fff;
}
.pro-img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
  margin-right: 30px;
}
.pro-img img {
  width: 100%;
}
.doctor-profile ul.no-record {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.doctor-profile ul.no-record li {
  font-size: 20px;
  color: #cbcbca;
  font-weight: 700;
  margin-top: 50px;
}
ul.user-list {
  list-style: none;
  margin: 50px 0 0;
  padding: 0px;
}
ul.user-list li {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: 700;
  color: #eeae01;
}
.doc-profile {
  width: 100%;
  float: left;
  margin-bottom: 30px;
}
.doc-profile h3 {
  font-size: 20px;
  color: #000;
  font-weight: 700;
  margin-top: 30px;
}
.doc-profile h4 {
  font-size: 20px;
  color: #1b2027;
  font-weight: 400;
}
.payment-col {
  width: 100%;
  float: left;
}
.payment-col h5 {
  font-size: 18px;
  font-weight: 600;
}
.payment-col h6 {
  font-size: 21px;
  font-weight: 700;
  color: #eeae01;
  margin-bottom: 30px;
}

/* responsive css */

@media (min-width: 320px) and (max-width: 991px) {
  .scrtabs-tabs-fixed-container {
    width: 100%;
  }
  .book-appoint-header .active-index .book-appoint-text {
    font-size: 16px;
  }
  .pro-content {
    padding: 0 15px;
    padding-top: 50px;
  }
  .pro-content2 {
    padding: 0 15px;
    padding-top: 50px;
    margin-left: 0;
  }
  .scrtabs-tab-container {
    width: 100%;
    /* float: left; */
    border-radius: 10px;
    display: flex;
  }
  .scrtabs-tabs-fixed-container ul.nav-tabs > li h4 {
    font-size: 18px;
  }
  .doctor-profile h3 {
    font-size: 20px;
  }
  .doctor-profile ul.doc-info li {
    font-size: 14px;
  }
  .morning-date ul li .time-box {
    width: 100px;
    height: 45px;
    line-height: 45px;
    font-size: 14px;
  }
  header {
    padding: 10px 20px;
  }

  .scrtabs-tabs-movable-container .nav-tabs {
    padding: 6px 0 0 0;
    overflow-x: scroll;
  }
}

@media (min-width: 992px) and (max-width: 1400px) {
  .scrtabs-tabs-movable-container .nav-tabs {
    padding: 14px 0 0;
    overflow-x: scroll;
  }
}

@media (min-width: 320px) and (max-width: 600px) {
  .morning-date ul li .time-box {
    width: 90px;
    height: 40px;
    line-height: 40px;
  }
  .morning-top {
    font-size: 14px;
  }
  .morning-top img {
    height: 30px;
  }
  .scrtabs-tabs-fixed-container ul.nav-tabs > li {
    padding: 0 15px;
  }
  .logo img {
    height: 45px;
  }
  .login-btn {
    width: 100px;
    height: 40px;
    line-height: 36px;
    margin-top: 0px;
  }
  .pro-bottom-col h5 {
    float: none;
    text-align: center;
  }
  .next-btn {
    display: block;
    margin: 0px auto;
    float: none;
  }
  .pro-bottom-col {
    margin-bottom: 20px;
  }
}

@media (min-width: 320px) and (max-width: 678px) {
  .book-appoint-header.book-appoint-header1-mob {
    display: flex !important;
    margin: 20px 15px;
    align-items: center;
    justify-content: space-between;
  }
  .book-appoint-header.book-appoint-header1 {
    display: none !important;
  }
}
.book-appoint-header.book-appoint-header1-mob {
  display: none;
}
.book-appoint-header.book-appoint-header1 {
  display: flex;
}
.successThumbs {
  font-size: 160px;
  text-align: center;
  color: #f969a4;
}

.proceed_to_pay_btn:hover {
  color: white !important;
}
.line {
  width: 100%;
  height: 1px;
  background-color: #d6dae5;
  margin-bottom: 25px;
}
@media (min-width: 992px) {
  .doctor-profile {
    background-color: #fff;
    border-radius: 6px;
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    /* justify-content: center;
    text-align: center; */
    padding: 0 !important;
    box-shadow: 0px 3px 6px #8888880d;
    /* max-height: 80vh; */
  }
}

.doctor-profile {
  min-height: 335px;
  max-height: fit-content;
  background-color: #fff;
  border-radius: 6px;
  align-content: flex-start;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* justify-content: center;
  text-align: center; */
  padding: 0 !important;
  box-shadow: 0px 3px 6px #8888880d;
}

.pro-calender {
  width: 100%;
  /* padding-left: 30px;
  float: left; */
  background-color: #fff;
  border-radius: 6px;
}

.initial-follow-switching .initial-btn {
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  width: 130px;
  height: 45px;
  border-radius: 40px;
  border: none;
  color: #fff;
  background-color: #17bae0;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.initial-follow-switching .follow-up-btn {
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  width: auto;
  text-align: center;
  color: #17bae0;
  background-color: initial;
  font-size: 14px;
  cursor: pointer;
  padding-right: 2rem;
  padding-left: 1rem;
}
.initial-follow-switching .follow-up-btn:hover {
  background-color: initial;
}

.book-appoint-video-btn {
  height: 46px;
  background-color: #1b2027;
  color: #fff;
  transform: rotate(-90deg);
  position: absolute;
  top: 13rem;
  right: -98px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
}
.book-appoint-video {
  padding: 0;
}
.book-appoint-video img {
  transform: rotate(90deg);
}
.book-appoint-video span {
  color: #fff;
  margin-left: 8px;
  font-size: 14px;
  text-transform: uppercase;
}
.modal-dialog.book-appoint-video-modal {
  position: absolute;
  right: 0;
  width: 70%;
  height: 100%;
  margin: 0;
  background: #fff;
}
.book-appoint-video-modal .modal-header {
  align-items: center;
  justify-content: center;
  padding: 22px 7%;
  border-bottom: none;
}
.book-appoint-video-modal .modal-header p {
  margin-bottom: 0;
  font-size: 17px;
}
.book-appoint-video-modal .modal-header .close {
  color: #000 !important;
  font-size: 14px;
  opacity: 1;
  font-weight: 300;
  padding: 0;
  margin: 0;
  position: absolute;
  right: 7%;
}
.book-appoint-video-modal .modal-body.book-appoint-video-modal-body {
  padding: 0 7% !important;
}
.video-col2.appoint-footer-banner {
  padding: 0 0 5rem 0;
  background: whitesmoke;
}
.appoint-footer-banner .container {
  padding: 0 0 0 6rem;
  background: #e4f3f7;
  border: 4px solid #fff;
  border-radius: 12px;
  width: auto;
  max-width: unset;
  margin: 0 65px;
}
.appoint-footer-banner .row {
  align-items: center;
  width: 100%;
  margin: 0;
}
.appoint-footer-banner .banner-text p {
  font-size: 24px;
  color: #000;
  width: 70%;
  text-align: left;
  font-weight: bold;
}
.appoint-footer-banner .banner-image {
  padding: 0;
}
.appoint-footer-banner .banner-image img {
  margin-top: 10px;
}
.banner-subtext img {
  margin-right: 6px;
  transform: unset;
}
.banner-subtext span {
  color: #17bae0;
  font-size: 15px;
  font-weight: 600;
}
/* .w-100 {
  background-color: #fff;
  display: flex;
  box-shadow: 0px 0px 20px #8888881f;
  align-items: center;
  justify-content: flex-end;
  padding-right: 65px;
} */
.time-fees,
.doctor-details,
.doctor-qualification-details {
  width: 100%;
}
.time-fees {
  order: 2;
}
.doctor-details {
  order: 1;
  padding-bottom: 8px;
  text-align: left;
}

.time-fees .consultationInformation {
  width: 100%;
}
.consultationInformation_wrapper {
  width: 100% !important;
}
.book-appoint-header1,
.book-appoint-header2,
.book-appoint-header3 {
  padding: 10px 5rem 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.book-appoint-date-time11,
.book-appoint-date-time12,
.book-appoint-date-time13 {
  width: 33.33%;
  white-space: nowrap;
  display: flex;
  align-items: center;
}
.book-appoint-index {
  border-radius: 50%;
  margin-right: 4%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-self: center;
}
.book-appoint-img-index {
  margin-right: 4%;
}
.book-appoint-text {
  color: #000;
  text-transform: capitalize;
  font-weight: 600;
}
.book-appoint-date-time11 .book-appoint-index,
.book-appoint-date-time12 .book-appoint-index,
.book-appoint-date-time13 .book-appoint-index {
  background: initial;
  color: #707070;
  border: 1px solid #707070;
  padding: 6px 11px;
}
.book-appoint-date-time11 .book-appoint-text,
.book-appoint-date-time12 .book-appoint-text,
.book-appoint-date-time13 .book-appoint-text {
  opacity: 0.6;
}
.book-appoint-date-time11 .index-opacity,
.book-appoint-date-time12 .index-opacity,
.book-appoint-date-time13 .index-opacity {
  opacity: 1;
}
.book-appoint-header .active-index .book-appoint-index {
  background: #f969a4;
  color: #fff;
  border: none;
}
.book-appoint-header .active-index .book-appoint-text {
  opacity: 1;
}
.initial-follow-switching {
  border-radius: 50px;
  opacity: 1;
  width: fit-content;
  margin: auto;
  align-self: center;
  justify-content: space-between;
  background: #f5f5f5;
  border: 1px solid #ffffff;
  display: flex;
  text-transform: uppercase;
}
.duration-block,
.fees-block,
.about-block,
.education-block {
  margin-left: 15px;
  text-align: left;
}
.duration-text,
.fees-text,
.about-text,
.education-text {
  color: #0f0f0f;
  opacity: 0.5;
  font-size: 12px;
}
.duration-block .duration-text,
.fees-block .fees-text {
  opacity: 0.5;
  color: #0f0f0f;
}
.duration-block p,
.fees-block p {
  color: #17bae0;
}
.book-appoint-arrow {
  margin-left: 10px;
  height: 13px;
  width: auto;
}
.continue-btn-outer button {
  width: auto;
  padding: 0 48px;
}
.mobileFixedBook.back_continue button {
  width: 130px;
}
.mob-valid-till {
  display: none;
}
@media (min-width: 320px) and (max-width: 767px) {
  .doctor-heading-patient.doc-profile,
  .doctor-heading-patient.followup-details,
  .doctor-heading-patient.select-pat,
  .doctor-heading-patient,
  .doctor-heading-patient.followup {
    display: none;
  }
  .booking-date.d-flex.align-items-center {
    padding-top: 20px;
    padding-right: 25px;
  }

  .valid-till {
    display: none;
  }
  .mob-valid-till {
    display: block;
    margin-bottom: 20px;
  }
  .book-appoint-date-time11 {
    font-size: 16px;
  }
  .mobileFixedBook {
    display: block;
    color: #fff;
    padding: 20px;
    bottom: 0%;
    height: 80px;
    position: fixed;
    text-align: center;
    z-index: 9999;
  }
  .follow_up_wrapper {
    margin: 0 !important;
  }
  .BookFollowUp {
    padding: 0 !important;
    margin-top: 15px;
    text-align: center;
  }
  .patient-doctor-profile .media .media-body {
    margin-left: 1rem;
  }
  .mobileFixedBook h5 {
    font-size: 14px;
  }

  .phoneInputOtp {
    margin-left: -5px !important;
    /*width: 90% !important;*/
  }

  .otpFields {
    display: flex !important;
    padding: 0 30px 30px 30px !important;
    justify-content: center;
  }

  .otpFields input {
    border-radius: 0;
    width: 30px !important;
  }

  .profile-img {
    margin: 0px auto 30px;
    width: 120px;
    height: 120px;
  }
  .doctor-profile h3 {
    text-align: center;
  }
  .doctor-profile h4 {
    text-align: center;
  }
  .doctor-profile small {
    text-align: center;
  }
  .pro-calender {
    padding-left: 0px;
    margin-top: 30px;
    border-radius: unset;
    box-shadow: unset;
  }
  .pro-content-col h2 {
    font-size: 20px;
    text-align: center;
  }

  .logo img {
    height: 60px;
  }
  .login-btn {
    width: 130px;
    height: 45px;
    line-height: 42px;
    font-size: 16px;
    margin-top: 0px;
  }
  .pro-content {
    padding: 30px 0;
  }
  .proc-btn {
    margin-bottom: 20px;
  }
  .pro-content2 {
    padding: 0 15px;
    padding-top: 30px;
    width: 100%;
    margin-left: 0;
  }
  .payment-col h5 {
    text-align: center;
  }
  .payment-col h6 {
    text-align: center;
  }
  .doc-profile {
    text-align: center;
  }
  .pro-img {
    margin: 0px auto !important;
    float: none;
  }
  .doctor-profile ul.no-record li {
    text-align: center;
  }
  ul.user-list li {
    text-align: center;
  }
  .pro-bottom-col h5 {
    font-size: 14px;
  }
  .next-btn {
    width: 100px;
    height: 40px;
    line-height: 38px;
  }
  .morning-date ul li .time-box {
    width: 90px;
    height: 40px;
    line-height: 38px;
    font-size: 14px;
  }
  .morning-top {
    font-size: 16px;
  }
  .morning-top img {
    height: 30px;
  }
  .select-dt-wrapper .pro-content-col {
    padding: 0;
    margin-top: 0;
  }

  .scrtabs-tabs-fixed-container {
    margin-bottom: 2rem;
  }
  .scrtabs-tabs-fixed-container ul.nav-tabs > li {
    padding: 0;
    width: 33.33%;
    min-width: 95px;
  }
  .scrtabs-tabs-fixed-container ul.nav-tabs > li h4 {
    padding: 0 0 14px 0;
  }
  .appoint-footer-banner .container {
    padding: 20px 0 0 6px;
    margin: 0 15px;
  }
  .appoint-footer-banner .banner-text p {
    font-size: 19px;
    width: 100%;
    margin-bottom: 15px;
  }
  .banner-subtext span {
    margin-left: 0;
  }
  .video-col2.appoint-footer-banner {
    padding: 0 0 3.5rem 0;
  }
  .modal-dialog.book-appoint-video-modal {
    width: 90%;
  }
  .book-appoint-video-modal .modal-header {
    padding: 22px 4%;
    justify-content: flex-start;
  }
  .book-appoint-video-modal .modal-header .close {
    right: 4%;
  }
  .book-appoint-video-modal .modal-header p {
    font-size: 4.5vw;
  }
  .book-appoint-video-modal .modal-body.book-appoint-video-modal-body {
    padding: 0 3% !important;
  }
  .book-appoint-header .book-appoint-date-time11,
  .book-appoint-header .book-appoint-date-time12,
  .book-appoint-header .book-appoint-date-time13 {
    width: auto;
  }
  .book-appoint-header1 .book-appoint-date-time12 .book-appoint-text,
  .book-appoint-header1 .book-appoint-date-time13 .book-appoint-text {
    display: none;
  }
  .book-appoint-header2 .book-appoint-date-time11 .index-opacity,
  .book-appoint-header2 .book-appoint-date-time13 .index-opacity {
    display: none;
  }
  .book-appoint-header3 .book-appoint-date-time11 .index-opacity,
  .book-appoint-header3 .book-appoint-date-time12 .index-opacity {
    display: none;
  }
  .book-appoint-header1,
  .book-appoint-header2,
  .book-appoint-header3 {
    justify-content: space-between;
    padding: 20px 4% 30px;
  }
  /* .book-appoint-video-btn {
    display: none;
  } */
  .book-appoint-video-btn {
    right: 5px;
    top: 5rem;
  }
  .book-appoint-video span {
    display: none;
  }
  .book-appoint-video img {
    transform: rotate(-180deg);
  }
}

.ai-center {
  align-items: center !important;
}

.activeTab {
  color: #17bae0 !important;
}

.check-icon-filter {
  font-size: 12px;
  color: transparent;
}

.heading-appt {
  color: #2f2f2f;
  font-size: 18px;
  font-weight: 600;
}

.patient-doctor-profile {
  width: 100%;
}
.doctor-heading-patient.followup {
  margin: 20px 3rem 20px !important;
}
/* .doctor-heading-patient.followup-details */
.doctor-heading-patient.followup-details {
  margin: 0px 3rem 30px !important;
}
.doctor-heading-patient {
  background: #e4f3f7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 16px;
  font-size: 16px;
  line-height: 24px;
  display: block;
  border: 3px solid white;
}

.patient-doctor-profile .doctor-profile-div {
  margin: 0 auto 16px;
}
.patient-doctor-profile .doctor-details {
  text-align: center;
}
.BookFollowUp {
  text-align: center;
}

.custom-btn-div {
  display: flex;
  justify-content: center;
}

.custom-btn.border {
  align-items: center;
  border-color: #10a74a !important;
  display: flex;
  max-height: 38px;
}

.call-icon-div {
  background: #10a74a;
  border: 0;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  text-align: center;
  vertical-align: baseline;
  margin-right: 6px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-top: 2px;
}

.call-icon-div[data-tooltip]::after,
.call-icon-div[data-tooltip]::before {
  z-index: 999;
}

.call-icon-div h4 {
  margin: 0px;
  width: 30px;
  height: 31px;
  border-radius: 50%;
  line-height: initial;
}

.call-icon {
  color: white;
  font-size: 18px;
}

@media (max-width: 767px) {
  .mobile_view_p_l_0 {
    padding-left: 0px !important;
  }
  .mobile_view_p_0 {
    padding: 0px !important;
  }
  .custom-btn-div.mobile_view .custom-btn.border {
    border: 0px !important;
  }
  .custom-btn-div.mobile_view .call-icon-div {
    width: 38px;
    height: 38px;
    margin-top: 2px;
    margin-left: 5px;
  }
  .custom-btn-div.mobile_view .call_by_text {
    display: none;
  }
  .custom-btn-div.mobile_view {
    position: relative;
  }
  .login-btn {
    margin-left: 0px !important;
    padding: 0px !important;
    float: left !important;
    width: calc(100% - 10px) !important;
  }
  .next-appointment-widget .call-icon {
    font-size: 18px;
  }
  .call-icon {
    font-size: 22px;
  }
}
