@use "../../../../Util/c2cConstants/colorConstants.scss" as color;
@use "../../../../Util/c2cConstants/fontFileConstants.scss" as font;

.share-with-pharmacy-popup {
  width: 315px;
  font-family: font.$C2C_FONT_FAMILY;

  .c2c-custom-popup-container {
    .c2c-custom-popup-header {
      border-bottom: none;
      .c2c-custom-popup-header-title {
        color: #000;
        font-size: 16px;
        font-weight: 300;
      }
    }

    .c2c-custom-popup-cont {
      padding: 7px 30px 7px 30px;
      max-height: 300px;
      margin-right: 10px;
      overflow: auto;

      .custom-checkbox-container {
        align-items: start;

        .custom-checkbox-title {
          color: #000 !important;
          font-size: 14px;
          font-weight: 400;
          overflow-wrap: break-word;
          width: 200px;
        }
      }
    }

    .c2c-custom-popup-footer {
      margin: 17px 0px;

      .primay-button-container {
        min-width: fit-content;
        padding: 8px 16px;
        color: color.$WHITE;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .share-with-pharmacy-popup {
    width: 100%;

    .c2c-custom-popup-container {
      .c2c-custom-popup-cont {
        max-height: 50vh;

        .custom-checkbox-container {
          .custom-checkbox-title {
            width: 70vw;
          }
        }
      }
    }
  }
}
