  .menu-bars {
    font-size: 2rem;
    background: none;
  }
   .nav-menu {
    background-color: #ffff;
    width: 310px;
    height: 100vh;
    position: fixed;
    top: 0;
    z-index: 101;
    left: -100%;
    transition: 850ms;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
    box-shadow: 0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12);
  }
  
  .nav-text {
    display: flex;
    padding: 8px;
    height: 20px;
  }
  
  .nav-text a {
    font-size: 16px;
    width: 100%;
    font-weight: 600;
    display: flex;
    color: #000000;
    align-items: center;
    padding: 0 16px;
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    height: 80px;
    display: flex;
    align-items: center;
  }
  // span {
  //   margin-left: 16px;
  // }
  .customer_support .image-style {
       display: flex;
       margin: 10px 0px;
  }
  .customer_support{
    padding: 30px 35px;
    background: #FAFAFA;
  }
  .customer_support  img {
    margin-right: 10px;
  }
.customer_support a{
  color: #2F2F2F;
  font-size: 14px;
}
li hr:not(:last-child):after { 
  content: ' '; 
  color:green; 
  font-weight:bold; 
} 
.mob-loginsection  a{
  color: #2F2F2F;
    padding: 10px 20px;
    font-size: 14px;
}
.dropdown-menu li{
  margin: 5px;
}
.nav-menu-items li{
  width: 100%;
}
.nav-menu-items  li{
  border-bottom: 2px solid lightgrey;
  padding: 20px 18px;
  display: flex;
  margin: 0px 40px;
   width: 75%;
  flex-direction: column;
}
.nav-menu-items li:nth-last-child(1) , .nav-menu-items li:nth-last-child(6) {
  border-bottom:none;
}
.nav-menu-items span{
  margin-left: -16px;
  color: #000;
}
.navbar-toggle{
  padding: 30px !important;
  margin: 0px -60px !important;
}