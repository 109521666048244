.PrescriptionSharedModal-background.show {
  z-index: 1500;
}

.modal-buttons {
  padding: 10px 30px;
  border-radius: 40px;
  background-color: white;
  border: 1px solid #17bae0;
  color: #17bae0;
}

.modal-buttons:hover {
  background-color: #17bae0;
  border: 1px solid #17bae0;
}

.custom-modal {
  padding-top: 190px;
  padding-left: 180px;
  padding-right: 200px;
  min-height: 100vh;
}

.modal-buttons2 {
  padding: 10px 60px;
  border-radius: 40px;
  background-color: #17bae0;
  color: white;
  border: none;
}

.modal-buttons2:hover {
  background-color: #17bae0;
}

.modal-buttons2:active {
  background-color: #17bae0;
}

.header-text {
  padding-bottom: 10px;
  font-size: 25px;
  font-weight: bold;
}

.pem-header-content {
  padding: 0px 50px 10px;
}

@media screen and (max-width: 768px) {
  .header-text {
    font-size: 20px;
  }
  .pem-header-content {
    padding: 0px 0px 10px;
  }
}

.body-text {
  padding-bottom: 30px;
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .body-text {
    font-size: 16px;
  }
}

@media screen and (device-aspect-ratio: 40/71) {
  .custom-modal {
    padding: 0;
    height: auto;
  }

  .buttons-div {
    display: block;
    align-items: center;
  }

  .modal-buttons {
    padding: 5px 10px;
    display: block;
  }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
  .custom-modal {
    padding: 0;
    height: auto;
    padding-top: 300px;
  }
}

@media screen and (min-width: 768px) {
  .custom-modal {
    padding: 0;
    height: auto;
    padding-top: 350px;
  }
}

@media screen and (min-width: 280px) {
  .custom-modal {
    padding: 0;
    padding-top: 50px;
  }

  .buttons-div {
    align-items: center;
    justify-content: space-evenly;
  }

  .modal-buttons {
    padding: 5px 20px;
    display: block;
    align-self: center;
    justify-content: space-around;
  }
}

.custom-modal-button {
  width: 50%;
  border-radius: 50px;
}

@media screen and (max-width: 768px) {
  .custom-modal-button {
    font-size: 14px;
  }
}

.yes-button {
  background-color: #17bae0;
  border-color: #17bae0;
  color: "#fff";
}

.yes-button:hover {
  border-color: #17bae0;
}

.yes-button:active {
  border-color: #17bae0 !important;
}

.no-button {
  background-color: #fff;
  color: #17bae0;
  border-color: #fff;
}

.no-button:hover {
  background-color: #fff;
  color: #17bae0;
  border-color: #fff;
}

.no-button:active {
  background-color: #fff !important;
  border-color: #fff !important;
  color: #17bae0 !important;
}

.prescription-shared-modal-wrapper {
  background: #fff;
  border-radius: 10px;
  max-width: 640px;
}

@media only screen and (min-width: 360px) and (max-width: 600px) {
  .prescription-shared-modal-wrapper {
    width: 95%;
  }
}
