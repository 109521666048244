@media screen and (max-width: 559px) {
  .headerr {
    height: 60px;
    padding-left: 6px; /*16px ;*/
    display: relative;
    min-height: auto !important;
  }
  .doctor-appoitmrnt {
    min-height: auto !important;
  }
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .dr-welcom-sec h3 {
    font-size: 21px;
  }
  .name,
  .link-up,
  .vieww,
  .timee {
    width: 50%;
  }
  .link-up {
    margin-bottom: 10px;
  }
  .vieww,
  .timee {
    text-align: left;
  }
  .dr-prfl {
    padding: 0px;
  }
}
@media screen and (min-width: 960px) {
  /*.headerr {
		    height: 70px;
		    padding-left: 24px;
		  }*/
  /* .dr-welcom-sec h3 {
  } */
}
@media screen and (min-width: 1200px) {
  .headerr {
    /* padding-left: 32px; */
  }
}
.header__logo {
  display: inline-block;
  height: auto;
  text-decoration: none;
  color: #000;
  text-align: center;
  width: 100%;
  margin-top: 40px;
}
@media screen and (max-width: 559px) {
  .header__logo {
    text-align: left;
    margin-top: 15px;
  }
  .doctor-appoitmrnt {
    padding: 20px 10px 0px 15px;
    /* margin-top: 25px; */
  }
  .prfl-tt {
    display: inline-block;
  }
  .prfl-tt h3 span {
    display: block;
  }
  .prfl-tt a {
    float: right;
  }
  .dr-prfl img {
    margin: 0 auto;
    display: block;
    margin-top: 15px;
  }
}
@media screen and (min-width: 560px) and (max-width: 959px) {
  .headerr {
    min-height: auto !important;
  }
  .doctor-appoitmrnt {
    min-height: auto !important;
  }

  .header__logo {
    font-size: 24px;
    letter-spacing: 0.05em;
    margin-top: 15px;
    padding-bottom: 15px;
  }
  .dr-prfl ul li {
    width: 100%;
  }
  .prfl-tt h3 span {
    display: block;
  }
  .prfl-tt {
    display: inline-block;
  }
  .prfl-tt a {
    float: right;
  }
  .dr-prfl img {
    margin: 0 auto;
    display: block;
    margin-top: 15px;
  }
}
@media screen and (min-width: 960px) {
  .header__logo {
    font-size: 26px;
    letter-spacing: 0.05em;
  }
}
@media screen and (max-width: 959px) {
  .header__nav-wrap {
    position: fixed;
    left: 0;
    top: 0;
    display: none;
    z-index: 10;
    background-color: #1b2027;
    width: 100%;
    height: 100%;
  }

  .doctor-appoitmrnt {
    width: 100%;
    background: #fff;
    padding: 20px 15px 0px 15px;
    min-height: 750px;
  }

  .docScheduleHeight {
    max-height: 92px !important;
  }
}
@media screen and (min-width: 960px) {
  .header__nav-wrap {
    display: inline-block;
    height: auto;
    width: auto;
    margin-left: auto;
  }
}
.header__nav-wrap.open {
  display: block;
  overflow-y: scroll;
}
.header__nav-wrap.close {
  display: none;
}
.header__nav-list {
  list-style: none;
  height: 100%;
  margin: 0;
  padding: 0;
}
@media screen and (max-width: 959px) {
  .header__nav-list {
    position: relative;
    overflow-x: hidden;
    overflow-y: auto;
    padding-top: 8vh;
  }
}
@media screen and (min-width: 960px) {
  .header__nav-list {
    /*display: flex;*/
    align-items: center;
  }
}
@media screen and (max-width: 559px) {
  .header__nav-item {
    display: block;
    margin: 0 auto;
    margin-bottom: 32px;
    text-align: center;
  }
}
@media screen and (min-width: 560px) and (max-width: 959px) {
  .header__nav-item {
    display: block;
    margin: 0 auto;
    margin-bottom: 40px;
    text-align: center;
  }
}
@media screen and (min-width: 960px) {
  .header__nav-item {
    display: inline-block;
    /*margin-right: 24px;*/
  }
}
/* @media screen and (min-width: 1200px) { */
.header__nav-item {
  font-weight: 400;
  width: 100%;
  font-size: 16px;
  color: #5ad5f2;
  padding: 13px 18px;
}
/* } */
.header__nav-link {
  cursor: pointer;
  text-decoration: none;
  color: #17bae0;
}

.header__nav-item-active {
  background: #5ad5f2 0% 0% no-repeat padding-box;
  color: #ffffff !important;
}

@media screen and (max-width: 959px) {
  .header__nav-link {
    font-size: 14px;
    cursor: pointer;
  }
  .mobWidthHeader {
    max-width: 100% !important;
  }
  .patientList {
    text-align: center;
  }
}
@media screen and (min-width: 960px) {
  .header__nav-link {
    font-size: 14px;
    margin: 14px 0px;
    display: inline-block;
    cursor: pointer;
  }
}
@media screen and (min-width: 1200px) {
  .header__nav-link {
    font-size: 16px;
    font-weight: 400;
    color: #17bae0;
    width: 100%;
    cursor: pointer;
    transition: 0.5s;
  }
}
.header__nav-link:hover {
  color: #09a4ce;
  text-decoration: none;
  cursor: pointer;
}

.header__nav-link:not(:hover) {
  color: #17bae0;
}

@media screen and (max-width: 959px) {
  .ham-menu {
    box-sizing: border-box;
    margin-left: auto;
    margin-right: 25px;
    transition: all 0.3s;
    z-index: 20;
    position: absolute;
    width: 23px;
    height: 23px;
    background-color: #17bae0;
    display: flex;
    justify-content: center;
    border-radius: 2px;
    cursor: pointer;
    top: 20px;
    right: 0;
  }
  .header__logo {
    margin-top: 0 !important;
  }
}
@media screen and (min-width: 960px) {
  .ham-menu {
    display: none;
  }
}
@media screen and (max-width: 959px) {
  .ham-menu__line {
    display: inline-block;
    box-sizing: border-box;
    transition: all 0.3s;
    position: absolute;
    width: 16px;
    height: 2px;
    background-color: #f5f6f7;
    border-radius: 2px;
  }
}

@media screen and (max-width: 767px) {
  .name,
  .link-up {
    width: 100%;
  }
  .vieww,
  .timee {
    width: 50%;
  }
}
