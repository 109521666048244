.finance-header-font {
  font-size: 19px;
}

.finance-header-font-weight {
  font-weight: 900;
}

.finance-back-button {
  width: 120px;
  height: 35px;
  background-color: #0ebaf3;
  font-size: 13px;
  border-radius: 20px;
  color: white;
  border: none;
}
