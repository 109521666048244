@use "../../Util/c2cConstants/colorConstants.scss" as color;
@use "../../Util/c2cConstants/fontFileConstants.scss" as font;

.dacc-downloadapp-and-call-buttons-wrapper {
    height: 15vh;
    .dacc-download-app-text {
        text-align: left;
        font: normal normal normal 20px/24px font.$C2C_FONT_FAMILY;
        letter-spacing: 0px;
        color: #242424;
        opacity: 1;
        margin-bottom: 5px;
      }
      .dacc-download-btn-container {
        display: flex;
        height: 50%;
        align-items: center;
      }
      .dacc-download-app-btn {
        background: #fff5d3 0% 0% no-repeat padding-box;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 155px;
        height: 50px;
        margin-right: 15px;
        font: normal normal 500 16px/19px font.$C2C_FONT_FAMILY;
        color: #000000;
        cursor: pointer;
        text-decoration: none;
      }
      .dacc-apple-play-logo {
        width: 30px;
        height: 25px;
        margin-right: 6px;
      }

  .dacc-contact-email-coontainer {
    display: flex;
    height: 25%;
    align-items: center;
  }
  .dacc-c2c-contact {
    margin-right: 20px;
  }
  .dacc-c2c-contact-logo {
    margin-right: 7px;
  }

  .dacc-contact-btn-text {
    font: normal normal normal 14px/17px font.$C2C_FONT_FAMILY;
    letter-spacing: 0px;
    color: #1a1a1a;
    cursor: pointer;
    text-decoration: none;
  }
  }
  