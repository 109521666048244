@use "../../../Util/c2cConstants/colorConstants" as color;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.abha-aadhar-validation-view {
  .aavv-input-container-view {
    display: flex;
    justify-content: center;
  }

  .aavv-input-container {
    margin-right: 8px;
    background: #f8f8f8 0% 0% no-repeat padding-box;
    border: 1px solid #efefef;
    border-radius: 5px;
    width: 130px;
    height: 50px;
    display: flex;
    align-items: center;
  }

  .aavv-input {
    width: inherit;
    background: none;
    border: none;
    text-align: center;
    font: normal normal normal 14px/2px font.$C2C_FONT_FAMILY;
    letter-spacing: 0px;
    color: color.$C2C_DARK_GRAY;
  }

  .aavv-input::placeholder {
    color: #bebebe;
    position: relative;
    top: 3px;
  }

  .aavv-input-container-error {
    border: 1px solid #fd1717;
  }

  .aavv-input-label {
    font-size: font.$PARAGRAPH_REGULAR;
    line-height: 22px;
    font-family: font.$C2C_FONT_FAMILY;
    font-weight: 500;
    color: color.$C2C_CYNICAL_BLACK;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 12px;
  }

  .aavv-input-error {
    font-family: font.$C2C_FONT_FAMILY;
    font-size: font.$PARAGRAPH_REGULAR;
    line-height: 17px;
    color: #fd1717;
    margin-left: 35px;
    margin-top: 8px;
  }

  .aavv-about {
    text-align: center;
    font-size: font.$PARAGRAPH_REGULAR;
    line-height: 22px;
    font-family: font.$C2C_FONT_FAMILY;
    color: color.$C2C_CYNICAL_BLACK;
    margin-bottom: 16px;
    padding: 0px 10px;
  }

  .aavv-subheading {
    text-align: center;
    font-size: font.$HEADING_4;
    line-height: 36px;
    font-family: font.$C2C_FONT_FAMILY;
    color: color.$C2C_CYNICAL_BLACK;
    margin-bottom: 16px;
    padding: 0px 5px;

    .aavv-subheading-bold {
      font-weight: 600;
    }
  }

  .aavv-ci {
    text-align: center;
    font-size: font.$PARAGRAPH_SMALL;
    line-height: 20px;
    font-family: font.$C2C_FONT_FAMILY;
    color: color.$C2C_CYNICAL_BLACK;
    margin-top: 16px;
    padding: 10px;
  }

  .aavv-button-container {
    display: flex;
    justify-content: center;
    margin-top: 24px;
  }

  .aavv-terms-container {
    margin-top: 15px;
    display: flex;
    align-items: flex-start;
    padding: 0px 45px 0px 45px;
  }

  .aavv-terms-checkbox {
    background: #d6dae5 0% 0% no-repeat padding-box;
    width: 40px;
    height: 20px;
    border-radius: 5px;
    margin-right: 10px;
    justify-content: center;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
  }

  .aavv-terms-checkbox-selected {
    background: #17bae0;
  }

  .add-time-range-day-selector-copy-text {
    font: normal normal normal 14px/17px Rubik, sans-serif;
    color: #21252a;
    cursor: pointer;
  }

  .add-time-range-terms-text {
    font: normal normal normal 13px/17px Rubik, sans-serif;
    color: #21252a;
  }

  .aavv-main-heading {
    text-align: center;
    font-weight: 600;
    font-size: 30px;
    line-height: 36px;
    font-family: font.$C2C_FONT_FAMILY;
    color: color.$C2C_CYNICAL_BLACK;
    margin: 0px 20px;
  }

  .aavv-terms-link-selected {
    color: color.$C2C_WINE_BLUE;
    font-size: 12px;
    text-decoration: underline;
  }

  .aavv-terms-Learn-more {
    color: color.$C2C_LIGHT_BLUE;
    padding-left: 5px;
    text-decoration: underline;
  }

  .aavv-terms-tac {
    color: color.$C2C_LIGHT_BLUE;
    text-decoration: underline;
    margin-left: 5px;
  }

  @media screen and (max-width: 768px) {
    .aavv-input-container {
      margin-right: 8px;
      background: #f8f8f8 0% 0% no-repeat padding-box;
      border: 1px solid #efefef;
      border-radius: 5px;
      width: 100px;
      height: 50px;
      display: flex;
      align-items: center;
    }

    .aavv-terms-checkbox {
      width: 50px;
    }

    .aavv-terms-container {
      padding: 0;
    }
  }
}
.abha-terms-and-conditions-popup {
  overflow: hidden;
  width: 100%;
  .abha-terms-and-condition-popup-body {
    .terms-and-condition-div {
      font: font.$C2C_FONT_FAMILY;
      h3 {
        font-size: 24px;
        color: color.$C2C_BLUE;
      }
      p {
        color: color.$C2C_EERIE_BLACK;
      }
    }
  }

  .c2c-custom-popup-container {
    .c2c-custom-popup-cont {
      height: 80vh;
      overflow-y: scroll;
    }
  }
}
