@use "../../Util/c2cConstants/colorConstants.scss" as color;
@use "../../Util/c2cConstants/fontFileConstants.scss" as font;

.login-password-and-otp{
    height: 40px;
    display: flex;
    align-items: flex-start;
    .login-password-btn {
      font: normal normal normal 16px/19px font.$C2C_FONT_FAMILY;
      color: #b2b2b2;
      cursor: pointer;

      }
      .login-with-otp-btn {
        font: normal normal normal 16px/19px font.$C2C_FONT_FAMILY;
        color: #b2b2b2;
        cursor: pointer;
        padding: 0px 20px 0px 20px;
      }
      .login-header-selected {
        font-weight: 500;
        color: color.$C2C_CYNICAL_BLACK;
      }
}