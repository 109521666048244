#dialog-content-container{
  margin:1.25rem 0 1rem 0;
  padding:0 3rem;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    padding:0 2rem;
  }
}

#dialog-content-heading {
  font-size: 1.25rem;
  font-family: Rubik, sans-serif;
  padding-bottom: 0.8rem;
  font-weight: 600;
  line-height: 1.4em;
  text-align: center;
  color: #1B2027;

  @media screen and (max-width: 600px) {
    width: 90%;
    margin: auto;
  }
}

#dialog-content-text {
  text-align: center;
  font-family: Rubik, sans-serif;
  font-weight: 400;
  width:60%;
  line-height: 1.18em;
  min-width: 12.5em ;
  margin: auto;
  font-size: 0.97rem;
  color: #1B2027;
}

#dialog-content-action-div {
  display: flex;
  justify-content: center;
  margin-bottom: 2em;
}

#dialog-content-action-div > button {
  border-radius: 1.56em;
  width: 9.3rem;
  height: 3.125rem;
}

.dialog-content-success{
  background-color:#17bae0;
  color:white;
}
