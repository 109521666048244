@use "../../../Util/c2cConstants/colorConstants.scss" as color;
@use "../../../Util/c2cConstants/fontFileConstants.scss" as font;

.home-page-footer-container {
  .home-page-footer-content {
    background-color: #1d1d1d;
    padding: 50px 165px 100px 165px;
    color: color.$C2C_WHITE;
  }

  .home-page-footer-top-conetnt {
    display: flex;
    border-bottom: 1px solid #484848;
    padding-bottom: 50px;
  }

  .home-page-footer-bottom-conetnt {
    display: flex;
    justify-content: space-between;
  }

  .home-page-footer-address-container {
    width: 35%;
  }

  .home-page-footer-address {
    width: 255px;
  }

  .home-page-footer-title {
    font: normal normal 500 14px/17px font.$C2C_FONT_FAMILY;
    letter-spacing: 0.56px;
    text-transform: uppercase;
    margin-top: 50px;
    margin-bottom: 20px;
  }

  .home-page-footer-address-content {
    font: normal normal normal 12px/20px font.$C2C_FONT_FAMILY;
  }

  .home-page-footer-all-rights-content {
    height: 40px;
    background-color: #ffb400;
    padding: 0px 165px;
    display: flex;
    align-items: center;
    font: normal normal normal 14px/17px font.$C2C_FONT_FAMILY;
  }

  .home-page-footer-download-app-content {
    display: flex;
  }

  .home-page-footer-download-app-icons {
    margin-right: 10px;

    .download-app-icons {
      width: 130px;
      height: 38px;
    }
  }

  .home-page-footer-contact-content {
    display: flex;
    font: normal normal normal 12px/14px font.$C2C_FONT_FAMILY;
  }

  .home-page-footer-contact-icon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }

  .home-page-footer-call-content {
    margin-right: 20px;

    a {
      color: color.$C2C_WHITE;
    }
  }

  .home-page-footer-email-content {
    a {
      color: color.$C2C_WHITE;
    }
  }

  .home-page-footer-company-content {
    display: flex;
    font: normal normal normal 12px/17px font.$C2C_FONT_FAMILY;
  }

  .home-page-footer-company-title {
    margin-right: 40px;
    cursor: pointer;
  }

  .home-page-follow-us-icons {
    display: flex;

    .follow-us-icon {
      margin-right: 5px;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .outlined-icon {
        display: flex;
      }

      .filled-icon {
        display: none;
      }
    }

    .follow-us-icon:hover {
      background-color: color.$C2C_WHITE;

      .filled-icon {
        display: flex;
      }

      .outlined-icon {
        display: none;
      }
    }
  }

  @media (max-width: 768px) {
    .home-page-footer-content {
      padding: 40px 20px;
    }

    .home-page-footer-top-conetnt,
    .home-page-footer-bottom-conetnt {
      display: flex;
      flex-direction: column;
    }

    .home-page-footer-all-rights-content {
      padding: 0px 30px;
    }

    .home-page-footer-company-title {
      margin-right: 12px;
    }

    .home-page-footer-contact-icon {
      width: 18px;
      height: 18px;
    }
  }
}


@media only screen and (min-width: 820px) and (max-width: 1179px) {

  .home-page-footer-container .home-page-footer-content {
    background-color: #1d1d1d;
    padding: 41px 30px 80px 30px;
    color: #ffffff;
  }

}