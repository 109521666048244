.doctor-reset-password-container {
  width: 27vw;

  .drpc-mobile-input-container {
    margin-bottom: 10px;
    height: 70px;
    width: 27vw;
  }

  .drpc-otp-container {
    padding-bottom: 25px;
    border-bottom: 1px solid #e5e5e5;
  }

  .drpc-password-container {
    margin-top: 25px;
  }
  .drpc-action-button {
    display: flex;
    justify-content: end;
    margin: 25px 0px;
  }
}

@media (max-width: 767px) {
  .doctor-reset-password-container {
    width: 100%;

    .drpc-mobile-input-container {
      margin-bottom: 10px;
      width: 100%;
    }
  }

}
